
import { Component, Vue, Watch } from 'vue-property-decorator';
import FancyCurrencyComponent from '@/components/general/FancyCurrencyComponent.vue';
import CashbookEntryTemplate from '@/assets/models/CashbookEntryTemplate';
import DatePicker from '@/components/cashbook/dialogs/transactionDialog/DatePicker.vue';
import Webservice from '@/assets/service/Webservice';
import CashbookAccount from "@/assets/models/CashbookAccount";

@Component({
    components: { DatePicker, FancyCurrencyComponent },
})
export default class CashbookTemplateDialog extends Vue {
    private requiredRules = {
      required: value => !!value || "Dieses Feld ist ein Pflichtfeld"
    };
    private valid:boolean = true;
    private icon:string="fa fa-plus";
    private open: boolean = false;
    private loading: boolean = false;
    private addNewItem:boolean=false;
    private errors: any = {};
    private readonly: boolean=false;
    private showTax:boolean=false;
    private cashbookEntryTemplate: CashbookEntryTemplate=new CashbookEntryTemplate();
    private costCentreFromBranch:boolean=false;
    private onlyForActiveCashbook:boolean=false;

    @Watch("cashbookEntryTemplate.KOSTENSTELLE")
    costCentreChanged(value: number, oldValue: number) {
      this.costCentreFromBranch= value==9999;
    }

    @Watch("costCentreFromBranch")
    costCentreFromBranchChanged(value: boolean, oldValue: boolean) {
      if(value)
        this.cashbookEntryTemplate.KOSTENSTELLE=9999;
      else
        delete this.cashbookEntryTemplate.KOSTENSTELLE;
    }
    @Watch("onlyForActiveCashbook")
    onlyForActiveCashbookChanged(value: boolean, oldValue: boolean) {
      if(value)
        this.cashbookEntryTemplate.KASSENBUCH= this.$store.getters.selectedCashbook.NUM;
      else
        this.cashbookEntryTemplate.KASSENBUCH=0;
    }
    private selectText(e){
      try {
        e.target.select()
      }catch {}
    }
  private typeChanged(){
    delete this.cashbookEntryTemplate.KONTO;

  }
  private costCentreReadonly(): boolean {
    return this.readonly || this.costCentreFromBranch;
  }

    public addItem():void{
      this.addNewItem=true;
      this.readonly=false;
      this.icon="fa fa-plus";
      const newItem=new CashbookEntryTemplate();
      newItem.BUCHUNGSTEXT="Neue Buchungsvorlage";
      newItem.BRUTTO=0.0;
      newItem.NUMMER=0;
      newItem.KASSENBUCH=0;
      this.show(newItem);
    }
    public editItem(item:CashbookEntryTemplate){
      this.addNewItem=false;
      this.readonly=false;
      this.icon="fa fa-edit";
      this.show(new CashbookEntryTemplate(item));
    }
    public viewItem(item:CashbookEntryTemplate){
      this.readonly=true;
      this.icon="fa fa-eye";
      this.show(item);
    }
    private show(item:CashbookEntryTemplate) {
        this.open = true;
        this.cashbookEntryTemplate=item;
        this.templateChange();
        this.loading = false;
    }

    private async save() {
      //@ts-ignore
      if (this.$refs.form.validate()) {
        if (!this.loading) {
          this.loading = true;
          try {
            this.handleSave(() => {
              //success
              //update Templates
              Webservice.getCashbookEntryTemplates();
              //@ts-ignore
              this.$refs.form.resetValidation();
              this.open = false;

            }, () => {
              //finally
              this.loading = false;
            });
          } catch (e) {
            // error should already be logged
          }
        }
      }
    }
    private handleSave(callbackSuccess: (val: CashbookEntryTemplate) => void, callbackFinally: () => void):void{

        if (this.addNewItem) {
          Webservice.insertCashbookEntryTemplate(this.cashbookEntryTemplate, callbackSuccess, callbackFinally);
        } else {
          Webservice.changeCashbookEntryTemplate(this.cashbookEntryTemplate, callbackSuccess, callbackFinally);
        }
    }
    private cancel() {
        //@ts-ignore
        this.$refs.form.resetValidation();
        this.open = false;
    }

    private get accounts(): ComboboxItem[] {
      return this.$store.getters.cashbookAccounts
        .filter((account: CashbookAccount) => account.TYP === this.cashbookEntryTemplate?.TYP)
        .map((account: CashbookAccount) => ({ text: `${account.KONTO} - ${account.BUCHUNGSTEXT}`, value: account.KONTO }));
    }

    private templateChange():void {
      this.costCentreFromBranch=this.cashbookEntryTemplate.KOSTENSTELLE==9999;
      this.onlyForActiveCashbook=this.cashbookEntryTemplate.KASSENBUCH!==0;
      this.showTax=this.cashbookEntryTemplate.TYP===1 ||this.cashbookEntryTemplate.TYP===2 ;
      if(!this.showTax)
        delete this.cashbookEntryTemplate.MWSTART;
    }
  private updateAccount(value: string|number|ComboboxItem) {
      if(value!==null) {
        const comboboxItem = (value as ComboboxItem);

        if (comboboxItem !== undefined && comboboxItem.value !== undefined) {
          this.cashbookEntryTemplate.KONTO = comboboxItem.value;
        } else {
          const number = parseInt(value.toString());
          if (!Number.isNaN(number)) {
            this.cashbookEntryTemplate.KONTO = number;
          } else {
            this.cashbookEntryTemplate.KONTO = 0;
          }
        }
      }
  }

}
interface ComboboxItem {
  text: string;
  value: number;
}
