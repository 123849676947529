import { render, staticRenderFns } from "./CashbookRoot.vue?vue&type=template&id=666ac4a4&scoped=true&"
import script from "./CashbookRoot.vue?vue&type=script&lang=js&"
export * from "./CashbookRoot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666ac4a4",
  null
  
)

export default component.exports