import DialogMode from "@/components/cashbook/dialogs/transactionDialog/DialogMode";

const state = {
  ingestion: true,
  cashbookEntry: null,
  showTransactionDialog: false,
  showCashCountDialog: false,
  drawerCashCountDialog: 0,
  stepCashCountDialog: 0,
  lfNrCashCountDialog: 0,
  dateCashCountDialog: "",
  transactionDialogMode: DialogMode.VIEW,
  filterDate: null as Date|null,
};

const getters = {
  ingestion: initState => initState.ingestion,
  cashbookEntry: initState => initState.cashbookEntry,
  showTransactionDialog: initState => initState.showTransactionDialog,
  showCashCountDialog: initState => initState.showCashCountDialog,
  stepCashCountDialog: initState => initState.stepCashCountDialog,
  drawerCashCountDialog: initState => initState.drawerCashCountDialog,
  lfNrCashCountDialog: initState => initState.lfNrCashCountDialog,
  dateCashCountDialog: initState => initState.dateCashCountDialog,
  transactionDialogMode: initState => initState.transactionDialogMode,
  filterDate: initState => initState.filterDate,
};

const mutations = {
  SET_INGESTION(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.ingestion = params;
  },
  SET_CASHBOOK_ENTRY(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookEntry = params;
  },
  SET_SHOW_TRANSACTION_DIALOG(initState, param) {
    initState.showTransactionDialog = param;
  },
  SET_SHOW_COUNT_DIALOG(initState, param) {
    initState.showCashCountDialog = param;
  },
  SET_STEP_COUNT_DIALOG(initState, param) {
    initState.stepCashCountDialog = param;
  },
  SET_DRAWER_COUNT_DIALOG(initState, param) {
    initState.drawerCashCountDialog = param;
  },
  SET_LFNR_COUNT_DIALOG(initState, param) {
    initState.lfNrCashCountDialog = param;
  },
  SET_DATE_COUNT_DIALOG(initState, param) {
    initState.dateCashCountDialog = param;
  },
  SET_TRANSACTION_DIALOG_MODE(initState, param) {
    initState.transactionDialogMode = param;
  },
  SET_FILER_DATE(initState, param) {
    initState.filterDate = param;
  },
};

export default {
  state,
  getters,
  mutations,
};
