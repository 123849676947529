
import { Component, Vue, Watch } from 'vue-property-decorator';
import FancyCurrencyComponent from '@/components/general/FancyCurrencyComponent.vue';
import TransferOption from '@/assets/models/TransferOption';
import CashbookEntryTemplate from '@/assets/models/CashbookEntryTemplate';
import PaymentEntry from '@/assets/models/PaymentEntry';
import Taxrate from '@/assets/models/Taxrate';
import DatePicker from '@/components/cashbook/dialogs/transactionDialog/DatePicker.vue';
import moment from 'moment-mini';
import { REQUEST_DATEFORMAT } from '@/assets/utils/DateUtil';
import Webservice from '@/assets/service/Webservice';

@Component({
    components: { DatePicker, FancyCurrencyComponent },
})
export default class TransferDialog extends Vue {

    private webservice = new Webservice();

    private open: boolean = false;
    private loading: boolean = false;
    private step: number = 1;
    private highestStep: number = 1;

    private transferType: { NUM: number, VAL: string } = { NUM: 1, VAL: ''};
    private transferOptions: TransferOption = new TransferOption();
    private selectedDate: Date = new Date();

    private showFromTaxrate: boolean = false;
    private showToTaxrate: boolean = false;

    private errors: any = {};

    private get transactionItems(): CashbookEntryTemplate[] {
        // 1: Zahlungsmittel
        // 1: Einnahmen
        // 2: Ausgaben
        // 4: Ablieferungen

        if (this.transferType.NUM === 2) {
            return this.$store.getters.availableCashbookEntryTemplates
                .filter((value: CashbookEntryTemplate) => value.TYP === 1 || value.TYP === 3)
        }
        if (this.transferType.NUM === 3) {
            return this.$store.getters.availableCashbookEntryTemplates
                .filter((value: CashbookEntryTemplate) => value.TYP === 2 || value.TYP === 4 || value.TYP === 6)
        }
        if (this.transferType.NUM === 4) {
            return this.$store.getters.availableCashbookEntryTemplates
                .filter((value: CashbookEntryTemplate) => value.TYP === 6)
        }
        return [];
    }

    @Watch('transferType')
    private onTransferTypeChanged() {
        this.transferOptions.ART = this.transferType.NUM;
        delete this.transferOptions.ZAHLUNGSMITTELVON;
        delete this.transferOptions.ZAHLUNGSMITTELNACH;
        delete this.transferOptions.VORLAGEVON;
        delete this.transferOptions.STEUERSATZVON;
        delete this.transferOptions.VORLAGENACH;
        delete this.transferOptions.STEUERSATZNACH;
    }

    @Watch('transferOptions.VORLAGEVON')
    private onTransactionFromChanged(newValue: number, oldValue: number) {
        if (this.transferOptions.VORLAGENACH === newValue && newValue>-1) {
            this.transferOptions.VORLAGENACH = oldValue;
        }
        const transaction = this.getTransactionForId(this.transferOptions.VORLAGEVON);
        this.showFromTaxrate = transaction !== undefined && (transaction.TYP === 1 || transaction.TYP === 2);
        if (transaction && this.showFromTaxrate) {
            const taxrate = this.getTaxRateForId(transaction.MWSTART);
            if (taxrate) {
                this.transferOptions.STEUERSATZVON = taxrate.STEUERSATZ;
            }
        } else {
            delete this.transferOptions.STEUERSATZVON;
        }
    }

    @Watch('transferOptions.VORLAGENACH')
    private onTransactionToChanged(newValue: number, oldValue: number) {
        if (this.transferOptions.VORLAGEVON === newValue && newValue>-1) {
            this.transferOptions.VORLAGEVON = oldValue;
        }
        const transaction = this.getTransactionForId(this.transferOptions.VORLAGENACH);
        this.showToTaxrate = transaction !== undefined && (transaction.TYP === 1 || transaction.TYP === 2);
        if (transaction && this.showToTaxrate) {
            const taxrate = this.getTaxRateForId(transaction.MWSTART);
            if (taxrate) {
                this.transferOptions.STEUERSATZNACH = taxrate.STEUERSATZ;
            }
        } else {
            delete this.transferOptions.STEUERSATZNACH;
        }
    }

    @Watch('transferOptions.ZAHLUNGSMITTELVON')
    private onPaymentFromChanged(newValue: number, oldValue: number) {
        if (this.transferOptions.ZAHLUNGSMITTELNACH === newValue && newValue>-1) {
            this.transferOptions.ZAHLUNGSMITTELNACH = oldValue;
        }
    }

    @Watch('transferOptions.ZAHLUNGSMITTELNACH')
    private onPaymentToChanged(newValue: number, oldValue: number) {
        if (this.transferOptions.ZAHLUNGSMITTELVON === newValue && newValue>-1) {
            this.transferOptions.ZAHLUNGSMITTELVON = oldValue;
        }
    }

    @Watch('selectedDate')
    private onDateChanged() {
        this.transferOptions.DATUM = moment(this.selectedDate).format(REQUEST_DATEFORMAT);
    }

    private getTaxRateForId(id: number|undefined): Taxrate {
        return this.$store.getters.taxRates.find((value) => value.ART === id);
    }

    private getPaymentForId(id: number|undefined): PaymentEntry | undefined {
        return this.$store.getters.availableCashbookPayments.find((value) => value.NUMMER === id);
    }

    private getTransactionForId(id: number|undefined): CashbookEntryTemplate | undefined {
        return this.$store.getters.availableCashbookEntryTemplates.find((value) => value.NUMMER === id);
    }

    private setStep(step: number) {
        if (step <= this.highestStep) {
            this.step = step;
            this.highestStep = Math.max(step, this.highestStep);
        }
    }

    private nextStep() {
        if (this.isValid()) {
            this.step += 1;
            this.highestStep = Math.max(this.step, this.highestStep);
        }
    }

    private isValid(): boolean {
        this.errors = {};
        if (this.step >= 1 && this.transferOptions.ART <= 0) {
            this.errors.ART = 'Bitte geben Sie eine Umbuchungsart an.';
        }
        if (this.step >= 2 && this.transferOptions.DATUM === undefined) {
            this.errors.DATUM = 'Bitte geben Sie ein Umbuchungsdatum an.';
        }
        if (this.step >= 2 && this.transferOptions.BETRAG <= 0) {
            this.errors.BETRAG = 'Bitte geben Sie einen Betrag größer 0 an.';
        }
        if (this.step >= 2 && this.transferOptions.ART === 1 && this.transferOptions.ZAHLUNGSMITTELNACH === undefined) {
            this.errors.ZAHLUNGSMITTELNACH = 'Bitte geben Sie ein Zahlungsmittel an.';
        }
        if (this.step >= 2 && this.transferOptions.ART === 1 && this.transferOptions.ZAHLUNGSMITTELVON === undefined) {
            this.errors.ZAHLUNGSMITTELVON = 'Bitte geben Sie ein Zahlungsmittel an.';
        }
        if (this.step >= 2 && (this.transferOptions.ART === 2 || this.transferOptions.ART === 3) && this.transferOptions.VORLAGENACH === undefined) {
            this.errors.VORLAGENACH = 'Bitte geben Sie ein Vorlage an.';
        }
        if (this.step >= 2 && (this.transferOptions.ART === 2 || this.transferOptions.ART === 3) && this.transferOptions.VORLAGEVON === undefined) {
            this.errors.VORLAGEVON = 'Bitte geben Sie ein Vorlage an.';
        }
        if (this.step >= 2 && this.showFromTaxrate && this.transferOptions.STEUERSATZVON === undefined) {
            this.errors.STEUERSATZVON = 'Bitte geben Sie einen Steuersatz an.';
        }
        if (this.step >= 2 && this.showToTaxrate && this.transferOptions.STEUERSATZNACH === undefined) {
            this.errors.STEUERSATZNACH = 'Bitte geben Sie einen Steuersatz an.';
        }
        this.$forceUpdate();
        return Object.keys(this.errors).length === 0;
    }

    private formattedTransferoptions(): string {
        if (this.transferOptions.ART === 1) {
            const from = this.getPaymentForId(this.transferOptions.ZAHLUNGSMITTELVON);
            const to = this.getPaymentForId(this.transferOptions.ZAHLUNGSMITTELNACH);
            if (from && to) {
                return this.$t('Transfer.Summary', { amount: this.$n(this.transferOptions.BETRAG,"currency"), from: from.BEZEICHNUNG, to: to.BEZEICHNUNG }).toString();
            }

        } else {
            const from = this.getTransactionForId(this.transferOptions.VORLAGEVON);
            const to = this.getTransactionForId(this.transferOptions.VORLAGENACH);
            if (from && to) {
                const fromFormatted = this.showFromTaxrate ? `${from.BUCHUNGSTEXT} (${this.transferOptions.STEUERSATZVON}%)` : from.BUCHUNGSTEXT;
                const toFormatted = this.showToTaxrate ? `${to.BUCHUNGSTEXT} (${this.transferOptions.STEUERSATZNACH}%)` : to.BUCHUNGSTEXT;
                return this.$t('Transfer.Summary', { amount: this.$n(this.transferOptions.BETRAG,"currency"), from: fromFormatted, to: toFormatted }).toString();
            }
        }
        return '';
    }

    private get day(): number {
        if (this.$vuetify.breakpoint.smAndDown) {
            return Number(this.$store.getters.selectedDay);
        } else {
            const now = new Date();
            if (now.getFullYear() === Number(this.$store.getters.selectedYear)
                && now.getMonth() === Number(this.$store.getters.selectedMonth)) {
                return now.getDate();
            } else {
                return (new Date(this.$store.getters.selectedYear,
                    this.$store.getters.selectedMonth + 1, 0)).getDate();
            }
        }
    }

    public show() {
        this.open = true;
        this.transferOptions = new TransferOption();
        this.transferOptions.BETRAG = 0;
        this.transferOptions.ZAHLUNGSMITTELVON=-1;
        this.transferOptions.ZAHLUNGSMITTELNACH=-1;
        this.transferOptions.STEUERSATZVON=-1;
        this.transferOptions.STEUERSATZNACH=-1;
        this.transferType = this.$store.getters.transferTypes[0];
        this.transferOptions.ART = this.transferType.NUM;
        this.step = 1;
        this.selectedDate = this.$store.getters.filterDate || new Date(this.$store.getters.selectedYear, this.$store.getters.selectedMonth, this.day);
        this.loading = false;
    }

    private async save() {
        if (!this.loading && this.isValid()) {
            this.loading = true;
            try {
                await Webservice.transfer(this.transferOptions);
                this.open = false;
                // reload table
                Webservice.getCashbookEntries(Number(this.$store.getters.selectedMonth), Number(this.$store.getters.selectedYear), () => {});
            } catch (e) {
                // error should already be logged
            }
            this.loading = false;
        }
    }

    private cancel() {
        this.open = false;
    }
}
