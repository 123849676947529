
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class AdminElement extends Vue
  {
    @Prop() useSmallIcon!: boolean ;
    @Prop({ required: true }) title!: string;
    @Prop({ required: true }) description!: string;
    @Prop({ required: true }) icon!: string;
    @Prop({ required: true }) linkTo!: string;
    @Prop({ required: true }) linkText!: string;
    @Prop({ default: true }) showLink!:boolean;
  }
