export default class DocumentHeader {
  KASSENBUCH!: number;

  DATUM!: string;

  UHRZEIT!: string;

  TYP!: number;

  BUCHUNGART!: number;

  PERSISTENZ!: number;

  LFDNUMMER!: number;

  REFERENZAUFDATUM!: string;

  REFERENZAUFLFDNUMMER!: number;

  FREMDNUMMER!: string;

  KOSTENSTELLE!: number;

  BEDIENERNAME!: number;

  KONTO!: number;

  BUCHUNGSTEXT!: string;

  BRUTTO!: number;

  BEMERKUNG!: string;

  NUMMER!: number;
}
