
import { Component, Vue, Watch } from 'vue-property-decorator';
import CashbookEntriesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
import CashbookDivisionComponent from '@/components/cashbook/dialogs/transactionDialog/CashbookDivisionCpmponent.vue';
import calcTaxByCurrentAndRate from '@/assets/utils/TaxUtils';
import CashbookDivision from '@/components/cashbook/dialogs/transactionDialog/CashbookDivision';
import DivisionStyle from '@/components/cashbook/dialogs/transactionDialog/DivisionStyle';
import DialogMode from '@/components/cashbook/dialogs/transactionDialog/DialogMode';
import Taxes from '@/assets/service/parameterTypes/cashbook/taxes/Taxes';
import Payment from '@/assets/service/parameterTypes/cashbook/payments/Payment';
import CashbookEnumerationsEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEnumerations/CashbookEnumerationsEntry';
import CashbookTaxRatesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookTaxRates/CashbookTaxRatesResponseEntry';
import {
    CashbookEntryDetailResponse,
    Revisions,
} from '@/assets/service/responseTypes/cashbook/cashbookEntryDetails/CashbookEntryDetailResponse';
import CashbookPaymentMethodResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry';
import Lightbox from '@/components/cashbook/dialogs/lightbox/Lightbox.vue';
import CashbookEntryTemplate from '@/assets/models/CashbookEntryTemplate';
import DocumentHeader from '@/assets/models/DocumentHeader';
import PermissionUtil from '@/assets/utils/PermissionUtil';
import { get_tex_width, TransactionDialogErrors } from '@/assets/utils/transactionDialog/transactionDialogUtils';
import ServiceErrors from '@/assets/service/responseTypes/ServiceErrors';
import DatePicker from '@/components/cashbook/dialogs/transactionDialog/DatePicker.vue';
import { REQUEST_DATEFORMAT, REQUEST_TIMEFORMAT } from '@/assets/utils/DateUtil';
import moment from 'moment-mini';
import CashCountDialog from '@/components/cashbook/dialogs/dayClosing/CashCountDialog.vue';
import FancyCurrencyComponent from '@/components/general/FancyCurrencyComponent.vue';
import { CountProtocolListEntry } from '@/assets/service/responseTypes/cashbook/countProtocol/CountProtocol';
import CashbookAccount from '@/assets/models/CashbookAccount';
import Webservice from '@/assets/service/Webservice';
import TransactionDialog from '@/components/cashbook/dialogs/transactionDialog/TransactionDialog.vue';

@Component({
    components: {TransactionDialog},
})
export default class TransactionDialogWrapper extends Vue {

    private closeDialog(): void {
        // the dialog is closed by setting the variable $store.getters.showTransactionDialog to false (true to open respectively)
        // this is not done here directly, because the dialog can prevent the closing if contents are dirty, so we let the dialog
        // component set the variable if we want to actually close the dialog
        const transactionDlg = this.$refs.transactionDlg as any
        if (transactionDlg) {
            transactionDlg.closeDialog();
        }
    }

    // the class generated here is only appended to the dialog, it does not seem to have any effect, as there are neither
    // css nor query selectors that could reference this class, so this can probably be removed...

    // the idClass was appended to the v-dialog

    // private get idClass(): string {
    //     let idClass: string = 'transaction_';
    //     switch (this.mode) {
    //         case DialogMode.CREATE:
    //             idClass += 'CREATE';
    //             break;
    //         case DialogMode.EDIT:
    //             idClass += 'EDIT';
    //             break;
    //         case DialogMode.VIEW:
    //             idClass += 'VIEW';
    //             break;
    //         case DialogMode.DELETE:
    //             idClass += 'DELETE';
    //             break;
    //     }
    //     idClass += '_' + (this.$store.getters.ingestion ? 'ingestion_' : 'expense');
    //     if (this.cashbookEntry !== undefined && this.cashbookEntry !== null) {
    //         idClass += `-${this.cashbookEntry.DATUM}-${this.cashbookEntry.LFDNUMMER}`.replace(/\//g, '-');
    //     }
    //     return idClass;
    // }
}
