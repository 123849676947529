
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";
  import Datepicker from '@/components/cashbook/Datepicker.vue';
  import CashbookInfo from "@/assets/service/responseTypes/cashbook/cashbookDetail/CashbookInfo";

  @Component({
    components: {
      Datepicker
    }
  })
  export default class MobileDayPicker extends Vue {
    @Prop() updateOpenEntries!: () => void;

    @Prop() changeMonth!: () => void;

    @Prop() subtitle!: string;

    now: Date = new Date();

    get loadTableData(): boolean {
      return this.$store.getters.loadTableData;
    }

    set loadTableData(val: boolean) {
      this.$store.commit('SET_LOAD_TABLE_DATA', val);
    }

    get maxDays(): number {
      const ret =  new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate();
      console.log(ret);
      return ret;
    }

    get selectedYear() {
      return Number(this.$store.getters.selectedYear);
    }

    set selectedYear(selected: number) {
      if (selected !== this.selectedYear) {
        if (Number(this.activeCashbookInfo?.DATUMERSTEREINTRAG.split('/')[0]) === selected) {
          if (Number(this.activeCashbookInfo?.DATUMERSTEREINTRAG.split('/')[1]) > this.selectedMonth) {
            this.selectedMonth = 11;
          }
        } else if (this.activeCashbookInfo?.DATUMLETZTEREINTRAG.length > 0
          && Number(this.activeCashbookInfo?.DATUMLETZTEREINTRAG.split('/')[0]) === selected) {
          if (Number(this.activeCashbookInfo?.DATUMLETZTEREINTRAG.split('/')[1]) < this.selectedMonth) {
            this.selectedMonth = Number(this.activeCashbookInfo?.DATUMLETZTEREINTRAG.split('/')[1]) - 1;
          }
        } else if (this.now.getFullYear() === selected) {
          this.selectedMonth = this.now.getMonth();
        }
        this.$store.commit('SET_SELECTED_YEAR', selected);
        this.changeMonth();
      }
    }

    get selectedMonth(): number {
      return this.$store.getters.selectedMonth;
    }

    set selectedMonth(month: number) {
      this.$store.commit('SET_SELECTED_MONTH', month);
    }

    get activeCashbookInfo(): CashbookInfo {
      return this.$store.getters.activeCashbookDetails;
    }

    get dateFirstEntry(): Date {
      const startDate = moment(this.activeCashbookInfo?.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT).toDate();
      startDate.setHours(0, 0, 0, 0);
      return startDate;
    }

    get selectedDay(): number {
      if (Number(this.$store.getters.selectedYear) !== this.selectedYear
        || Number(this.$store.getters.selectedMonth) !== this.selectedMonth) {
        this.selectedMonth = this.$store.getters.selectedMonth;
        this.changeMonth();
      }
      return Number(this.$store.getters.selectedDay);
    }

    set selectedDay(d: number) {
      this.updateOpenEntries();
      this.$store.commit('SET_SELECTED_DAY', d);
    }

    getDate(d: number): Date {
      return new Date(this.selectedYear, this.selectedMonth, d);
    }

    disableDay(day: number): boolean {
      if (this.activeCashbookInfo) {
        if (this.activeCashbookInfo?.DATUMERSTEREINTRAG) {
          if (Number(this.selectedYear) > this.dateFirstEntry.getFullYear() && this.now.getFullYear() < Number(this.selectedYear)) {
            return false;
          } else if (this.dateFirstEntry.getFullYear() === this.now.getFullYear()) {
            if (Number(this.selectedMonth) > this.dateFirstEntry.getMonth()) {
              if (this.now.getMonth() > Number(this.selectedMonth)) {
                return false;
              }
              if (day <= this.now.getDate()) {
                return false;
              }
            } else if ((Number(this.selectedMonth) === this.dateFirstEntry.getMonth() && day >= this.dateFirstEntry.getDate())
              && this.now.getMonth() > Number(this.selectedMonth)) {
              return false;
            } else if ((Number(this.selectedMonth) === this.dateFirstEntry.getMonth() && day >= this.dateFirstEntry.getDate())
              && (this.now.getMonth() === Number(this.selectedMonth) && day <= this.now.getDate())) {
              return false;
            }
          } else if (Number(this.selectedYear) === this.dateFirstEntry.getFullYear()) {
            if (Number(this.selectedMonth) > this.dateFirstEntry.getMonth()) {
              return false;
            } else if (day >= this.dateFirstEntry.getDate()) {
              return false;
            }
          } else if (this.now.getFullYear() === Number(this.selectedYear)) {
            if (Number(this.selectedMonth) < this.now.getMonth()) {
              return false;
            } else if (day <= this.now.getDate()) {
              return false;
            }
          }
        }
      }
      return true;
    }

    @Watch('loadTableData')
    @Watch('selectedDay')
    @Watch('activeCashbookInfo')
    dayWatcher() {
      if (!this.loadTableData) {
        this.scrollTo(this.selectedDay);
      }
    }

    scrollTo(day: number) {
      let slider = document.querySelector('.hs');
      let element = document.querySelector('#day-element-' + (day));
      if (slider && element) {
        console.log("Jump to day:" + day);
        slider.scrollTo((element as HTMLElement).offsetLeft - ((element as HTMLElement).clientWidth / 2), 0);
      }
    }

    showDatePickerDialog(): void {
      if (!this.loadTableData) {
        this.$store.commit('SET_SHOW_DATEPICKER_DIALOG', true);
      }
    }

    created(){
        console.log("created");
    }
    prevMonth(): void {
      if (this.selectedMonth === 1) {
        this.selectedYear = this.selectedYear - 1;
        this.selectedMonth = 12;
      } else {
        this.selectedMonth = this.selectedMonth - 1;
      }
      this.selectedDay = this.maxDays;
      this.changeMonth();
    }

    nextMonth(): void {
      if (this.selectedMonth === 12) {
        this.selectedYear = this.selectedYear + 1;
        this.selectedMonth = 1;
      } else {
        this.selectedMonth = this.selectedMonth + 1;
      }
      this.selectedDay = 1;
      this.changeMonth();
    }
  }
