
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import calcTaxByCurrentAndRate from '@/assets/utils/TaxUtils';
import CashbookDivision from '@/components/cashbook/dialogs/transactionDialog/CashbookDivision';
import DivisionStyle from '@/components/cashbook/dialogs/transactionDialog/DivisionStyle';
import CashbookTaxRatesResponseEntry from '@/assets/service/responseTypes/cashbook/cashbookTaxRates/CashbookTaxRatesResponseEntry';
import DialogMode from '@/components/cashbook/dialogs/transactionDialog/DialogMode';
import FancyCurrencyComponent from "@/components/general/FancyCurrencyComponent.vue";

@Component({
    components: { FancyCurrencyComponent }
})
export default class CashbookDivisionComponent extends Vue {
    @Model('change', { type: CashbookDivision })
    public readonly model!: CashbookDivision;

    @Prop()
    public readonly removeDivision!: (division: CashbookDivision) => void;

    @Prop()
    public readonly last!: boolean;

    @Prop()
    public readonly mode!: DialogMode;

    @Prop()
    public readonly keyProp!: string;

    @Prop()
    public readonly paymentError!: string;

    @Prop()
    public readonly currentError!: string;

    @Prop()
    public readonly readOnly!: boolean;

    private divisionStyles = DivisionStyle;
    private dialogModes = DialogMode;

    private get tax(): number {
        this.model.tax = this.model.taxRate ? calcTaxByCurrentAndRate(this.model.current, this.model.taxRate.STEUERSATZ) : 0;
        return this.model.tax;
    }

    private get taxRates(): CashbookTaxRatesResponseEntry[] {
        return this.$store.getters.taxRates;
    }

    private get disabled() {
        return this.mode !== DialogMode.CREATE && this.mode !== DialogMode.EDIT;
    }

    private taxRateDisplay(taxRate:CashbookTaxRatesResponseEntry) :string {
        if(taxRate) {
            return `${this.$n(taxRate.STEUERSATZ)}%`;
        }
        return "";
    }

    private toggleCombobox(cbx: string) {
        const combobox: any = this.$refs[cbx];
        if (combobox.isMenuActive) {
            (this.$refs[cbx] as any).isMenuActive = false;
        } else {
            (this.$refs[cbx] as any).isMenuActive = true;
            combobox.focus();
        }
    }
}
