import { CashbookPort } from './CashbookPort';
import { CashbookService } from './services/CashbookService';
import type { CashbookRepository } from './adapter/CashbookRepository';
import { CashbookRestRepository } from './adapter/rest/CashbookRestRepository';
import { AuthenticationProvider } from './bridges';

export class CashbookPortFactory {

    private readonly authenticationProvider: AuthenticationProvider;

    private cashbookService: CashbookService|null = null;
    private cashbookRepository: CashbookRepository|null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createCashbookPort(auth: AuthenticationProvider): CashbookPort {
        const factory = new CashbookPortFactory(auth);
        return new CashbookPort({
            cashbookService: factory.getCashbookService(),
        });
    }

    private getCashbookService(): CashbookService {
        if (this.cashbookService === null) {
            this.cashbookService = new CashbookService({
                cashbookRepository: this.getCashbookRepository(),
            });
        }
        return this.cashbookService;
    }

    private getCashbookRepository(): CashbookRepository {
        if (this.cashbookRepository === null) {
            this.cashbookRepository = new CashbookRestRepository(this.authenticationProvider);
        }
        return this.cashbookRepository;
    }
}
