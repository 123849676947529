
import { Component, Vue, Watch } from 'vue-property-decorator';

// This is a helper component to register a watcher on showCashCountDialog in Step4.vue. Due to the
// inheritance of StepGeneric within the Step vue files, watchers cannot be used directly in Step4.
@Component({})
export default class CashCountDialogWatcher extends Vue {

    @Watch('$store.getters.showCashCountDialog')
    private onShowCashCountDialogChanged() {
        this.$emit('change');
    }
}
