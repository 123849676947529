import axios from 'axios';
import type { BillingRepository } from '../BillingRepository';
import type { AuthenticationProvider } from '../../bridges';
import type {
    BillingStatus,
    CountProtocol,
    Stock,
    RegisterProgress,
    ServiceError,
} from '../../models';
import type {
    BillingCommittableResponse,
    BillingDateResponse,
    BillingProcessableResponse,
    BillingStartableResponse,
    BillingStatusResponse,
    CommitBillingResponse,
    CountProtocolRequest,
    ProcessBillingResponse,
    RegisterProgressResponse,
    RollbackBillingResponse,
    ServiceErrorResponse,
    StartBillingResponse,
    UpsertCashcountProtocolResponse,
    SkipRegisterResponse,
} from './models';

export class BillingRestRepository implements BillingRepository {

    private readonly auth: AuthenticationProvider;

    constructor(params: {
        auth: AuthenticationProvider,
    }) {
        this.auth = params.auth;
    }

    public async isStartable(cashbookId: number): Promise<boolean> {
        const requestUrl = this.auth.getServiceUrl() + '/Abrechnung_startable';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        const response = await axios.post<BillingStartableResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
        const data = response.data.Abrechnung_startable_response;
        if (!data.startable && data.Reason) {
            const error: ServiceError = {
                number: -1,
                recordId: '',
                recommendation: '',
                position: '',
                description: data.Reason,
            };
            throw [error];
        }
        return true;
    }

    public async start(cashbookId: number): Promise<void> {
        const requestUrl = this.auth.getServiceUrl() + '/start_Kassenbuch_Abrechnung';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        const response = await axios.post<StartBillingResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
    }

    public async isProcessable(cashbookId: number): Promise<boolean> {
        const requestUrl = this.auth.getServiceUrl() + '/Abrechnung_processable';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        const response = await axios.post<BillingProcessableResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
        return response.data.Abrechnung_processable_response?.processable === true;
    }

    public async process(cashbookId: number): Promise<void> {
        const requestUrl = this.auth.getServiceUrl() + '/process_Kassenbuch_Abrechnung';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        try {
            const response = await axios.post<ProcessBillingResponse>(requestUrl, requestBody);
            this.checkForServiceErrors(response);
        } catch (e: any) {
            e.rollback = () => this.rollback(cashbookId);
            throw e;
        }
    }

    public async skipRegister(cashbookId: number): Promise<void> {
        const requestUrl = this.auth.getServiceUrl() + '/ignore_Kassenbericht_Error';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        try {
            const response = await axios.post<SkipRegisterResponse>(requestUrl, requestBody);
            this.checkForServiceErrors(response);
        } catch (e: any) {
            e.rollback = () => this.rollback(cashbookId);
            throw e;
        }
    }

    public async isCommittable(cashbookId: number): Promise<boolean> {
        const requestUrl = this.auth.getServiceUrl() + '/Abrechnung_commitable';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        try {
            const response = await axios.post<BillingCommittableResponse>(requestUrl, requestBody);
            this.checkForServiceErrors(response);
            return response.data.Abrechnung_commitable_response?.commitable === true;
        } catch (e: any) {
            e.rollback = () => this.rollback(cashbookId);
            throw e;
        }
    }

    public async commit(cashbookId: number, comment: string): Promise<void> {
        const requestUrl = this.auth.getServiceUrl() + '/commit_Kassenbuch_Abrechnung';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
            Bemerkung: comment,
        };
        const response = await axios.post<CommitBillingResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
    }

    public async rollback(cashbookId: number): Promise<void> {
        const requestUrl = this.auth.getServiceUrl() + '/rollback_Kassenbuch_Abrechnung';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        const response = await axios.post<RollbackBillingResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
    }

    public async getRegisterProgress(cashbookId: number): Promise<RegisterProgress> {
        const requestUrl = this.auth.getServiceUrl() + '/get_Kassenbericht_Fortschritt';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        const response = await axios.post<RegisterProgressResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
        const data = response.data.get_Kassenbericht_Fortschritt_response;
        const progress: RegisterProgress = {
            PercentageDone: data.PercentageDone,
            StatusMessage: data.StatusMessage,
        }
        if (data.Warning) {
            progress.Warning = {
                CAPTION: data.Warning.CAPTION,
                TEXT: data.Warning.TEXT,
            };
        }
        return progress;
    }

    public async upsertCashCountProtocol(protocol: CountProtocol, stock: Stock[]) {
        const requestUrl = this.auth.getServiceUrl() + '/upsert_Zaehlprotokoll';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            Kopf: protocol,
            Lst_Bestand: stock
        };
        const response = await axios.post<UpsertCashcountProtocolResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
    }

    public async getStatus(cashbookId: number): Promise<BillingStatus> {
        const requestUrl = this.auth.getServiceUrl() + '/get_Kassenbuch_Abrechnung_Status';
        const requestBody = {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookId,
        };
        const response = await axios.post<BillingStatusResponse>(requestUrl, requestBody);
        this.checkForServiceErrors(response);
        const data = response.data.get_Kassenbuch_Abrechnung_Status_response;
        return {
            AbrechnungStatus: data.AbrechnungStatus,
            KassenberichtStatus: data.KassenberichtStatus,
            ZaehlprotokollStatus: data.ZaehlprotokollStatus,
        };
    }

    /**
     * Search the response for service errors and throw them if present
     * @param response service response
     * @param rollback optional
     */
    private checkForServiceErrors(response: any, rollback?: () => Promise<any>): void {
        if (!response) {
            return;
        }
        const serviceErrors = this.findServiceErrors(response);
        if (serviceErrors) {
            const mappedErrors: ServiceError[] = serviceErrors.map((error) => ({
                number: error.ERRORNUMBER,
                description: error.ERRORDESCRIPTION,
                position: error.ERRORPOSITION,
                recommendation: error.ERRORRECOMMENDATION,
                recordId: error.ERRORRECORDID,
                rollback: rollback,
            }));
            throw mappedErrors;
        }
    }

    /**
     * search the object recursively for a key named ServiceErrors
     * @param object
     */
    private findServiceErrors(object: any): ServiceErrorResponse[]|undefined {
        const keys = Object.keys(object);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = object[key];
            if (key === 'ServiceErrors') {
                return value;
            }
            if (value && typeof value === 'object') {
                const serviceErrorsRec = this.findServiceErrors(value);
                if (serviceErrorsRec) {
                    return Array.isArray(serviceErrorsRec) ? serviceErrorsRec : [serviceErrorsRec];
                }
            }
        }
        return undefined;
    }
}
