import { Vue } from "vue-property-decorator";

export default abstract class GenericDateDrivenComponent extends Vue {
  now: Date = new Date();

  get loadTableData(): boolean {
    return this.$store.getters.loadTableData;
  }

  set loadTableData(val: boolean) {
    this.$store.commit('SET_LOAD_TABLE_DATA', val);
  }

  get selectedYear() {
    return Number(this.$store.getters.selectedYear);
  }

  set selectedYear(selected: number) {
    if (selected !== this.selectedYear) {
      if (Number(this.$store.getters.activeCashbookDetails?.DATUMERSTEREINTRAG.split('/')[0]) === selected) {
        if (Number(this.$store.getters.activeCashbookDetails?.DATUMERSTEREINTRAG.split('/')[1]) > this.selectedMonth) {
          this.selectedMonth = 11;
        }
      } else if (this.$store.getters.activeCashbookDetails?.DATUMLETZTEREINTRAG.length > 0
        && Number(this.$store.getters.activeCashbookDetails?.DATUMLETZTEREINTRAG.split('/')[0]) === selected) {
        if (Number(this.$store.getters.activeCashbookDetails?.DATUMLETZTEREINTRAG.split('/')[1]) < this.selectedMonth) {
          this.selectedMonth = Number(this.$store.getters.activeCashbookDetails?.DATUMLETZTEREINTRAG.split('/')[1]) - 1;
        }
      } else if (this.now.getFullYear() === selected) {
        this.selectedMonth = this.now.getMonth();
      }
      this.$store.commit('SET_SELECTED_YEAR', selected);
      this.changeMonth();
    }
  }

  get selectedMonth(): number {
    return this.$store.getters.selectedMonth;
  }

  set selectedMonth(month: number) {
    this.$store.commit('SET_SELECTED_MONTH', month);
  }

  get activeCashbookTitle(): string {
    return this.$store.getters.selectedCashbook
      ? this.$store.getters.selectedCashbook.VAL : '';
  }

  abstract changeMonth(): void;

  mounted() {
    this.selectedMonth = Number(this.$store.getters.selectedMonth);
    this.changeMonth();
  }
}
