
import {Component, Vue} from 'vue-property-decorator';
import SidemenuEntry from '@/components/general/sidemenu/SidemenuEntry.vue';
import {mainSites} from '@/router';
import SidemenuHeader from '@/components/general/sidemenu/SidemenuHeader.vue';
import Cashbook from '@/assets/models/Cashbook';
import LogoutDialog from "@/components/cashbook/dialogs/LogoutDialog.vue";
import CustomDrawer from "@/components/general/sidemenu/CustomDrawer.vue";
import Webservice from '@/assets/service/Webservice';
import vuetify from "@/plugins/vuetify";

@Component({
    methods: {
        vuetify() {
            return vuetify
        }
    },
    components: {
        LogoutDialog,
        SidemenuHeader,
        SidemenuEntry,
        CustomDrawer,
    },
})
export default class Sidemenu extends Vue {
    elements = mainSites;

    get items(): Cashbook[] {
        return this.$store.getters.cashbooks;
    }

    private get showLogOut(): boolean {
        return !this.$store.getters.caller && !this.$store.getters.isInjected;
    }

    private get hasOpener(): boolean {
        return !!window.opener;
    }

    get selectedCashbook() {
        if (this.$store.getters.selectedCashbook.NUM === undefined
            || this.$store.getters.cashbooks.filter(value => value.NUM === this.$store.getters.selectedCashbook.NUM).length === 0) {

            if (this.$store.getters.cashbooks !== null
                && this.$store.getters.cashbooks !== undefined
                && this.$store.getters.cashbooks.length > 0) {
                [this.selectedCashbook] = this.$store.getters.cashbooks;
                return this.$store.getters.cashbooks[0];
            }
        }
        return this.$store.getters.selectedCashbook;
    }

    set selectedCashbook(value: Cashbook) {
        if (value) {
            this.$store.commit('SET_SELECTED_CASHBOOK', value);
            Webservice.getCashbookInfo();
            Webservice.getTaxRates();
            this.$emit('close');
            // this.$router.push('/');
        }
    }

    toggleCombobox() {
        const cashbookCbx: any = this.$refs.cashbookCbx;
        if (cashbookCbx.isMenuActive) {
            this.closeCombobox();
        } else {
            this.openCombobox();
        }
    }

    public closeCombobox() {
        const cashbookCbx: any = this.$refs.cashbookCbx as any;
        cashbookCbx.isMenuActive = false;
    }

    public openCombobox() {
        const cashbookCbx: any = this.$refs.cashbookCbx as any;
        cashbookCbx.isMenuActive = true;
        cashbookCbx.focus();
    }

    private showLink(element): boolean {
        if (element.meta && element.meta.userAuthorized) {
            return element.meta.userAuthorized();
        }
        return true;
    }

    private setHeaderTitle(value: Cashbook): void {
        if (this.$route.path && (this.$route.path === "" || this.$route.path === "/cashbook"))
            this.$route.meta!.headerTitle = value.VAL;
    }

    private resetCaller(): void {
        this.$store.commit("SET_CALLER", "");
    }

    private closeApp(): void {
        open(location.href, '_self');
        close();
    }
}
