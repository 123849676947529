
import { Component } from 'vue-property-decorator';
import StepGeneric from "@/components/general/genericComponents/checkOut/steps/StepGeneric";

@Component({})
export default class Step5 extends StepGeneric {
    private committable: boolean = false;
    private processing: boolean = false;
    private comment: string = '';
    private cancelPollingTask: boolean = false;

    finishStep() {
        this.reload();
        this.next();
    }

    visible(visible: boolean) {
        this.cancelPollingTask = !visible;

        if (visible) {
            this.awaitProcessable();
        }
    }

    public beforeDestroy() {
        this.cancelPollingTask = true;
    }

    private async awaitProcessable() {
        this.cancelPollingTask = false;
        this.committable = false;
        while (!this.committable && !this.cancelPollingTask) {
            const cashbook = this.$store.getters.selectedCashbook.NUM;
            this.committable = await this.$ports.billing.isCashbookBillingCommittable(cashbook);
            // wait 1 second before starting the next request
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
    }

    private async commit() {
        if (this.processing) {
            return;
        }
        this.processable = true;
        this.setLoading(true);
        const cashbook = this.$store.getters.selectedCashbook.NUM;
        await this.$ports.billing.commitCashbookBilling(cashbook, this.comment);
        this.reload();
        this.setLoading(false);
    }
}
