import moment from "moment-mini";
import CashbookPaymentMethodResponseEntry
    from "@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry";
import i18n from "@/i18n";

export default class FilterCashbookItem {
    public DATUM: string | null = '';
    public PAYMENT: CashbookPaymentMethodResponseEntry =new CashbookPaymentMethodResponseEntry(-1, 0, i18n.t('All').toLocaleString());
    public BUCHUNGSTEXT: string | null = '';

    get RequestDate(): moment.Moment | undefined {
        if (this.DATUM) {
            return moment(this.DATUM);
        }
        return undefined;
    }

    public clear(): void {
        this.DATUM = '';
        this.BUCHUNGSTEXT = '';
        this.PAYMENT=new CashbookPaymentMethodResponseEntry(-1, 0, i18n.t('All').toLocaleString());
    }

    public get isEmpty(): boolean {
        return !this.DATUM && !this.BUCHUNGSTEXT && (!this.PAYMENT || this.PAYMENT.NUMMER===-1);
    }
}
