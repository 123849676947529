import GenericDateDrivenComponent
  from "@/components/general/genericComponents/dateDrivenComponents/GenericDateDrivenComponent";
import store from "@/store/store";
import { CashbookReportsResponseEntry } from "@/assets/service/responseTypes/report/CashbookReportsResponse";
import Webservice from '@/assets/service/Webservice';

export default class Report extends GenericDateDrivenComponent {
  now: Date = new Date();

  get entries(): CashbookReportsResponseEntry[] {
    return this.loadTableData ? [] : this.$store.getters.reportEntries;
  }

  changeMonth() {
    this.loadTableData = true;
    store.commit('SET_CASHBOOKENTRIES_FOR_SELECTED_MONTH', []);
    Webservice.getCashbookProtocols(() => {
      this.loadTableData = false;
    });
  }

  openReport(date: string, callBackFinally: () => void) {
    Webservice.getReportURL(date, (url) => {
      window.open(url, '_blank');
    }, callBackFinally)
  }
}
