
  import { Component, Vue } from 'vue-property-decorator';
  import AdminElement from "@/components/admin/AdminElement.vue";
  import DesktopHeader from "@/components/general/DesktopHeader.vue";
  import PermissionUtil from "@/assets/utils/PermissionUtil";
  @Component({
    components: {
      AdminElement,
      DesktopHeader,
    },
  })
  export default class AdminDesktop extends Vue {
    private get showCashbookEntryLink():boolean{
       return PermissionUtil.hasPermissionToCreateTemplates() ||
        PermissionUtil.hasPermissionToEditTemplates() ||
        PermissionUtil.hasPermissionToDeleteTemplates();

    }
  }
