
import {Component, Prop, Vue} from "vue-property-decorator";
import Webservice from "@/assets/service/Webservice";
import {ServiceError} from "@/modules/billing";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import PermissionUtil from "@/assets/utils/PermissionUtil";


@Component({components: {LoadingSpinner}})
export default class CloseDay extends Vue {
    private errorMessage: string = ""
    private success: boolean = false;

    private showPrompt: boolean = false;

    private cashbook: any;

    private async start(): Promise<void> {
        this.showPrompt = false;
        try {
            let start = await this.$ports.billing.isCashbookBillingStartable(this.cashbook);

            if (start) {
                try {
                    await this.$ports.billing.startCashbookBilling(this.cashbook);
                    await this.$router.push('/cashbook/day-closing');
                } catch (e: any) {
                    this.success = false;
                    if ((e as ServiceError[])?.length > 0) {
                        this.errorMessage = e[0].description;
                    } else {
                        this.errorMessage = e;
                    }
                }
            } else {
                this.success = false;
                this.errorMessage = "Der Tagesabschluss kann nicht gestartet werden.";
            }
        } catch (e: any) {
            this.success = false;
            if ((e as ServiceError[])?.length > 0) {
                this.errorMessage = e[0].description;
            } else {
                this.errorMessage = e;
            }
        }
    }

    async mounted() {
        const query = {...this.$route.query};
        if (query.sid) {
            try {
                if(query.surl && query.surl !== ''){
                    this.$store.commit("setServiceUrl", query.surl);
                }
                const sessionInfo = await this.$ports.auth.getSessionInfo(query.sid.toString());
                if (sessionInfo) {
                    this.$store.commit("setAuthToken", query.sid.toString());
                    this.$store.commit("setBranchId", sessionInfo.Filiale.ID);
                    this.$store.commit("setUserId", sessionInfo.User.ID);
                    this.$store.commit("setLoggedIn", true);
                    this.$store.commit("setIsInjected", true);
                    const cashbooks = await Webservice.getCashbooks();
                    if (cashbooks && cashbooks.length > 0) {
                        const book = cashbooks.find((book) => {
                            if (book.FILIALID) {
                                return book.FILIALID === sessionInfo.Filiale.ID;
                            }
                        });
                        if (book && book.NUM) {
                            this.$store.commit('SET_SELECTED_CASHBOOK', book);
                        } else {
                            console.log(`No Cashbook matches FilialId = ${sessionInfo.Filiale.ID}`);
                            this.$store.commit('SET_SELECTED_CASHBOOK', cashbooks[0]);
                        }
                    }

                    this.cashbook = this.$store.getters.selectedCashbook.NUM;

                    const permissions = await Webservice.getUserPermissionsAsync(this.cashbook);


                    if (permissions.ABSCHLUSS === 0) {
                        this.errorMessage = "Sie haben nicht die Berechtigung einen Tagesabschluss durchzuführen.";
                        this.success = false;
                    } else {

                        const state = await this.$ports.billing.getCashbookBillingState(this.cashbook);
                        Webservice.getCashbookBillingDate();

                        // @ts-ignore a fix causes an endless redirect loop
                        if (state.AbrechnungStatus !== undefined && state.AbrechnungStatus !== 0 && state.AbrechnungStatus !== 5) {
                            await this.$router.push('/cashbook/day-closing');
                        } else {
                            this.showPrompt = true;
                        }
                    }
                }
            } catch (e: any) {
                this.success = false;
                if ((e as ServiceError[])?.length > 0) {

                    this.errorMessage = e[0].description;
                } else {
                    this.errorMessage = e;
                }

            }
        }
    }

    private get dialogTitle(): string {
        return this.$t('Dialog.Billing.Title').toString();
        //+ ' - ' + this.$store.getters?.activeCashbookDetails?.BEZEICHNUNG
    }
}