
  import { Component } from 'vue-property-decorator';
  import Step1 from "@/components/general/genericComponents/checkOut/steps/Step1.vue";
  import Step4 from "@/components/general/genericComponents/checkOut/steps/Step4.vue";
  import Step5 from "@/components/general/genericComponents/checkOut/steps/Step5.vue";
  import CashCountDialog from "@/components/cashbook/dialogs/dayClosing/CashCountDialog.vue";
  import GenericCheckOut, { StepComponent } from "@/components/general/genericComponents/checkOut/GenericCheckOut.vue";
  import { CashbookBillingConfiguration } from "@/assets/service/responseTypes/cashbook/cashbookBilling/CashbookBillingDefaultResponse";
  import Webservice from '@/assets/service/Webservice';

  @Component({
    components: { CashCountDialog, Step1, Step4, Step5 }
  })
  export default class DayEndClosing extends GenericCheckOut {

    title = 'DayEndClosing.Title';

    get steps() {
      let steps: StepComponent[] = [];

      if (this.activeConfig.ZAEHLPROT) {
        steps.push({
          title: 'DayEndClosing.Step1',
          stepNumber: 1,
          component: Step1,
        });
      }
      if (this.activeConfig.ZAEHLABLIEF || this.activeConfig.ZAEHLABLIEFZM[0] !== 0) {
        steps.push({
          title: 'DayEndClosing.Step2',
          stepNumber: 2,
          component: Step1,
        });
      }
      if (this.activeConfig.ZAEHLBESTAND) {
        steps.push({
          title: 'DayEndClosing.Step3',
          stepNumber: 3,
          component: Step1,
        });
      }

      steps.push({
        title: 'DayEndClosing.Step4',
        component: Step4,
        stepNumber: 4,
      }, {
        title: 'DayEndClosing.Step5',
        component: Step5,
        stepNumber: 5,
      });

      return steps;
    }

    get activeConfig(): CashbookBillingConfiguration {
      return this.$store.getters.dayEndClosingConfig;
    }

    stateCallback(val) {
      this.$store.commit('SET_CASHBOOK_BILLING_STATE', val);
      if (val.AbrechnungStatus === 0 || val.AbrechnungStatus === 5) {
        this.$router.push('/');
      } else {
        if (val.KassenberichtStatus === 5 || val.AbrechnungStatus === 3) {
          this.currentStep = this.steps.length;
        } else if (val.ZaehlprotokollStatus === 3) {
          this.currentStep = this.steps.length - 1;
        } else if (this.currentStep >= this.steps.length - 1) {
          this.currentStep = Math.max(this.steps.length - 2, 1);
        }
      }
    }

    created() {
      this.loading = true;
      Webservice.getCashbookPaymentDivision();
      Webservice.getCashbookBillingState((val) => {
        this.stateCallback(val);
        this.loading = false;
      });
    }
  }
