export default class CashbookPaymentMethodResponseEntry {
  NUMMER!: number;

  KONTO!: number;

  BEZEICHNUNG!: string;

  constructor(NUMMER?: number | undefined,
              KONTO?: number | undefined,
              BEZEICHNUNG?: string | undefined) {
    if (NUMMER !== undefined) {
      this.NUMMER = NUMMER;
    }
    if (KONTO !== undefined) {
      this.KONTO = KONTO;
    }
    if (BEZEICHNUNG !== undefined) {
      this.BEZEICHNUNG = BEZEICHNUNG;
    }
  }
}
