
  import { Component, Model, Vue } from 'vue-property-decorator';
  import CashbookTableRow from '@/components/cashbook/table/cashbook/CashbookTableRow.vue';
  import CashbookEntriesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
  import { CashbookEntryDetailResponse } from "@/assets/service/responseTypes/cashbook/cashbookEntryDetails/CashbookEntryDetailResponse";
  import isIngestion from "@/assets/utils/CashbookUtils";
  import { cashbookDetailsHeader } from "@/assets/config/TableHeaders";
  import PermissionUtil from "@/assets/utils/PermissionUtil";
  import moment from "moment-mini";
  import Webservice from '@/assets/service/Webservice';

  @Component({ components: { CashbookTableRow } })
  export default class DetailsTable extends Vue {
    @Model() model!: CashbookEntriesResponseEntry;

    details: CashbookEntryDetailResponse | null = null;

    loading: boolean = false;

    get header() {
      return cashbookDetailsHeader;
    }

    get hasDivision() {
      return this.model.MWSTSATZ_2 !== 0
          || this.model.MWSTSATZ_2_TEXT !== ''
          || this.model.MWSTSATZ_3 !== 0
          || this.model.MWSTSATZ_3_TEXT !== ''
          || this.model.ZAHLUNGSMITTEL_2 !== 0
          || this.model.ZAHLUNGSMITTEL_2_TEXT !== ''
          || this.model.ZAHLUNGSMITTEL_3 !== 0
          || this.model.ZAHLUNGSMITTEL_3_TEXT !== '';
    }


    get divisions() {
      const divisions: any = [];

      if (this.hasDivision && this.details) {
        if (this.details.Lst_Zahlungen.length > 1) {
          divisions.push(this.details.Lst_Zahlungen);
        }
        if (this.details.Lst_Steuern.length > 1) {
          divisions.push(this.details.Lst_Steuern);
        }
      }

      return divisions;
    }

    get editable() {
      const now: Date = new Date();
      return (this.model.PERSISTENZ === 1
        && PermissionUtil.hasPermissionToEditCashbookEntry() && moment(this.model.DATUM).day() === now.getDate());
    }

    get ingestion() {
      const [type] = this.$store.getters.cashbookEntryTypes
        .filter(value => value.NUM === this.model.TYP);
      return isIngestion(type);
    }

    mounted() {
      if (this.hasDivision) {
        this.loading = true;
        Webservice.getCachedCashbookEntryDetails(this.model).then(value => {
          if (value.ServiceErrors) {
            Webservice.preAnalyzeErrors(value.ServiceErrors);
          } else {
            this.details = value;
          }
        }).finally(() => this.loading = false);
      }
    }
  }
