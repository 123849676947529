
  import { Component, Vue } from 'vue-property-decorator';
  import AdminMobile from '@/views/admin/AdminMobile.vue';
  import AdminDesktop from "@/views/admin/AdminDesktop.vue";


  @Component({
    components:{
      AdminMobile,
      AdminDesktop
    }
  })
  export default class Admin extends Vue {

  }
