
  import { Component, Vue } from 'vue-property-decorator';
  import CashbookEntryTemplate from "@/assets/models/CashbookEntryTemplate";
  import PermissionUtil from '@/assets/utils/PermissionUtil';
  import {Store} from "vuex";

  @Component
  export default class CashbookTemplateGrid extends Vue
  {
    //member
    private isMobile=this.$vuetify.breakpoint.smAndDown;

    //properties
    private get items(): CashbookEntryTemplateDetail[] {
      return this.$store.getters.availableCashbookEntryTemplates.map(cet=> {return new CashbookEntryTemplateDetail(cet,this.$store) ;});
    }
    public get canAdd():boolean{
      return PermissionUtil.hasPermissionToCreateTemplates();
    }
    public get canEdit():boolean{
      return PermissionUtil.hasPermissionToEditTemplates();
    }
    public get canDelete():boolean{
      return PermissionUtil.hasPermissionToDeleteTemplates();
    }

    private get headers() : {
      text: string,
      value: string,
      sortable: boolean
    }[] {
      if(this.isMobile){
        return [
          {text: 'BUCHUNGSTEXT', value: 'BUCHUNGSTEXT',sortable: true},
          {text: 'TYP', value: 'TYPTEXT',sortable: true},
          {text: 'KONTO', value: 'KONTO',sortable: true},
          {text: '', value: 'actions',sortable: false}];
      }
      return [
        {text: 'BUCHUNGSTEXT', value: 'BUCHUNGSTEXT',sortable: true},
        {text: 'TYP', value: 'TYPTEXT',sortable: true},
        {text: 'ZAHLUNGSMITTEL', value: 'ZAHLUNGSMITTELTEXT',sortable: true},
        {text: 'KONTO', value: 'KONTO',sortable: true},
        {text: 'KOSTENSTELLE', value: 'KOSTENSTELLE',sortable: true},
        {text: 'BEMERKUNG', value: 'BEMERKUNG',sortable: false},
        {text: '', value: 'actions',sortable: false}];
    }
//methods
    viewItem (item) {
      this.$emit("ViewItem", item);
    }
    addItem () {
      this.$emit("AddItem");
    }
    editItem (item) {
      this.$emit("EditItem", item);
    }
    deleteItem (item) {
      this.$emit("DeleteItem", item);
    }
  }
  class CashbookEntryTemplateDetail extends CashbookEntryTemplate
  {
    constructor(cashbookEntryTemplate:CashbookEntryTemplate,store:Store<any> ) {
      super(cashbookEntryTemplate);
      this.ZAHLUNGSMITTELTEXT=store.getters.availableCashbookPayments.find(value => value.NUMMER === cashbookEntryTemplate.ZAHLUNGSMITTEL).BEZEICHNUNG;
      this.TYPTEXT=store.getters.cashbookEntryTypes.find(value => value.NUM === cashbookEntryTemplate.TYP).VAL;
    }
    TYPTEXT!: string;
    ZAHLUNGSMITTELTEXT!: string;
  }
