
import { Component, Vue, Watch } from 'vue-property-decorator';
import CashbookEntriesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
import CashbookDivisionComponent from '@/components/cashbook/dialogs/transactionDialog/CashbookDivisionCpmponent.vue';
import calcTaxByCurrentAndRate from '@/assets/utils/TaxUtils';
import CashbookDivision from '@/components/cashbook/dialogs/transactionDialog/CashbookDivision';
import DivisionStyle from '@/components/cashbook/dialogs/transactionDialog/DivisionStyle';
import DialogMode from '@/components/cashbook/dialogs/transactionDialog/DialogMode';
import Taxes from '@/assets/service/parameterTypes/cashbook/taxes/Taxes';
import Payment from '@/assets/service/parameterTypes/cashbook/payments/Payment';
import CashbookEnumerationsEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEnumerations/CashbookEnumerationsEntry';
import CashbookTaxRatesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookTaxRates/CashbookTaxRatesResponseEntry';
import {
    CashbookEntryDetailResponse,
    Revisions,
} from '@/assets/service/responseTypes/cashbook/cashbookEntryDetails/CashbookEntryDetailResponse';
import CashbookPaymentMethodResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry';
import Lightbox from '@/components/cashbook/dialogs/lightbox/Lightbox.vue';
import CashbookEntryTemplate from '@/assets/models/CashbookEntryTemplate';
import DocumentHeader from '@/assets/models/DocumentHeader';
import PermissionUtil from '@/assets/utils/PermissionUtil';
import { get_tex_width, TransactionDialogErrors } from '@/assets/utils/transactionDialog/transactionDialogUtils';
import ServiceErrors from '@/assets/service/responseTypes/ServiceErrors';
import DatePicker from '@/components/cashbook/dialogs/transactionDialog/DatePicker.vue';
import { REQUEST_DATEFORMAT, REQUEST_TIMEFORMAT } from '@/assets/utils/DateUtil';
import moment from 'moment-mini';
import CashCountDialog from '@/components/cashbook/dialogs/dayClosing/CashCountDialog.vue';
import FancyCurrencyComponent from '@/components/general/FancyCurrencyComponent.vue';
import { CountProtocolListEntry } from '@/assets/service/responseTypes/cashbook/countProtocol/CountProtocol';
import CashbookAccount from '@/assets/models/CashbookAccount';
import Webservice from '@/assets/service/Webservice';
import { CashbookImageRequest } from '@/assets/models/CashbookImageRequest';
import type { CashbookEntryAttachment } from '@/modules/cashbook';

@Component({
    components: {
        FancyCurrencyComponent,
        DatePicker,
        Lightbox,
        CashbookDivisionComponent,
        CashCountDialog
    },
})
export default class TransactionDialog extends Vue {
    // helper variables to use enums in template
    private readonly divisionStyles = DivisionStyle;
    private readonly dialogMode = DialogMode;
    private readonly PermissionUtil = PermissionUtil;

    // component state
    private loading: boolean = false;
    private showSafetyDialog: boolean = false;
    private showReasonDialog: boolean = false;
    private errors: TransactionDialogErrors = new TransactionDialogErrors();

    // readonly data?
    private type: CashbookEnumerationsEntry | null = null;
    private details: CashbookEntryDetailResponse | null = null;

    //  cashbook data
    private current: number = 0; // => cashbookEntry.BRUTTO
    private comment: string = ''; // => cashbookEntry.BEMERKUNG
    private account: number = 0; // => cashbookEntry.KONTO
    private cashbookId: number = 0; // => cashbookEntry.LFDNUMMER
    private countCentre: number = 0; // => cashbookEntry.KOSTENSTELLE
    private selectedVorfall: string = ''; // => cashbookEntry.BUCHUNGSTEXT
    private selectedDateStored: Date | null = null;

    private selectedTemplate: CashbookEntryTemplate | null = null;
    private reason: string = '';
    private file: File | null = null;
    private attachment: CashbookEntryAttachment|null = null;

    private selectedPayment: CashbookPaymentMethodResponseEntry | null = null;
    private taxRate: CashbookTaxRatesResponseEntry | null = null;

    private divisionsTax: CashbookDivision[] = [];
    private divisionsPayment: CashbookDivision[] = [];

    private reportLoading: boolean = false;
    private countProtocols: CountProtocolListEntry[] = [];

    // these keys are probably used to forceupdate parts of the ui
    // better way would be to used $forceUpdate() instead of random number keys
    private key: number = Math.random() * 10;
    private buttonKey: number = Math.random() * 10;

    // vue lifecycle methods

    /**
     * Lifecylce Method called when this component gets created
     */
    public created() {
        [this.taxRate] = this.taxRates.filter(value => value.STEUERSATZ === 19);
        this.loadData();
        this.selectedDateStored = this.$store.getters.filterDate || new Date();
        // this.type = this.typeProp;
    }

    /**
     * Lifecylce Method called when this component is rendered on the dom
     */
    public mounted() {
        document.addEventListener('backbutton', this.onBackButton);
        this.dialogDiv?.addEventListener('scroll', this.closeMenus, { passive: true });
    }

    /**
     * Lifecylce Method called when this component gets destroyed
     */
    public destroyed() {
        document.removeEventListener('backbutton', this.onBackButton);
        this.dialogDiv?.removeEventListener('scroll', this.closeMenus);
    }

    private get dialogDiv(): HTMLElement|undefined {
        const root = this.$refs.roothelper as HTMLElement;
        if (!root) {
            return undefined;
        }
        return root.closest('.v-dialog') as HTMLElement || undefined;
    }

    private get userCanLoadReport() : boolean{
        return PermissionUtil.hasPermissionForCashCheck();
    }

    private get cashbookEntry(): CashbookEntriesResponseEntry {
        return this.$store.getters.cashbookEntry;
    }

    private get mode(): DialogMode {
        return this.$store.getters.transactionDialogMode;
    }

    private get showHistory(): boolean {
        return this.mode !== DialogMode.CREATE && this.history !== undefined && this.history.GRUND.length > 0;
    }

    private get history(): Revisions | undefined {
        // KAS-111 sort by KORREKTURNUMMER instead of DATUMAENDERUNG and UHRZEITAENDERUNG, as these can be invalid
        return this.details?.Lst_Revisionen.sort((a, b) => b.KORREKTURNUMMER - a.KORREKTURNUMMER)[0];
    }

    private get historyDate(): string {
        return this.history ? this.$d(moment(this.history.DATUMAENDERUNG, REQUEST_DATEFORMAT).toDate(), 'long') : '';
    }

    private get historyTime(): string {
        return this.history ? this.$d(moment(this.history.UHRZEITAENDERUNG, REQUEST_TIMEFORMAT).toDate(), 'time') : '';
    }

    private get divisionTaxEnabled(): boolean {
        return this.canSplit && this.taxInputNeeded && this.divisionsTax.length < 3;
    }

    private get divisionPaymentEnabled(): boolean {
        return this.canSplit && !this.isAfterDailyClosing && this.divisionsPayment.length < 3;
    }

    private get canSplit() {
        return (this.editable && (this.type?.NUM !== 5 && this.type?.NUM !== 6 && this.type?.NUM !== 7));
    }

    private get availableTemplates(): CashbookEntryTemplate[] {
        const templates: CashbookEntryTemplate[] = this.$store.getters.availableCashbookEntryTemplates || [];
        return templates.filter((template) => this.availableTypesNum.includes(template.TYP));
    }

    private get availableTypesNum(): number[] {
        if (this.isAfterDailyClosing) {
            switch (this.type?.NUM) {
                case 1: return [1, 3]; // Einnahme
                case 3: return [1, 3]; // Umsatzneutrale Einnahme
                case 2: return [2, 4]; // Ausgabe
                case 4: return [2, 4]; // Umsatzneutrale Ausgabe
                case 5: return [5]; // Transit (Einlage)
                case 6: return [6]; // Transit (Ablieferung)
                case 7: return [7]; // Bestand
            }
        }
        // new entry return all types that match the basic type ingestion / expense
        if (this.$store.getters.ingestion) {
            return [1, 3, 5, 7];
        }
        return [2, 4, 6];
    }

    private get availableTypes(): CashbookEnumerationsEntry[] {
        const types: CashbookEnumerationsEntry[] = this.$store.getters.cashbookEntryTypes || [];
        return types.filter((type) => this.availableTypesNum.includes(type.NUM));
    }

    private get selectedDate(): Date {
        return this.selectedDateStored === null ? new Date() : this.selectedDateStored;
    }

    private get reasonTitle(): string {
        if (this.mode === DialogMode.DELETE) {
            return this.cashbookEntry?.PERSISTENZ === 1 ? 'Delete' : 'Cancel';
        }
        if (this.mode === DialogMode.EDIT) {
            return 'Change';
        }
        return 'Change';
    }

    private get taxRates(): CashbookTaxRatesResponseEntry[] {
        return this.$store.getters.taxRates;
    }

    private get divisions(): CashbookDivision[] {
        return this.divisionsPayment.concat(this.divisionsTax);
    }

    private get accountEditable(): boolean {
        return this.editable && (PermissionUtil.hasPermissionToEditTemplates() || !this.selectedTemplate?.KONTO);
    }

    private get hasToUseTemplate(): boolean {
        // PermissionUtil cannot be used in vue template directly, so we have to use a wrapper computed property
        return PermissionUtil.hasToUseTemplates();
    }

    private get taxInputNeeded(): boolean {
        return this.type?.VAL === 'Einnahme' || this.type?.VAL === 'Ausgabe';
    }

    private get canChangeDate(): boolean {
        return this.mode === DialogMode.CREATE && PermissionUtil.hasPermissionToEditAfterDayClosing() && this.$vuetify.breakpoint.mdAndUp;
    }

    private get editable(): boolean {
        if (this.loading) {
            return false;
        }
        if (this.mode === DialogMode.CREATE) {
            return true;
        }
        if (this.mode === DialogMode.EDIT) {
            if (this.divisionsTax.length < 1 && this.taxInputNeeded) {
                // single tax (no division)
                if (!this.taxRates || !this.isTaxrateAvailable(this.taxRate?.STEUERSATZ)) {
                    console.log("taxrates 1 - false");
                    // the selected tax rate has been removed and can no longer be found in the list
                    return false;
                }
            } else {
                console.log("taxrates 2 - false");
                // tax division
                if (!this.taxRates || this.divisionsTax.find((divisionTax) => divisionTax.taxRate && !this.isTaxrateAvailable(divisionTax.taxRate.STEUERSATZ))) {
                    return false;
                }
            }

            if (this.hasToUseTemplate && (!this.availableTemplates || this.availableTemplates.find(item => item.BUCHUNGSTEXT === this.selectedVorfall) === undefined)) {
                console.log("templates 1 - false");
                // if user has to use a template and a template with the given name cannot be found, disable editing
                return false;
            }
            return true;
        }
        return false;
    }

    private isTaxrateAvailable(taxrate: number|undefined): boolean {
        if (taxrate !== undefined) {
            return this.taxRates.find((taxRate) => taxRate.STEUERSATZ === taxrate) !== undefined;
        }
        return false;

    }

    private get isAfterDailyClosing(): boolean {
        // PERSISTENZ === 1 => ?
        // PERSISTENZ === 2 => after Daily Closing
        return this.cashbookEntry?.PERSISTENZ === 2;
    }

    private get hasRightToCreateOrEditTemplate(): boolean {
        return (this.selectedTemplate !== undefined && this.selectedTemplate !== null && PermissionUtil.hasPermissionToEditTemplates())
            || ((this.selectedTemplate === undefined || this.selectedTemplate === null) && PermissionUtil.hasPermissionToCreateTemplates());
    }

    private get containsReport(): boolean {
        return this.cashbookEntry?.HAT_ABRECHNUNG === 1;
    }

    private get diff(): number {
        return this.cashbookEntry?.DIFFERENZ;
    }

    private get tax(): number {
        let tax: number = 0;
        if(!this.taxRate) {
            if(this.details && this.details.Lst_Steuern) {
                return calcTaxByCurrentAndRate(this.current, this.details.Lst_Steuern[0].STEUERSATZ);
            }
        }
        if (this.divisionsTax.length === 0 && this.taxRate) {
            tax = calcTaxByCurrentAndRate(this.current, this.taxRate.STEUERSATZ);
        } else if (this.divisionsTax.length > 0 && this.taxRate) {
            this.divisionsTax.forEach((value: CashbookDivision) => {
                tax += value.taxRate ? calcTaxByCurrentAndRate(value.current, value.taxRate.STEUERSATZ) : 0;
            });
        }
        return tax;
    }

    private get min(): number|undefined{
        //readonly
        if (!this.editable || this.isAfterDailyClosing)
            return undefined;

        return 0;
    }

    private get day(): number {
        if (this.$vuetify.breakpoint.smAndDown) {
            return Number(this.$store.getters.selectedDay);
        } else {
            const now = new Date();
            if (now.getFullYear() === Number(this.$store.getters.selectedYear)
                && now.getMonth() === Number(this.$store.getters.selectedMonth)) {
                return now.getDate();
            } else {
                return (new Date(this.$store.getters.selectedYear,
                    this.$store.getters.selectedMonth + 1, 0)).getDate();
            }
        }
    }

    private get isRebook() {
        console.info(this.$store.getters.cashbookBillingDate)
        return moment(this.$store.getters.cashbookBillingDate, REQUEST_DATEFORMAT).isSameOrAfter(this.selectedDate, 'day');
    }

    private get accounts(): ComboboxItem[] {
        return this.$store.getters.cashbookAccounts
            .filter((account: CashbookAccount) => account.TYP === this.type?.NUM)
            .map((account: CashbookAccount) => ({ text: `${account.KONTO} - ${account.BUCHUNGSTEXT}`, value: account.KONTO }));
    }

    // watchers

    @Watch('type')
    private onTypeChanged() {
        if (this.type?.NUM === 5 || this.type?.NUM === 6 || this.type?.NUM === 7) {
            this.divisionsTax = [];
            this.divisionsPayment = [];
        }
        this.buttonKey = Math.random() * 10;
    }

    @Watch('divisionsTax', { deep: true })
    private onDivisionTaxChanged(newVal, oldVal) {
        if (newVal.length > 1 && !this.lastElementChanged(newVal, oldVal)) {
            this.divisionChanged(this.divisionStyles.Tax);
        }
    }

    @Watch('divisionsPayment', { deep: true })
    private onDivisionPaymentChanged(newVal, oldVal) {
        if (newVal.length > 1 && !this.lastElementChanged(newVal, oldVal)) {
            this.divisionChanged(this.divisionStyles.Payment);
        }
    }

    @Watch('current')
    private onCurrentChanged() {
        if (this.divisionsTax.length > 0) {
            this.divisionChanged(DivisionStyle.Tax);
        }
        if (this.divisionsPayment.length > 0) {
            this.divisionChanged(DivisionStyle.Payment);
        }
    }
    private get taxRateReadOnly():string{
        let activeTax:CashbookTaxRatesResponseEntry = new CashbookTaxRatesResponseEntry();
        if(this.taxRate!==null){
            activeTax=this.taxRate;
        }
        return this.taxRateDisplay(activeTax);
    }
    // functions
    private taxRateDisplay(taxRate:CashbookTaxRatesResponseEntry) :string {
        if(taxRate && taxRate.STEUERSATZ !== undefined && taxRate.STEUERSATZ !== null) {
            return `${this.$n(taxRate.STEUERSATZ)}%`;
        }
        if(this.details && this.details.Lst_Steuern){
            return `${this.details.Lst_Steuern[this.details.Lst_Steuern.length-1].STEUERSATZ}%`;
        }
        return "";
    }
    private updateAccount(value: string|number|ComboboxItem) {
        const comboboxItem = (value as ComboboxItem);
        if (comboboxItem !== undefined && comboboxItem.value !== undefined) {
            this.account = comboboxItem.value;
        } else {
            const number = parseInt(value.toString());
            if (!Number.isNaN(number)) {
                this.account = number;
            } else {
                this.account = 0;
            }
        }
    }

    private showDiff(): boolean {
        return this.cashbookEntry !== null && this.cashbookEntry !== undefined && this.cashbookEntry.DIFFERENZ !== 0;
    }

    private openReport() {
        this.reportLoading = true;
        Webservice.getReportURL(this.cashbookEntry.DATUM,
            (url) => window.open(url, '_blank'),
            () => this.reportLoading = false);
    }

    private openProtocol(protocol: CountProtocolListEntry) {
        this.$store.commit('SET_DATE_COUNT_DIALOG', protocol.DATUM);
        this.$store.commit('SET_LFNR_COUNT_DIALOG', protocol.LFDNUMMER);
        this.$store.commit('SET_STEP_COUNT_DIALOG', protocol.ART);
        this.$store.commit('SET_DRAWER_COUNT_DIALOG', protocol.ART === 2 ? protocol.ZAHLUNGSMITTEL : Number(protocol.SCHUBLADE));
        this.$store.commit('SET_SHOW_COUNT_DIALOG', true);
    }

    private get_tex_width(txt, font): number {
        return get_tex_width(txt, font);
    }

    private lastElementChanged(newVal: CashbookDivision[], oldVal: CashbookDivision[]): boolean {
        return !newVal[newVal.length - 1].equals(oldVal[oldVal.length - 1]);
    }

    private checkTemplateInput(value: string): string | boolean {
        if (PermissionUtil.hasToUseTemplates()
            && !this.$store.getters.availableCashbookEntryTemplates.find((val: CashbookEntryTemplate) => val.BUCHUNGSTEXT === value)) {
            return this.$t('Dialog.Errors.InvalidTemplateName').toString();
        }
        return true;
    }

    /**
     * Load transaction details
     */
    private async loadData() {
        this.type = this.availableTypes[0];
        this.details = null;
        this.attachment = null;

        // KAS-114 caused by the Corona pandemie taxrates have been temporary decreased from 1. Jul to 31. Dec, so we
        // have to update the taxrate regulary
        //
        // this operation is blocking (=> await) because the callback of Webservice.getCachedCashbookEntryDetails is
        // using the taxrates loaded by this service call


        if (!this.cashbookEntry) {
            if (this.selectedDateStored === null || this.$vuetify.breakpoint.smAndDown) {
                this.selectedDateStored = new Date(this.$store.getters.selectedYear, this.$store.getters.selectedMonth, this.day);
            }
            return;
        }

        this.loading = true;
        this.type = this.$store.getters.cashbookEntryTypes.find(value => value.NUM === this.cashbookEntry.TYP) || null;
        this.current = this.cashbookEntry.BRUTTO;
        this.comment = this.cashbookEntry.BEMERKUNG;
        this.account = this.cashbookEntry.KONTO;
        this.cashbookId = this.cashbookEntry.LFDNUMMER;
        this.countCentre = this.cashbookEntry.KOSTENSTELLE;
        this.selectedDateStored = moment(this.cashbookEntry.DATUM + this.cashbookEntry.UHRZEIT,REQUEST_DATEFORMAT + REQUEST_TIMEFORMAT).toDate();
        await Webservice.getTaxRates(this.selectedDateStored);
        this.selectedVorfall = this.cashbookEntry.BUCHUNGSTEXT;

        if (this.cashbookEntry.HAT_BELEGBILD) {
            const attachment = await this.$ports.cashbook.getCashbookEntryAttachment({
                cashbookId: this.$store.getters.selectedCashbook.NUM,
                entryId: this.cashbookEntry.LFDNUMMER,
                date: this.cashbookEntry.DATUM
            });
            this.attachment = attachment || null;
        }

        Webservice.getCachedCashbookEntryDetails(this.cashbookEntry)
            .then((value: CashbookEntryDetailResponse) => {
                if (value && value.ServiceErrors === undefined) {
                    this.details = value;
                    if (value.Lst_Steuern.length > 1) {
                        value.Lst_Steuern.forEach((entry, index) => {
                            this.divisionsTax.push(
                                new CashbookDivision(
                                    entry.BRUTTOBETRAG,
                                    this.getMaxAvailableForLoadedData(index, DivisionStyle.Tax),
                                    entry,
                                    undefined,
                                    DivisionStyle.Tax,
                                ),
                            );
                        });
                    } else {
                        this.taxRate = this.taxRates.find(value1 => value1.STEUERSATZ === value.Lst_Steuern[0].STEUERSATZ) || null;
                    }
                    if (value.Lst_Zahlungen.length > 1) {
                        value.Lst_Zahlungen.forEach((entry, index) => {
                            this.divisionsPayment.push(
                                new CashbookDivision(
                                    entry.BRUTTOBETRAG,
                                    this.getMaxAvailableForLoadedData(index, DivisionStyle.Tax),
                                    undefined,
                                    this.$store.getters.availableCashbookPayments.find((value1) => value1.NUMMER === entry.ZAHLUNGSMITTEL),
                                    DivisionStyle.Payment,
                                ),
                            );
                        });
                    } else {
                        this.selectedPayment = this.$store.getters.availableCashbookPayments.find((value1) => value1.NUMMER === value.Lst_Zahlungen[0].ZAHLUNGSMITTEL);
                    }
                } else if (value) {
                    Webservice.preAnalyzeErrors(value.ServiceErrors);
                }
                if (value.Erweiterte_Informationen.HAT_ZAEHLPROTOKOLL) {
                    Webservice.getCountProtocols(this.cashbookEntry.DATUM, this.cashbookEntry.LFDNUMMER,
                        (protocols) => this.countProtocols = protocols);
                }
            })
            .finally(() => this.loading = false);
    }

    /**
     * Has any data been edited by the user?
     */
    private isDirty(): boolean {
        if (this.cashbookEntry) {
            // existing entry, compare inputs to old value
            return this.current !== this.cashbookEntry.BRUTTO
                || this.selectedVorfall !== this.cashbookEntry.BUCHUNGSTEXT
                || this.type && this.cashbookEntry.TYP !== this.type.NUM
                || this.selectedPayment !== null && this.selectedPayment.NUMMER !== this.cashbookEntry.ZAHLUNGSMITTEL
                || this.account !== this.cashbookEntry.KONTO
                || this.comment !== this.cashbookEntry.BEMERKUNG
                || this.countCentre !== this.cashbookEntry.KOSTENSTELLE
                || (moment(this.selectedDate).format(REQUEST_DATEFORMAT) !== this.cashbookEntry.DATUM)
                || this.paymentsDirty()
                || this.taxesDirty();
        }

        // new entry, compare inputs to default
        return this.type && this.type.NUM !== this.availableTypes[0].NUM
            || this.file !== null
            || this.divisionsPayment.length !== 0
            || this.divisionsTax.length !== 0
            || this.current !== 0
            || this.selectedVorfall !== ''
            || this.selectedPayment !== null
            || this.comment !== ''
            || this.taxRate && this.taxRate.STEUERSATZ !== 19
            || this.selectedDate.getFullYear() !== Number(this.$store.getters.selectedYear)
            || this.selectedDate.getMonth() !== Number(this.$store.getters.selectedMonth)
            || this.selectedDate.getDate() !== this.day;
    }

    private toggleCombobox(cbx: string) {
        const combobox: any = this.$refs[cbx] as any;
        if (combobox) {
            combobox.isMenuActive = !combobox.isMenuActive;
            if (combobox.isMenuActive) {
                combobox.focus();
            }
        }
    }

    private paymentsDirty(): boolean {
        // no existing payment
        if (!this.details) {
            return this.divisionsPayment.length > 0;
        }
        // if payments have not been divided divisionsPayment is empty and Lst_Zahlungen contains the single payment
        if (this.divisionsPayment.length === 0 && this.details.Lst_Zahlungen.length === 1) {
            return this.selectedPayment?.NUMMER !== this.details.Lst_Zahlungen[0].ZAHLUNGSMITTEL;
        }
        // check if an entry has been added or removed
        if (this.divisionsPayment.length !== this.details.Lst_Zahlungen.length) {
            return true;
        }
        // check if an entry has changed
        for (let i: number = 0; i < this.divisionsPayment.length; ++i) {
            if (this.divisionsPayment[i].current !== this.details.Lst_Zahlungen[i].BRUTTOBETRAG
                || this.divisionsPayment[i].payment.NUMMER !== this.details.Lst_Zahlungen[i].ZAHLUNGSMITTEL) {
                return true;
            }
        }
        return false;
    }

    private taxesDirty(): boolean {
        // no existing taxes
        if (!this.details) {
            return this.divisionsTax.length > 0;
        }
        // if taxes have not been divided divisionsTax is empty and Lst_Steuern contains the single tax
        if (this.divisionsTax.length === 0 && this.details.Lst_Steuern.length === 1) {
            return this.taxRate?.STEUERSATZ !== this.details.Lst_Steuern[0].STEUERSATZ;
        }
        // check if an entry has been added or removed
        if (this.divisionsTax.length !== this.details.Lst_Steuern.length) {
            return true;
        }
        // check if an entry has changed
        for (let i: number = 0; i < this.divisionsTax.length; ++i) {
            if (this.divisionsTax[i].current !== this.details.Lst_Steuern[i].BRUTTOBETRAG
                || this.divisionsTax[i].taxRate?.STEUERSATZ !== this.details.Lst_Steuern[i].STEUERSATZ) {
                return true;
            }
        }
        return false;
    }

    private isTemplateDirty(): boolean {
        if (this.selectedTemplate === null || this.selectedTemplate === undefined) {
            return this.isDirty();
        } else {
            return ((this.selectedPayment !== null && this.selectedTemplate.ZAHLUNGSMITTEL !== this.selectedPayment.NUMMER)
                || this.selectedTemplate.BUCHUNGSTEXT !== this.selectedVorfall
                || (this.taxRate !== null && this.taxRate !== undefined && this.selectedTemplate.MWSTART !== this.taxRate.ART)
                || (this.type !== null && this.type !== undefined && this.selectedTemplate.TYP !== this.type.NUM));
        }
    }

    private changeTemplate(value: CashbookEntryTemplate|string|undefined) {
        this.errors = new TransactionDialogErrors();
        if (!value) {
            return;
        }
        if (typeof value === 'string') {
            if (!PermissionUtil.hasToUseTemplates()) {
                this.selectedVorfall = value;
                return;
            } else {
                [value] = this.$store.getters.availableCashbookEntryTemplates
                    .filter((val: CashbookEntryTemplate) => val.BUCHUNGSTEXT === value);
            }
        }
        const template = value as CashbookEntryTemplate;
        if (template !== undefined && template.NUMMER !== undefined) {
            this.selectedTemplate = template;
            this.selectedVorfall = template.BUCHUNGSTEXT;
            [this.type] = this.$store.getters.cashbookEntryTypes.filter(
                value1 => value1.NUM === template.TYP,
            );
            if (!this.isAfterDailyClosing) {
                // KAS-175 the template can be changed after daily closing, in this case currents and payments must not
                // be changed
                if (template.BRUTTO !== 0) {
                    this.current = template.BRUTTO;
                }
                this.selectedPayment = this.$store.getters.availableCashbookPayments
                    .find((payment) => payment.NUMMER === template.ZAHLUNGSMITTEL);
            }
            if (template.KONTO) {
                this.account = template.KONTO;
            }
            if (template.KOSTENSTELLE) {
                this.countCentre = template.KOSTENSTELLE;
            }
            if (template.BEMERKUNG) {
                this.comment = template.BEMERKUNG;
            }
            if (template.MWSTART) {
                this.taxRate = this.taxRates.find(value1 => template.MWSTART === value1.ART) || null;
            }
        }
    }

    private divisionChanged(style: DivisionStyle) {
        const divisions: CashbookDivision[] = style === DivisionStyle.Payment ? this.divisionsPayment : this.divisionsTax;
        // amount of current that is not set to a division (excluding the last division)
        let remaining = this.current;
        // iterate over all divisions but the last one
        for (let i = 0; i < divisions.length - 1; i += 1) {
            const division = divisions[i];
            const available = this.getAvailableCurrentForDivision(division);
            if (division.current > available) {
                division.current = available;
            }
            remaining -= division.current;
            division.maxValue = available;
        }
        // set the current of the last division to the remaining current
        divisions[divisions.length - 1].current = remaining;
    }

    /**
     * Get the total available current for the division
     */
    private getAvailableCurrentForDivision(division: CashbookDivision) {
        const divisions: CashbookDivision[] = division.style === DivisionStyle.Payment ? this.divisionsPayment : this.divisionsTax;
        const available = divisions
            // filter out current and last division
            .filter((value) => value.uuID !== division.uuID && !this.isLastDivision(value))
            // subtract the currents of all remaining from the cashbook current
            .reduce((available, value) => available - value.current, this.current);
        return Math.max(available, 0);
    }

    private getMaxAvailableForLoadedData(index: number, style: DivisionStyle): number {
        if (!this.details) {
            return 0;
        }
        let available: number = this.details.Belegkopf.BRUTTO;
        if (style == DivisionStyle.Payment) {
            for (let i = 0; i < (this.details.Lst_Zahlungen.length - 1); i += 1) {
                if (i !== index) {
                    available -= this.details.Lst_Zahlungen[i].BRUTTOBETRAG;
                }
            }
            return available;
        }
        if (style == DivisionStyle.Tax) {
            for (let i = 0; i < (this.details.Lst_Steuern.length - 1); i += 1) {
                if (i !== index) {
                    available -= this.details.Lst_Steuern[i].BRUTTOBETRAG;
                }
            }
            return available;
        }
        return 0;
    }

    private safeToCancel() {
        this.showSafetyDialog = false;
        this.clearAndHide(true);
    }

    private addDivision(style: DivisionStyle) {
        const divisions = style === DivisionStyle.Payment ? this.divisionsPayment : this.divisionsTax;
        // array.unshift(val) adds the cashbook division at the first index, if the array is empty sets the index 0
        divisions.unshift(new CashbookDivision(0, 0, undefined, undefined, style));
        // we want at least 2 divisions, so add another one
        if (divisions.length === 1) {
            divisions.unshift(new CashbookDivision(0, 0, undefined, undefined, style));
        }
    }

    private removeDivision(val: CashbookDivision) {
        if (val.style === DivisionStyle.Payment) {
            this.divisionsPayment = this.divisionsPayment.filter(value => value.uuID !== val.uuID);
            // we need at least 2 divisions, so if we only have one left, remove that one as well
            if (this.divisionsPayment.length === 1) {
                this.divisionsPayment = [];
            }
            this.divisionChanged(DivisionStyle.Payment);
        } else if (val.style == DivisionStyle.Tax) {
            this.divisionsTax = this.divisionsTax.filter(value => value.uuID !== val.uuID);
            // we need at least 2 divisions, so if we only have one left, remove that one as well
            if (this.divisionsTax.length === 1) {
                this.divisionsTax = [];
            }
            this.divisionChanged(DivisionStyle.Tax);
        }
    }

    private clearAndHide(clearAll: boolean): void {
        this.$store.commit('SET_CASHBOOK_ENTRY', null);
        this.selectedVorfall = '';
        this.selectedTemplate = null;
        // 19% hard coded? shouldnt this better just take the first taxrate of the array?
        this.taxRate = this.taxRates.find(value => value.STEUERSATZ === 19) || null;
        this.divisionsTax = [];
        this.divisionsPayment = [];
        this.current = 0;
        this.attachment = null;
        this.comment = '';
        this.account = 0;
        this.file = null;
        this.reason = '';
        this.countCentre = 0;
        this.countProtocols = [];
        this.selectedPayment = null;
        this.errors.clearAllErrors();
        if (clearAll) {
            this.selectedDateStored = null;
        }

        this.loading = true;
        this.$store.commit('SET_SHOW_TRANSACTION_DIALOG', false);
    }

    private onBackButton(evt: Event) {
        this.closeDialog();
        evt.preventDefault();
    }

    private closeDialog(): void {
        if (this.editable && this.isDirty()) {
            this.showSafetyDialog = true;
        } else {
            this.clearAndHide(true);
        }
    }loa

    private isLastDivision(division: CashbookDivision): boolean {
        const divisions = division.style === DivisionStyle.Payment ? this.divisionsPayment : this.divisionsTax;
        const lastDivision = divisions[divisions.length - 1];
        return lastDivision.uuID === division.uuID;
    }

    private getTaxesFromDivisions(): Taxes[] {
        if (!this.taxInputNeeded) {
            return [new Taxes(0, 0, 0, this.current)];
        }
        if (this.divisionsTax.length > 0) {
            return this.divisionsTax.map((division) => new Taxes(division.taxRate?.ART, division.taxRate?.STEUERSATZ, division.tax, division.current));
        }
        if (this.taxRate) {
            const tax = calcTaxByCurrentAndRate(this.current, this.taxRate.STEUERSATZ);
            return [new Taxes(this.taxRate.ART, this.taxRate.STEUERSATZ, tax, this.current)]
        }
        return [];
    }

    private getPaymentsFromDivisions(): Payment[] {
        if (this.divisionsPayment.length > 0) {
            return this.divisionsPayment.map((division) => new Payment(division.current, division.payment?.NUMMER));
        }
        if (this.selectedPayment) {
            return [new Payment(this.current, this.selectedPayment.NUMMER)];
        }
        return [];
    }

    private save() {
        this.loading = true;
        this.errors.paymentDivisionCurrentError = '';
        this.errors.paymentDivisionPaymentError = '';

        if (this.mode === DialogMode.CREATE) {
            this.saveCreate();
        } else if (this.mode === DialogMode.EDIT) {
            this.saveEdit();
        } else if (this.mode === DialogMode.DELETE) {
            this.saveDelete();
        } else {
            this.loading = false;
            this.clearAndHide(true);
        }
    }

    private saveCreate() {
        const payments = this.getPaymentsFromDivisions();
        const taxes = this.getTaxesFromDivisions();

        let hasError = false;
        if (this.selectedVorfall === null || this.selectedVorfall.length === 0) {
            this.errors.businessCaseError = 'Dialog.Errors.EnterBusinessCase';
            hasError = true;
        }
        if (!this.selectedPayment && this.divisionsPayment.length === 0) {
            this.errors.paymentError = 'Dialog.Errors.NoPayment';
            hasError = true;
        }
        if (this.isRebook && !hasError) {
            if (!this.showReasonDialog) {
                this.showReasonDialog = true;
                hasError = true;
            } else if (this.reason.length < 1) {
                this.errors.reasonError = 'Dialog.Errors.EnterReason';
                hasError = true;
            }
        }
        if (hasError) {
            this.loading = false;
            return;
        }

        const head: DocumentHeader = new DocumentHeader();
        head.BRUTTO = this.current;
        head.KOSTENSTELLE = this.countCentre;
        head.BUCHUNGSTEXT = this.selectedVorfall;
        head.KASSENBUCH = this.$store.getters.selectedCashbook.NUM;
        head.DATUM = moment(this.selectedDate).format(REQUEST_DATEFORMAT);
        head.UHRZEIT = moment(this.selectedDate).format(REQUEST_TIMEFORMAT);
        head.BEMERKUNG = this.comment;
        if (this.type) {
            head.TYP = this.type.NUM;
        }
        head.KONTO = this.account;

        Webservice.insertCashbookEntry(head, payments, taxes,
            (value) => {
                if (this.file) {
                    const imgUploadRequest: CashbookImageRequest = {
                        cashbookId: this.$store.getters.selectedCashbook.NUM,
                        entryId: value.LFDNUMMER,
                        date: value.DATUM,
                        file: this.file,
                    };
                    Webservice.uploadCashbookEntryImage(imgUploadRequest)
                        .then(() => {
                            this.clearAndHide(false);
                            Webservice.reloadCashbookEntries();
                        })
                        .catch((errors: ServiceErrors[]) => {
                            this.showReasonDialog = false;
                            this.errors.handleError(errors);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    Webservice.reloadCashbookEntries();
                    this.clearAndHide(false);
                }
            },
            (errors: ServiceErrors[]) => {
                this.showReasonDialog = false;
                this.loading = false;
                if (errors[0].ERRORRECORDID == 'Grund') {
                    this.showReasonDialog = true;
                } else {
                    this.errors.handleError(errors);
                }
            },
            () => {
                if (this.file === null) {
                    this.loading = false;
                }
            },
            this.reason || undefined);
    }

    private async saveEdit() {
        this.loading = true;
        this.errors.paymentDivisionCurrentError = '';
        this.errors.paymentDivisionPaymentError = '';
        this.errors.reasonError = '';

        // user has no permission to edit the cashbook, just close dialog
        if (!PermissionUtil.hasPermissionToEditCashbookEntry()) {
            this.loading = false;
            this.clearAndHide(true);
            return;
        }

        // cashbook entry has not been changed, just close dialog
        if (!this.isDirty() && this.file === null) {
            this.loading = false;
            this.clearAndHide(true);
            return;
        }

        // user has to enter a reason, show reason dialog
        if (!this.showReasonDialog) {
            this.loading = false;
            this.showReasonDialog = true;
            return;
        }
        // entered reason is not valid
        if (this.reason.length < 1) {
            this.loading = false;
            this.errors.reasonError = 'Dialog.Errors.EnterReason';
            return;
        }

        // save cashbook entry
        this.$store.commit('SET_LOADING_ENTRY', this.cashbookEntry);

        // update cashbook entry
        if (this.isDirty()) {
            const head: DocumentHeader = new DocumentHeader();
            head.BRUTTO = this.current;
            head.BUCHUNGSTEXT = this.selectedVorfall;
            head.KASSENBUCH = this.$store.getters.selectedCashbook.NUM;
            head.BEMERKUNG = this.comment;
            head.KOSTENSTELLE = this.countCentre;
            head.KONTO = this.account;
            if (this.cashbookEntry) {
                head.LFDNUMMER = this.cashbookEntry.LFDNUMMER;
                head.DATUM = this.cashbookEntry.DATUM;
                head.UHRZEIT = this.cashbookEntry.UHRZEIT;
                head.BUCHUNGART = this.cashbookEntry.BUCHUNGART;
                head.PERSISTENZ = this.cashbookEntry.PERSISTENZ;
                head.REFERENZAUFDATUM = this.cashbookEntry.REFERENZAUFDATUM;
                head.REFERENZAUFLFDNUMMER = this.cashbookEntry.REFERENZAUFLFDNUMMER;
                head.FREMDNUMMER = this.cashbookEntry.FREMDNUMMER;
                head.BEDIENERNAME = this.cashbookEntry.BEDIENERNAME;
            }
            if (this.type) {
                head.TYP = this.type.NUM;
            }

            const payments = this.getPaymentsFromDivisions();
            const taxes = this.getTaxesFromDivisions();

            Webservice.changeCashbookEntry(head, this.reason, payments, taxes,
                () => {
                    if (this.file) {
                        this.uploadFile();
                    } else {
                        Webservice.reloadCashbookEntries();
                        this.clearAndHide(true);
                    }
                },
                (errors: ServiceErrors[]) => {
                    //console.log(errors);
                    this.showReasonDialog = false;
                    this.errors.handleError(errors);
                    this.loading = false;
                    this.$store.commit('SET_LOADING_ENTRY', null);
                    // these two cause the errors beeing ignored and the dialog closing without noticing the user something went wrong
                    // Webservice.reloadCashbookEntries();
                    // this.clearAndHide(true);
                },
                () => {
                    if (!this.file) {
                        this.loading = false;
                    }
                },
            );
        } else if (this.file) {
            this.uploadFile();
        }
    }

    private uploadFile() {
        this.loading = true;
        const imgUploadRequest: CashbookImageRequest = {
            cashbookId: this.$store.getters.selectedCashbook.NUM,
            entryId: this.cashbookEntry.LFDNUMMER,
            date: this.cashbookEntry.DATUM,
            file: this.file!,
        };
        Webservice.uploadCashbookEntryImage(imgUploadRequest)
            .then(() => {
                this.clearAndHide(false);
                Webservice.reloadCashbookEntries();
            })
            .catch((errors: ServiceErrors[]) => {
                this.showReasonDialog = false;
                this.errors.handleError(errors);
            })
            .finally(() => {
                this.showReasonDialog = false;
                this.loading = false;
            });
    }

    private saveDelete() {
        if ((PermissionUtil.hasPermissionToDeleteCashbookEntry()
            && this.cashbookEntry.PERSISTENZ === 1) || (PermissionUtil.hasPermissionToCancelCashbookEntry()
            && this.cashbookEntry.PERSISTENZ === 2)) {
            if (!this.showReasonDialog) {
                this.loading = false;
                this.showReasonDialog = true;
            } else if (this.reason.length < 1) {
                this.loading = false;
                this.errors.reasonError = 'Dialog.Errors.EnterReason';
            } else {
                this.errors.reasonError = '';
                if (PermissionUtil.hasPermissionToDeleteCashbookEntry()
                    && this.cashbookEntry.PERSISTENZ === 1) {
                    Webservice.deleteCashbookEntry(
                        this.cashbookEntry,
                        this.reason,
                        () => {
                            this.clearAndHide(true);
                            Webservice.reloadCashbookEntries();
                        },
                        (errors: ServiceErrors[]) => this.errors.handleError(errors),
                        () => {
                            this.loading = false;
                        },
                    );
                } else if (PermissionUtil.hasPermissionToCancelCashbookEntry()
                    && this.cashbookEntry.PERSISTENZ === 2) {
                    Webservice.cancelCashbookEntry(
                        this.cashbookEntry,
                        this.reason,
                        () => {
                            this.clearAndHide(true);
                            Webservice.reloadCashbookEntries();
                        },
                        (errors: ServiceErrors[]) => {
                            this.showReasonDialog = false;
                            this.errors.handleError(errors);
                        },
                        () => {
                            this.loading = false;
                        },
                    );
                }
            }
        }
    }

    private safeTemplate() {
        this.loading = true;
        try {
            if (this.selectedTemplate) {
                if (PermissionUtil.hasPermissionToEditTemplates()) {
                    const template: CashbookEntryTemplate = new CashbookEntryTemplate(this.selectedTemplate);
                    template.BUCHUNGSTEXT = this.selectedVorfall;
                    template.KOSTENSTELLE = this.countCentre;
                    template.BEMERKUNG = this.comment;
                    template.KONTO = this.account;
                    template.BRUTTO = this.current;
                    if (this.type) {
                        template.TYP = this.type.NUM;
                    }
                    if (this.selectedPayment) {
                        template.ZAHLUNGSMITTEL = this.selectedPayment.NUMMER;
                    }
                    if (this.taxRate) {
                        template.MWSTART = this.taxRate.ART;
                    }
                    Webservice.changeCashbookEntryTemplate(template, this.updateTemplate, () => {
                        this.loading = false;
                    });
                }
            } else {
                if (PermissionUtil.hasPermissionToCreateTemplates()) {
                    const template: CashbookEntryTemplate = new CashbookEntryTemplate();
                    template.BUCHUNGSTEXT = this.selectedVorfall;
                    template.KOSTENSTELLE = this.countCentre;
                    template.BEMERKUNG = this.comment;
                    template.KONTO = this.account;
                    template.BRUTTO = this.current;
                    if (this.type) {
                        template.TYP = this.type.NUM;
                    }
                    if (this.selectedPayment) {
                        template.ZAHLUNGSMITTEL = this.selectedPayment.NUMMER;
                    }
                    if (this.taxRate) {
                        template.MWSTART = this.taxRate.ART;
                    }
                    Webservice.insertCashbookEntryTemplate(template, this.updateTemplate, () => {
                        this.loading = false;
                    });
                }
            }
        } catch (e) {
            this.loading = false;
        }
    }

    private updateTemplate(returnVal: CashbookEntryTemplate) {
        [this.selectedTemplate] = this.$store.getters.availableCashbookEntryTemplates.filter((val) => val.NUMMER === returnVal.NUMMER);
    }

    private closeMenus() {
        this.closeMenusOfChildren(this);
    }

    /**
     * Recursively searches subcomponents for a component with active menu and closes this menu
     * @param component
     */
    private closeMenusOfChildren(component: Vue = this) {
        if ((component as any).isMenuActive === true) {
            (component as any).isMenuActive = false;
        } else if (component.$children) {
            component.$children.forEach((child) => this.closeMenusOfChildren(child));
        }
    }
}

interface ComboboxItem {
    text: string;
    value: number;
}
