
  import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";
  import { TableHeader } from "@/assets/config/TableHeaders";
  import CashbookTableFooter from "@/components/cashbook/table/cashbook/CashbookTableFooter.vue";
  import CashbookTableGroupRow
    from "@/components/cashbook/table/cashbook/CashbookTableGroupRow.vue";
  import CashbookTableRow from "@/components/cashbook/table/cashbook/CashbookTableRow.vue";
  import CashbookTableHeader from "@/components/cashbook/table/cashbook/CashbookTableHeader.vue";
  import DetailsTable from "@/components/cashbook/table/DetailsTable.vue";
  import ReportTableHeader from "@/components/cashbook/table/report/ReportTableHeader.vue";
  import ReportTableGroupRow from "@/components/cashbook/table/report/ReportTableGroupRow.vue";
  import ReportTableFooter from "@/components/cashbook/table/report/ReportTableFooter.vue";

  @Component({
    components: {
      CashbookTableFooter,
      CashbookTableGroupRow,
      CashbookTableRow,
      CashbookTableHeader,
      ReportTableFooter,
      ReportTableGroupRow,
      ReportTableHeader,
      DetailsTable,
    }
  })
  export default class DesktopMonthPicker<T> extends Vue {
    @Prop({ required: true }) changeMonthFunc!: () => void;

    @Prop({ required: true }) headers!: TableHeader[];

    @PropSync('topTableEntries', { required: true }) topTableEntriesStored!: T[];

    @PropSync('bottomTableEntries', { required: true }) bottomTableEntriesStored!: T[];

    @Prop({ required: true }) headerComponent!: string;

    @Prop({ required: true }) isEntryReloading!: (item: T) => boolean;

    @Prop({ required: true }) tableGroupRowComponent!: string;

    @Prop({ required: true }) detailsTableComponent!: string;

    @Prop({ required: true }) tableFooterComponent!: string;

    @Prop() setShowEarlier!: (val: boolean) => void;

    @PropSync("showEarlier", { type: Boolean }) showEarlierVal!: boolean;

    @Prop({ default: false })
    public readonly disableShowEarlier!: boolean;

    @Prop() containsFilterableEarlierCashbookDetailEntries!: boolean;

    @Prop() expandable!: boolean;

    @Prop() openReport!: (date: string, callBackFinally: () => void) => void;

    expandedTop = [];

    expandedBottom = [];

    sortBy: string | [] = '';

    sortDesc: boolean | [] = true;

    now: Date = new Date();

    months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'];

    get loadTableData(): boolean {
      return this.$store.getters.loadTableData;
    }

    set loadTableData(val: boolean) {
      this.$store.commit('SET_LOAD_TABLE_DATA', val);
    }

    get enlargeTopTable(): boolean {
      return this.showEarlierVal;
    }

    set enlargeTopTable(val: boolean) {
      this.setShowEarlier(val);
    }

    get activeCashbookInfo() {
      return this.$store.getters.activeCashbookDetails;
    }

    get selectedYear() {
      return Number(this.$store.getters.selectedYear);
    }

    get selectedMonth(): number {
      return this.$store.getters.selectedMonth;
    }

    set selectedMonth(month: number) {
      this.$store.commit('SET_SELECTED_MONTH', month);
    }

    get dateFirstEntry(): Date {
      const startDate = moment(this.activeCashbookInfo?.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT).toDate();
      startDate.setHours(0, 0, 0, 0);
      return startDate;
    }

    get isCurrentMonthSelected(): boolean {
      return this.now.getMonth() === this.selectedMonth && this.now.getFullYear() === this.selectedYear;
    }

    get containsUndone(): boolean {
      return this.bottomTableEntriesStored.length > 0;
    }

    disableTab(month: number): boolean {
      if (this.activeCashbookInfo) {
        if (this.activeCashbookInfo?.DATUMERSTEREINTRAG) {
          const date = new Date(this.selectedYear, month);
          const startDate = this.dateFirstEntry;
          startDate.setDate(1);
          return !((date >= startDate) && date <= this.now);
        }
        return false;
      }
      return true;
    }

    changeMonth() {
      if (!this.disableTab(this.selectedMonth)) {
        this.changeMonthFunc();
        this.expandedTop = [];
        this.expandedBottom = [];
        this.showEarlierVal = false;
      } else {
        this.selectedMonth = this.$store.getters.selectedMonth;
      }
    }
  }
