import _Vue, { PluginObject } from 'vue';
import { Store } from 'vuex';
import { AuthPort, AuthPortFactory } from '@/modules/auth';
import { CashbookPort, CashbookPortFactory } from '@/modules/cashbook';
import { BillingPort, BillingPortFactory } from '@/modules/billing';
import { AuthModuleAdapter } from '@/store/session/SessionModuleAdapter';

interface Ports {
    auth: AuthPort;
    cashbook: CashbookPort;
    billing: BillingPort;
}

const portsPlugin: PluginObject<any> = {
    install(vue: typeof _Vue, store: Store<any>): void {
        // Instantiate ports
        const authPort: AuthPort = AuthPortFactory.createAuthPort({
            appId: process.env.VUE_APP_APPID || '',
            loginServiceUrl: process.env.VUE_APP_LOGIN_URL || '',
            store: new AuthModuleAdapter(store),
        });

        const cashBookPort: CashbookPort = CashbookPortFactory.createCashbookPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
        });

        const billingPort: BillingPort = BillingPortFactory.createBillingPort({
            getServiceUrl: () => authPort.getServiceUrl(),
            getAuthToken: () => authPort.getAuthToken(),
            getUserId: () => authPort.getUserId(),
        });

        const ports: Ports = {
            auth: authPort,
            cashbook: cashBookPort,
            billing: billingPort,
        };

        vue.prototype.$ports = ports;
        vue.ports = ports;
    },
};

export default portsPlugin;

// add type definitions for typescript compiler
declare module 'vue/types/vue' {
    interface Vue {
        $ports: Ports;
    }

    interface VueConstructor {
        ports: Ports;
    }
}
