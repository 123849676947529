
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";

  @Component
  export default class Datepicker extends Vue {
    @Prop() changeMonth!: () => void;

    dateString: string = '';

    key: number = Math.random() * 10;

    get date() {
      if (this.dateString === '') {
        this.dateString = moment(new Date(Number(this.$store.getters.selectedYear),
          Number(this.$store.getters.selectedMonth),
          Number(this.$store.getters.selectedDay)))
          .toISOString(true).substr(0, 10);
      }
      return this.dateString;
    }

    set date(dateString: string) {
      this.dateString = dateString;
    }

    // eslint-disable-next-line class-methods-use-this
    get maxDate(): string {
      return new Date().toISOString();
    }

    get minDate(): string {
      return this.$store.getters.activeCashbookDetails ?
        moment(this.$store.getters.activeCashbookDetails.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT).toISOString(true)
        : moment(new Date()).toISOString(true);
    }

    get showDialog() {
      this.dateString = '';
      return this.$store.getters.showDatePickerDialog;
    }

    set showDialog(show: boolean) {
      this.$store.commit('SET_SHOW_DATEPICKER_DIALOG', show);
    }

    saveDate(dateString: string) {
      const date: Date = new Date(dateString);

      const monthChanged: boolean = date.getFullYear() !== this.$store.getters.selectedYear
        || date.getMonth() !== this.$store.getters.selectedMonth;

      this.$store.commit('SET_SELECTED_YEAR', date.getFullYear());
      this.$store.commit('SET_SELECTED_MONTH', date.getMonth());
      this.$store.commit('SET_SELECTED_DAY', date.getDate());
      this.showDialog = false;

      if (monthChanged) {
        this.changeMonth();
      }
    }

    jumpToToday() {
      this.key = Math.random() * 10;
      const now = new Date();
      this.dateString = now.toISOString().substr(0, 10);
    }
  }
