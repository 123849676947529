
import { Component } from 'vue-property-decorator';
import StepGeneric from "@/components/general/genericComponents/checkOut/steps/StepGeneric";
import DrawerList from "@/components/general/genericComponents/checkOut/DrawerList.vue";
import CashCountDialogWatcher from '@/components/general/genericComponents/checkOut/steps/CashCountDialogWatcher.vue';

@Component({
    components: {CashCountDialogWatcher, DrawerList }
})
export default class Step4 extends StepGeneric {
    processable: boolean = false;

    progress: number = 0;

    cancelPollingTask: boolean = false;

    stateMessage: string = '';

    get needCounting(): boolean {
        return this.$store.getters.cashbookBillingState.KassenberichtStatus === 6
            && this.$store.getters.dayEndClosingConfig.ZAEHLPROT === 0
            && this.$store.getters.dayEndClosingConfig.ZAEHLABLIEF === 0
            && this.$store.getters.dayEndClosingConfig.ZAEHLBESTAND === 0;
    }

    get stepComputed(): number {
        return 1;
    }

    finishStep() {
        this.reload();
        this.next();
    }

    visible(visible: boolean) {
        this.cancelPollingTask = !visible;

        if (visible) {
            this.awaitProcessable();
        }
    }

    beforeDestroy() {
        this.cancelPollingTask = true;
    }

    mounted() {
        if (this.needCounting) {
            this.reloadDrawers();
        }
    }

    private async awaitProcessable() {
        this.cancelPollingTask = false;
        this.processable = false;
        while (!this.processable && !this.cancelPollingTask) {
            await this.updateProcessable();
            await this.updateProgress();
            // there was an backend error, cancel polling
            if (this.$store.getters.cashbookBillingState.KassenberichtStatus === 5) {
                this.cancelPollingTask = true;
            }
            // wait 1 second before starting the next request
            await new Promise((resolve) => setTimeout(resolve, 2000));
        }
        await this.updateProcessable();
    }

    private async updateProcessable() {
        const cashbook = this.$store.getters.selectedCashbook.NUM;
        this.processable = await this.$ports.billing.isCashbookBillingProcessable(cashbook);
    }

    private async updateProgress() {
        const cashbook = this.$store.getters.selectedCashbook.NUM;
        const progress = await this.$ports.billing.getRegisterProgress(cashbook);
        this.progress = progress.PercentageDone;
        this.stateMessage = progress.StatusMessage;
        if (progress.Warning && progress.PercentageDone === 100) {
            this.cancelPollingTask = true;
            this.reload();
            this.$emit("OnShowWarning", progress.Warning);
        }
        if (this.progress === 0) {
            this.reload();
        }
    }

    private async process() {
        this.setLoading(true);
        this.reload();
        try {
            const cashbook = this.$store.getters.selectedCashbook.NUM;
            await this.$ports.billing.processCashbookBilling(cashbook);
            this.next();
        } catch (e) {
            // do nothing as error is already logged in service
        } finally {
            this.setLoading(false);
            this.reload();
        }
    }

    private async skip() {
        this.setLoading(true);
        this.reload();
        const cashbook = this.$store.getters.selectedCashbook.NUM;
        await this.$ports.billing.skipCashbookBillingRegister(cashbook);
        this.reload();
        this.setLoading(false);
    }
}
