import ServiceErrors from "@/assets/service/responseTypes/ServiceErrors";
import store from '@/store/store';

export class TransactionDialogErrors {
  reasonError: string = '';
  businessCaseError: string = '';
  currentError: string = '';
  paymentError: string = '';
  paymentDivisionPaymentError: string = '';
  paymentDivisionCurrentError: string = '';
  taxDivisionCurrentError: string = '';
  typeError: string = '';

  public clearAllErrors(): void {
    this.businessCaseError = '';
    this.reasonError = '';
    this.paymentError = '';
    this.currentError = '';
    this.paymentDivisionPaymentError = '';
    this.paymentDivisionCurrentError = '';
    this.taxDivisionCurrentError = '';
    this.typeError = '';
  }

  public handleError(reason: ServiceErrors[]) {
    reason.forEach(value => {
      value.ERRORRECORDID.split('; ').forEach((id: string) => {
        switch (id) {
          case 'Belegkopf.Brutto':
            this.currentError = value.ERRORRECOMMENDATION;
            break;
          case 'Belegkopf.Typ':
            this.typeError = value.ERRORRECOMMENDATION;
          case 'Lst_Zahlungen.Zahlungsmittel':
            this.paymentDivisionPaymentError = value.ERRORRECOMMENDATION;
            break;
          case 'Lst_Zahlungen.Bruttobetrag':
            this.paymentDivisionCurrentError = value.ERRORRECOMMENDATION;
            break;
          case 'Lst_Steuern.Bruttobetrag':
            this.taxDivisionCurrentError = value.ERRORRECOMMENDATION;
          default:
            store.commit('SET_ERROR', value);
        }
      });
    })
  }
}

export function get_tex_width(txt, font): number {
  const element = document.createElement('canvas');
  const context = element.getContext("2d");
  if (context) {
    context.font = font;
    return Math.round(context.measureText(txt).width * 2) / 2;
  }
  return 0;
}
