
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class DayEndClosingDialog extends Vue {

    private open: boolean = false;
    private processing: boolean = false;

    private get dialogTitle() : string {
      return this.$t('Dialog.Billing.Title') + ' - ' + this.$store.getters?.activeCashbookDetails?.BEZEICHNUNG
    }

    private async start() {
        if (!this.processing) {
            this.processing = true;
            const cashbook = this.$store.getters.selectedCashbook.NUM;
            const startable = await this.$ports.billing.isCashbookBillingStartable(cashbook);
            if (startable) {
                try {
                    await this.$ports.billing.startCashbookBilling(cashbook);
                    await this.$router.push('/cashbook/day-closing');
                } catch (e) {
                    this.processing = false;
                    this.open = false;
                }
            }
            this.processing = false;
        }
    }
}
