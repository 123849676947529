const state = {
  cashbookCashCountTypes: JSON.parse(window.localStorage.getItem('cashCountTypes') || '[]'),
  cashbookCashCountStates: JSON.parse(window.localStorage.getItem('cashCountStates') || '[]'),
  cashbookPaymentDivisions: JSON.parse(window.localStorage.getItem('paymentDivisions') || '{}'),
};

const getters = {
  cashbookCashCountTypes: initState => initState.cashbookCashCountTypes,
  cashbookCashCountStates: initState => initState.cashbookCashCountStates,
  cashbookPaymentDivisions: initState => initState.cashbookPaymentDivisions,
};

const mutations = {
  SET_CASH_COUNT_TYPES(initState, param) {
    initState.cashbookCashCountType = param;
    if (param !== undefined) {
      window.localStorage.setItem('cashCountType', JSON.stringify(param));
    }
  },
  SET_CASH_COUNT_STATES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookCashCountStates = params;
    if (params !== undefined) {
      window.localStorage.setItem('cashCountStates', JSON.stringify(params));
    }
  },
  SET_PAYMENT_DIVISIONS(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookPaymentDivisions = params;
    if (params !== undefined) {
      window.localStorage.setItem('paymentDivisions', JSON.stringify(params));
    }
  }
};

export default {
  state,
  getters,
  mutations,
};
