
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import ClientAbout from "@/components/about/ClientAbout.vue";
  import ServiceAbout from "@/components/about/ServiceAbout.vue";
  import DesktopHeader from "@/components/general/DesktopHeader.vue";

  @Component({
    components: {ServiceAbout, ClientAbout, DesktopHeader}
  })
  export default class AboutDesktop extends Vue {
    private tab = null;
  }
