import { CashbookService } from './services/CashbookService';
import type { CashbookEntryAttachment, CashbookEntryId } from './models';

export class CashbookPort {

    private readonly cashbookService: CashbookService;

    constructor(params: { cashbookService: CashbookService }) {
        this.cashbookService = params.cashbookService;
    }

    /**
     * Get the attachment for a cashbook entry
     * @param cashbooEntrykId
     */
    public getCashbookEntryAttachment(cashbooEntrykId: CashbookEntryId): Promise<CashbookEntryAttachment|undefined> {
        return this.cashbookService.getCashbookEntryAttachment(cashbooEntrykId);
    }
}
