
import { Component, Vue } from 'vue-property-decorator';
import ErrorConstants from "@/assets/constants/ErrorConstants";
import ServiceErrors from "@/assets/service/responseTypes/ServiceErrors";

@Component
export default class GenericErrorHandler extends Vue {

    private get openDialog(): boolean {
        return this.$store.getters.error !== '';
    }

    private get errorMessage() {
        if ((this.$store.getters.error as ServiceErrors).ERRORRECOMMENDATION) {
            return (this.$store.getters.error as ServiceErrors).ERRORRECOMMENDATION;
        } else {
            return this.$t(ErrorConstants.getDisplayStringForError(this.$store.getters.error));
        }
    }

    private get errorTitle() {
        if ((this.$store.getters.error as ServiceErrors).ERRORDESCRIPTION) {
            return (this.$store.getters.error as ServiceErrors).ERRORDESCRIPTION;
        } else {
            return this.$t(ErrorConstants.getDisplayTitleForError(this.$store.getters.error));
        }
    }

    private async closeDialog() {
        if (this.$store.getters.webserviceMethod) {
            await this.$store.getters.webserviceMethod();
            this.$store.commit('SET_ERROR_WEBSERVICE_METHOD', null);
        }
        this.$store.commit('SET_ERROR', '');
    }
}
