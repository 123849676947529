
import {Component, Vue} from "vue-property-decorator";

@Component({components: {}})
export default class End extends Vue {
    async mounted() {
        this.$store.commit("setIsInjected", false);
    }

    private logout(): void {
        this.$ports.auth.logout();
    }

}