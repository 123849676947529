
import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator';
  import moment from 'moment-mini';
  import CashbookEntriesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
  import { REQUEST_DATEFORMAT } from '@/assets/utils/DateUtil';
  import DialogMode from '@/components/cashbook/dialogs/transactionDialog/DialogMode';
  import TransactionDialog
    from '@/components/cashbook/dialogs/transactionDialog/TransactionDialog.vue';
  import isIngestion from '@/assets/utils/CashbookUtils';
  import Lightbox from '@/components/cashbook/dialogs/lightbox/Lightbox.vue';
  import PermissionUtil from "@/assets/utils/PermissionUtil";
  import Webservice from '@/assets/service/Webservice';
  import { CashbookEntryAttachment } from '@/modules/cashbook';
  import Templates from '@/modules/templates/adapter/vue/Templates.vue';

  @Component({ components: {Templates, TransactionDialog, Lightbox } })
  export default class CashbookTableGroupRow extends Vue {
    @Model()
    public readonly item!: CashbookEntriesResponseEntry;

    @Prop()
    public readonly expand!: (v: boolean) => void;

    @Prop()
    public readonly openReport!: (date: string, callBackFinally: () => void) => void;

    @Ref('lightbox')
    private readonly lightbox!: Lightbox|undefined;

    expandedStored: boolean = false;

    dialogMode = DialogMode;

    private attachment: CashbookEntryAttachment | null = null;


    loadReport: boolean = false;
    private get bruttoDisplay() : number {
        if(!this.ingestion)
        {
            return this.item.BRUTTO * -1;
        }
        return this.item.BRUTTO;
    }
    private get userCanLoadReport() : boolean {
      return PermissionUtil.hasPermissionForCashCheck();
    }

    get expanded() {
      return this.expandedStored;
    }

    set expanded(val: boolean) {
      this.expand(val);
      this.expandedStored = val;
    }

    get payment(): string {
      const paymentMethods = [
        this.item.ZAHLUNGSMITTEL_TEXT,
        this.item.ZAHLUNGSMITTEL_2_TEXT,
        this.item.ZAHLUNGSMITTEL_3_TEXT,
      ];
      return paymentMethods
       .filter((paymentMethod) => paymentMethod && paymentMethod.trim() !== '')
       .join('/');
    }

    get ingestion() {
      const [type] = this.$store.getters.cashbookEntryTypes
        .filter(value => value.NUM === this.item.TYP);
      return isIngestion(type);
    }

    get combinedTaxRate(): string {
      const taxRates = [
        this.item.MWSTSATZ_TEXT,
        this.item.MWSTSATZ_2_TEXT,
        this.item.MWSTSATZ_3_TEXT,
      ];
      return taxRates
        .filter((taxRate) => taxRate && taxRate.trim() !== '')
        .join('/');
    }

    get editable() {
      return (this.item.PERSISTENZ === 1
        && PermissionUtil.hasPermissionToEditCashbookEntry());
    }

    get deletable() {
      const alreadyClosed: boolean = moment(this.$store.getters.cashbookBillingDate, REQUEST_DATEFORMAT)
        .isSameOrAfter(this.date, 'day');
      return this.item.BUCHUNGART !== 3 && (((this.item.PERSISTENZ === 1
        && (PermissionUtil.hasPermissionToDeleteCashbookEntry() && !alreadyClosed)))
        || ((this.item.PERSISTENZ === 2 || this.item.PERSISTENZ === 1) && ((PermissionUtil.hasPermissionToCancelCashbookEntry()
          || PermissionUtil.hasPermissionToEditAfterDayClosing()) && alreadyClosed)));
    }

    get date() {
      return moment(this.item.DATUM, REQUEST_DATEFORMAT).toDate();
    }

    get isExpandable() {
        return this.item.MWSTSATZ_2 !== 0
            || this.item.MWSTSATZ_2_TEXT !== ''
            || this.item.MWSTSATZ_3 !== 0
            || this.item.MWSTSATZ_3_TEXT !== ''
            || this.item.ZAHLUNGSMITTEL_2 !== 0
            || this.item.ZAHLUNGSMITTEL_2_TEXT !== ''
            || this.item.ZAHLUNGSMITTEL_3 !== 0
            || this.item.ZAHLUNGSMITTEL_3_TEXT !== '';
    }

    get containsProtocol(): boolean {
      return this.item.HAT_ABRECHNUNG === 1;
    }

    get calendarIcon(): string {
      return require('@/assets/icons_exclamation_calendar.svg');
    }

    toggleLoadReport() {
      this.loadReport = !this.loadReport;
    }

    getPaymentMethod(id: number) {
      return this.$store.getters.availableCashbookPayments.filter(value => value.NUMMER === id)[0];
    }

    private async showAttachment() {
      if (this.attachment === null) {
        const attachment = await this.$ports.cashbook.getCashbookEntryAttachment({
          cashbookId: this.$store.getters.selectedCashbook.NUM,
          entryId: this.item.LFDNUMMER,
          date: this.item.DATUM
        });
        this.attachment = attachment || null;
      }
      if (this.attachment?.isImage) {
        this.$nextTick(() => {
          if (this.lightbox) {
            this.lightbox.show();
          } else {
            console.log('error');
          }
        });
      } else if (this.attachment) {
        const link = document.createElement('a');
        link.href = this.attachment.url;
        link.download = 'Beleg';
        link.click();
      }
    }

    openTransactionDialog(mode: DialogMode) {
      this.$store.commit('SET_INGESTION', this.ingestion);
      this.$store.commit('SET_TRANSACTION_DIALOG_MODE', mode);
      this.$store.commit('SET_CASHBOOK_ENTRY', this.item);
      this.$store.commit('SET_SHOW_TRANSACTION_DIALOG', true);
    }
  }
