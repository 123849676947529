export default class Payment {
  ZAHLUNGSMITTEL!: number;

  BRUTTOBETRAG!: number;

  constructor(BRUTTOBETRAG?: number, ZAHLUNGSMITTEL?: number) {
    if (ZAHLUNGSMITTEL !== undefined) {
      this.ZAHLUNGSMITTEL = ZAHLUNGSMITTEL;
    }
    if (BRUTTOBETRAG !== undefined) {
      this.BRUTTOBETRAG = BRUTTOBETRAG;
    }
  }
}
