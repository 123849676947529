import { BillingService } from './services/BillingService';
import type { CountProtocol, Stock, BillingStatus, RegisterProgress} from './models';

export class BillingPort {
    private readonly billingService: BillingService;

    constructor(params: { billingService: BillingService }) {
        this.billingService = params.billingService;
    }

    public async getCashbookBillingState(cashbookId: number): Promise<BillingStatus> {
        return this.billingService.getStatus(cashbookId);
    }

    public async isCashbookBillingStartable(cashbookId: number): Promise<boolean> {
        return this.billingService.isStartable(cashbookId);
    }

    public async startCashbookBilling(cashbookId: number): Promise<void> {
        return this.billingService.start(cashbookId);
    }

    public async isCashbookBillingProcessable(cashbookId: number): Promise<boolean> {
        return this.billingService.isProcessable(cashbookId);
        // TODO catch in BillingService returns false?
    }

    public async processCashbookBilling(cashbookId: number): Promise<void> {
        return this.billingService.process(cashbookId);
    }

    public async isCashbookBillingCommittable(cashbookId: number): Promise<boolean> {
        return this.billingService.isCommittable(cashbookId)
    }

    public async commitCashbookBilling(cashbookId: number, comment: string): Promise<void>  {
        return this.billingService.commit(cashbookId, comment);
    }

    public async rollbackCashbookBilling(cashbookId: number): Promise<void> {
        return this.billingService.rollback(cashbookId);
    }

    public async skipCashbookBillingRegister(cashbookId: number): Promise<void> {
        return this.billingService.skipRegister(cashbookId);
    }

    public async getRegisterProgress(cashbookId: number): Promise<RegisterProgress> {
        return this.billingService.getRegisterProgress(cashbookId);
    }

    public async upsertCashCountProtocol(protocol: CountProtocol, stock: Stock[]): Promise<void> {
        return this.billingService.upsertCashCountProtocol(protocol, stock);
    }
}
