
  import { Component,  Vue } from 'vue-property-decorator';
  import PackageInfo from "@/store/modules/clientAbout";

  @Component
  export default class ClientAbout extends Vue {

    packageInfo: PackageInfo = PackageInfo.getInstance();

    getText(obj: any): string {
      return JSON.stringify(obj)
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/,/g, "\n");
    }
  }
