
  import { Component, Vue } from 'vue-property-decorator';
  import AdminElement from "@/components/admin/AdminElement.vue";
  import PermissionUtil from "@/assets/utils/PermissionUtil";
  @Component({
    components: {
      AdminElement
    },
  })
  export default class AdminMobile extends Vue {
    private get showCashbookEntryLink():boolean{
      return PermissionUtil.hasPermissionToCreateTemplates() ||
        PermissionUtil.hasPermissionToEditTemplates() ||
        PermissionUtil.hasPermissionToDeleteTemplates();

    }
  }
