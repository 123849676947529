import { BillingRepository } from '../adapter/BillingRepository';
import type { CountProtocol, Stock, BillingStatus, RegisterProgress} from '../models';

export class BillingService {

    private readonly billingRepository: BillingRepository;

    constructor(params: { billingRepository: BillingRepository }) {
        this.billingRepository = params.billingRepository;
    }

    public getStatus(cashbookId: number): Promise<BillingStatus> {
        return this.billingRepository.getStatus(cashbookId);
    }

    public async isStartable(cashbookId: number): Promise<boolean> {
        return this.billingRepository.isStartable(cashbookId);
    }

    public async start(cashbookId: number): Promise<void> {
        return this.billingRepository.start(cashbookId);
    }

    public async isProcessable(cashbookId: number): Promise<boolean> {
        return this.billingRepository.isProcessable(cashbookId);
    }

    public async process(cashbookId: number): Promise<void> {
        return this.billingRepository.process(cashbookId);
    }

    public async isCommittable(cashbookId: number): Promise<boolean> {
        return this.billingRepository.isCommittable(cashbookId);
    }

    public async commit(cashbookId: number, comment: string): Promise<void> {
        return this.billingRepository.commit(cashbookId, comment);
    }

    public async rollback(cashbookId: number) {
        return this.billingRepository.rollback(cashbookId);
    }

    public async getRegisterProgress(cashbookId: number): Promise<RegisterProgress> {
        return this.billingRepository.getRegisterProgress(cashbookId);
    }

    public async skipRegister(cashbookId: number): Promise<void>{
        return this.billingRepository.skipRegister(cashbookId);
    }

    public async upsertCashCountProtocol(protocol: CountProtocol, stock: Stock[]) {
        return this.billingRepository.upsertCashCountProtocol(protocol, stock);
    }
}
