
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import {
    AproconTable,
    AproconFormDialog,
    FormConfig, FormsMappingProvider, TableConfig, FieldConfig,
} from '@aprocon-software/aprocon-form';
import DesktopHeader from '@/components/general/DesktopHeader.vue';
import type {CashbookTemplate} from '@/modules/templates';
import Webservice from "@/assets/service/Webservice";


@Component({
    components: {DesktopHeader, AproconTable, AproconFormDialog}
})
export default class Templates extends Vue {

    // @Ref('form')
    // private readonly form!: AproconFormDialog;

    @Ref('table')
    private readonly table!: AproconTable<CashbookTemplate>;
    private canCreate: boolean = true;
    private filter: string = '';
    private readonly transientFields: string[] = ['LOCAL_AKTUELLESKASSENBUCH', 'LOCAL_KOSTENSTELLEAUSFILIALE'];
    // private readonly formName: string = 'EDIT_KASSENBUCHEINTRAG_VORLAGEN';
    private readonly gridName: string = 'BROWSE_KASSENBUCHEINTRAG_VORLAGEN';

    private editRecord: CashbookTemplate | null = null;
    private formAction: 'Create' | 'View' | 'Update' | 'Delete' = 'Create'

    private mapper: FormsMappingProvider<CashbookTemplate> = {
        onInput: (params) => this.onInput(params),
        onInit: (params) => this.onInit(params),
        onDataChanged: (params) => this.onDataChanged(params),
        mapField: this.mapField
    }

    private mapField(field: FieldConfig, raw: any) :FieldConfig {

        console.log(raw);
        // if(field.fieldName === "BEMERKUNG") {
        //    console.log(raw);
        //     field.fieldType=()=>import("@/components/general/DateFormField.vue");
        //     field.inputType = ()=> import("@/components/general/DateFormInput.vue");
        //
        //     return field;
        // }
        return field;
    }
    // @Watch('editRecordId')
    // private onEditRecordChanged() {
    //     if (this.editRecordId) {
    //         this.form.open();
    //     }
    // }
    private async cashbookTemplatesDeleted(): Promise<void> {
        await this.reloadCashbookTemplates();
        this.filter = '';
    }

    private async reloadCashbookTemplates(): Promise<void> {
        //console.log("reloadCashbookTemplates");
        await Webservice.getCashbookInfo();
    }

    @Watch('currentCashbook')
    private onCashbookChanged() {
        this.table.reload(true);
    }

    private get context(): string {
        return `KassenbuchID=${this.currentCashbook}`;
    }

    private get currentCashbook(): number {
        return this.$store.getters.selectedCashbook.NUM;
    }

    private get editRecordId(): number | null {
        return this.editRecord?.NUMMER || null;
    }

    private reloadTable() {
        this.table.reload();
    }

    private async createTemplate() {
        this.editRecord = null;
        this.formAction = 'Create';
        await this.table.createItem();

    }

    // private editTemplate(template: CashbookTemplate) {
    //     this.editRecord = template;
    //     this.formAction = 'Update';
    //     this.form.open();
    // }

    // private viewTemplate(template: CashbookTemplate) {
    //     this.editRecord = template;
    //     this.formAction = 'View';
    //     this.form.open();
    // }

    // private deleteTemplate(template: CashbookTemplate) {
    //     this.table.deleteItem(template);
    // }

    private onInit({config, data}): CashbookTemplate {

        if (data.KOSTENSTELLE && data.KOSTENSTELLE.toString() === '9999') {
            data.LOCAL_KOSTENSTELLEAUSFILIALE = 1;
        } else {
            data.LOCAL_KOSTENSTELLEAUSFILIALE = 0;
        }
        if (data.KASSENBUCH === this.currentCashbook) {
            data.LOCAL_AKTUELLESKASSENBUCH = 1;
        } else {
            data.LOCAL_AKTUELLESKASSENBUCH = 0;
        }
        return data;
    }

    private onInput({config, data, changes}): Partial<any> {
        const changesOverride: Partial<any> = {};
        if (changes.KOSTENSTELLE !== undefined) {
            if (changes.KOSTENSTELLE.toString() === '9999') {
                changesOverride.LOCAL_KOSTENSTELLEAUSFILIALE = 1;
            } else {
                changesOverride.LOCAL_KOSTENSTELLEAUSFILIALE = 0;
            }
        }
        if (changes.LOCAL_KOSTENSTELLEAUSFILIALE !== undefined) {
            if (changes.LOCAL_KOSTENSTELLEAUSFILIALE) {
                changesOverride.KOSTENSTELLE = '9999';
            } else {
                changesOverride.KOSTENSTELLE = '0';
            }
        }
        if (changes.LOCAL_AKTUELLESKASSENBUCH !== undefined) {
            if (changes.LOCAL_AKTUELLESKASSENBUCH) {
                changesOverride.KASSENBUCH = this.currentCashbook;
            } else {
                changesOverride.KASSENBUCH = 0;
            }
        }
        return Object.assign(changes, changesOverride);
    }

    private onDataChanged({data, config}): FormConfig {

        if (data.LOCAL_KOSTENSTELLEAUSFILIALE) {
            const fieldIndex = config.fields.findIndex((f) => f.fieldName === 'KOSTENSTELLE');
            if (fieldIndex >= 0) {
                config.fields[fieldIndex].readonly = true;
            }
        }
        return config;
    }

    private overrideConfig(tableConfig: TableConfig): TableConfig {
        this.canCreate = tableConfig.permissions.create;
        return tableConfig;
    }
}

