
  import { Component, Model, Prop, Vue, } from 'vue-property-decorator';
  import Payment from '@/assets/service/parameterTypes/cashbook/payments/Payment';
  import Taxes from '@/assets/service/parameterTypes/cashbook/taxes/Taxes';

  @Component
  export default class CashbookTableRow extends Vue {
    @Model() item!: Payment | Taxes;

    @Prop() ingestion!: boolean;

    @Prop({ default: false }) deleted!: boolean;

    @Prop({ default: false }) isBilled!: boolean;
      private get bruttoDisplay() : number {
          if(!this.ingestion)
          {
              return this.item.BRUTTOBETRAG * -1;
          }
          return this.item.BRUTTOBETRAG;
      }
    get taxRate() {
      let taxRate: string;

      if ((this.item as Taxes).STEUERSATZ !== undefined) {
        taxRate = `${ (this.item as Taxes).STEUERSATZ }%`;
      } else {
        taxRate = '';
      }

      return taxRate;
    }

    get payment() {
      if ((this.item as Payment).ZAHLUNGSMITTEL !== undefined) {
        const paymentMethod = this.getPaymentMethod((this.item as Payment).ZAHLUNGSMITTEL);
        return paymentMethod ? paymentMethod.BEZEICHNUNG : '';
      }
      return '';
    }

    getPaymentMethod(id: number) {
      return this.$store.getters.availableCashbookPayments.filter(value => value.NUMMER === id)[0];
    }
  }
