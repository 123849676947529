<script>
  import {VNavigationDrawer} from 'vuetify/lib';
  import {Mixins} from "vue-property-decorator";

  /**
   * This utility extends the vuetify navigation 
   */
  export default {
    extends: Mixins(VNavigationDrawer),
    name: 'custom-drawer',
    methods: {
      swipeLeft(e) {
        const item = document.getElementsByClassName('day-slider').item(0);
        if (item) {
          const rect = item?.getBoundingClientRect();
          if (rect.top > e.touchstartY || (rect.top + rect.height) < e.touchstartY) {
            if (this.isActive && this.right) return;
            this.calculateTouchArea();
            if (Math.abs(e.touchendX - e.touchstartX) < 100) return;
            if (this.right && e.touchstartX >= this.touchArea.right) this.isActive = true; else if (!this.right && this.isActive) this.isActive = false;
          }
        } else {
          if (this.isActive && this.right) return;
          this.calculateTouchArea();
          if (Math.abs(e.touchendX - e.touchstartX) < 100) return;
          if (this.right && e.touchstartX >= this.touchArea.right) this.isActive = true; else if (!this.right && this.isActive) this.isActive = false;
        }
      }
    }
  }
</script>
