
  import { Component, Vue } from 'vue-property-decorator';
  import CashbookTemplateGrid
    from "@/components/admin/CashbookEntryTemplate/CashbookTemplateGrid.vue";
  import DesktopHeader from "@/components/general/DesktopHeader.vue";

  @Component({
    components: {
      CashbookTemplateGrid,
      DesktopHeader,
    },
  })
  export default class CashbookEntryTemplatesDesktop extends Vue {
    onViewItem (item) {
      this.$emit("ViewItem", item);
    }
    onEditItem (item) {
      this.$emit("EditItem", item);
    }
    onDeleteItem (item) {
      this.$emit("DeleteItem", item);
    }
    onAddItem () {
      this.$emit("AddItem");
    }
  }
