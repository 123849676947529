
  import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
  import {
    CountProtocol,
    CountProtocolRequestResponse,
    Stock
  } from "@/assets/service/responseTypes/cashbook/countProtocol/CountProtocol";
  import FancyCurrencyComponent from "@/components/general/FancyCurrencyComponent.vue";
  import Webservice from '@/assets/service/Webservice';

  @Component({
    components: {
      FancyCurrencyComponent
    }
  })
  export default class CashCountDialog extends Vue {


    @Prop({ required: true }) checkFunction !: Function;

    isSimpleStock: boolean = false;
    simpleStock: number = 0.0;

    billstock: Stock[] = [];
    coinstock: Stock[] = [];
    loading: boolean = false;
    processing: boolean = false;

    coinSum: string = "0 €";
    billSum: string = "0 €";
    totalSum: string = "0 €";
    intl = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', useGrouping: true });

    get lfNr(): number {
      return this.$store.getters.lfNrCashCountDialog;
    }

    set lfNr(nr: number) {
      this.$store.commit('SET_LFNR_COUNT_DIALOG', nr);
    }

    get readonly(): boolean {
      return this.lfNr > 0;
    }

    get cashCountDate(): string {
      return this.$store.getters.dateCashCountDialog;
    }

    set cashCountDate(date: string) {
      this.$store.commit('SET_DATE_COUNT_DIALOG', date);
    }

    get showSubTotals(): boolean {
      return this.coinstock.length > 0 && this.billstock.length > 0;
    }

    get open(): boolean {
      return this.$store.getters.showCashCountDialog;
    }

    set open(open: boolean) {
      this.$store.commit('SET_SHOW_COUNT_DIALOG', open);
    }

    //
    get drawer(): number {
      return this.$store.getters.drawerCashCountDialog;
    }

    get step(): number {
      return this.$store.getters.stepCashCountDialog;
    }

    get paymentRequestId(): number {
      if (this.step == 2) {
        return this.$store.getters.drawerCashCountDialog;
      }
      return 0;
    }

    get drawerRequestId(): string {
      if (this.step == 2) {
        return "";
      }
      return String(this.$store.getters.drawerCashCountDialog);
    }

    get dialogFullscreen(): boolean {
      if (this.isSimpleStock)
        return false;
      return this.$vuetify.breakpoint.smAndDown;
    }

    get Title() {
      if (this.step == 2) {
        return this.$t('DayEndClosing.Step2') + " - " + this.paymentName(this.drawer);
      }
      return this.$t('DayEndClosing.Step' + this.step) + " (Schublade " + this.drawer + ")";
    }

    calculateTotalSum(): void {
      let coinSum = this.calculateCoinSum();
      let billSum = this.calculateBillSum();
      this.coinSum = this.intl.format(coinSum);
      this.billSum = this.intl.format(billSum);
      this.totalSum = this.intl.format(coinSum + billSum);
    }

    calculateCoinSum(): number {
      let sum = 0.0;
      this.coinstock.forEach(elem => {
        sum += (elem.ANZAHL * elem.WERT)
      });
      return sum;
    }

    calculateBillSum(): number {
      let sum = 0.0;
      this.billstock.forEach(elem => {
        sum += (elem.ANZAHL * elem.WERT)
      });
      return sum;
    }

    sumText(stockItem: Stock): string {
      let ret = this.intl.format(stockItem.ANZAHL * stockItem.WERT);
      this.calculateTotalSum();
      return ret;
    }

    checkSimpleStock(paymentDivision: CountProtocolRequestResponse): boolean {
      this.isSimpleStock = false;

      if (paymentDivision !== undefined) {
        if ((paymentDivision.LSt_MuenzenStueckelung === undefined || paymentDivision.LSt_MuenzenStueckelung.length === 0) && (paymentDivision.Lst_ScheineStueckelung === undefined || paymentDivision.Lst_ScheineStueckelung.length === 0)) {

          this.isSimpleStock = true;
        }
      }
      return this.isSimpleStock;
    }

    paymentName(val: number): string {
      if (this.step === 2) {
        return this.$store.getters.availableCashbookPayments.filter(value => value.NUMMER === val)[0].BEZEICHNUNG;
      }
      return String(val);

    }

    @Watch('open')
    onOpen() {

      if (this.open) {
        this.loading = true;
        Webservice.getCashCountProtocol(this.lfNr, this.drawerRequestId, this.step, this.paymentRequestId, this.cashCountDate, (resp) => {

            if (resp.Protokollkopf !== undefined) {
              this.cashCountDate = resp.Protokollkopf.DATUM;
            }
            if (this.checkSimpleStock(resp)) {
              this.simpleStock = resp.Protokollkopf.BESTAND;
            } else {
              if (resp.Lst_ScheineStueckelung !== undefined) {
                for (let stockEntry of resp.Lst_ScheineStueckelung) {
                  this.billstock.push({
                    BEZEICHNUNG: stockEntry.BEZEICHNUNG,
                    WERT: stockEntry.WERT,
                    ANZAHL: 0
                  });
                }
              }
              if (resp.LSt_MuenzenStueckelung !== undefined) {
                for (let stockEntry of resp.LSt_MuenzenStueckelung) {
                  this.coinstock.push({
                    BEZEICHNUNG: stockEntry.BEZEICHNUNG,
                    WERT: stockEntry.WERT,
                    ANZAHL: 0
                  });
                }
              }
              if (resp.Lst_Bestand !== undefined) {
                for (let stockEntry of resp.Lst_Bestand) {
                  let index = this.coinstock.findIndex(elem => elem.WERT === stockEntry.WERT);
                  if (index > -1) {
                    this.coinstock[index].ANZAHL = stockEntry.ANZAHL;
                    continue
                  }
                  index = this.billstock.findIndex(elem => elem.WERT === stockEntry.WERT);
                  if (index > -1) {
                    this.billstock[index].ANZAHL = stockEntry.ANZAHL;
                  }
                }
              }
            }
          }
          , () => {
            this.loading = false;
          });

      } else {
        this.simpleStock = 0;
        this.isSimpleStock = false;
        this.lfNr = 0;
        this.cashCountDate = "";
        this.billstock = [];
        this.coinstock = [];
      }
    }

    private async save() {
      if (this.readonly) {
        this.open = false;
        return;
      }
      if (this.processing) {
        return;
      }
      this.processing = true;

      const head = new CountProtocol();
      head.ART = this.step;
      head.KASSENBUCH = this.$store.getters.selectedCashbook.NUM;
      head.DATUM = this.cashCountDate;
      if (this.step !== 2 && this.drawer !== 0) {
        head.SCHUBLADE = this.drawer;
      }
      if (this.isSimpleStock) {
        head.BESTAND = this.simpleStock;
      }
      head.ZAHLUNGSMITTEL = this.paymentRequestId;
      head.LFDNUMMER = this.lfNr;
      const stock = this.coinstock.concat(this.billstock);

      try {
        await this.$ports.billing.upsertCashCountProtocol(head, stock);
        this.checkFunction(this.step);
        this.open = false;
      } catch (e) {
        // error has already been loged
      } finally {
        this.processing = false;
      }
    }

    cancel(): void {
      this.open = false;
    }

    mounted() {
      document.addEventListener("mousewheel", function (event) {
        let numberInput = (<HTMLInputElement>document.activeElement);
        if (numberInput.type === "number") {
          numberInput.blur();
        }
      });
    }
  }
