import axios from 'axios';
import type { CashbookAttachmentResponse } from './models/CachbookAttachmentResponse';
import type { CashbookRepository } from '../CashbookRepository';
import type { AuthenticationProvider } from '../../bridges';
import type { CashbookEntryAttachment, CashbookEntryId } from '../../models';

export class CashbookRestRepository implements CashbookRepository {

    private readonly auth: AuthenticationProvider;

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }

    public async getCashbookEntryAttachment(cashbookEntryId: CashbookEntryId): Promise<CashbookEntryAttachment|undefined> {
        const requestUrl = `${this.auth.getServiceUrl()}/get_ImageURL_KassenbuchEintrag`;
        const response = await axios.post<CashbookAttachmentResponse>(requestUrl, {
            SID: this.auth.getAuthToken(),
            KassenbuchID: cashbookEntryId.cashbookId,
            LaufendeNummer: cashbookEntryId.entryId,
            Datum: cashbookEntryId.date,
        });

        const serviceErrors = response.data.get_ImageURL_KassenbuchEintrag_response?.ServiceErrors;
        const attachmentUrl = response.data.get_ImageURL_KassenbuchEintrag_response?.ImageURL;
        if (serviceErrors) {
            throw serviceErrors;
        }

        if (!attachmentUrl) {
            return undefined;
        }

        const fileType = attachmentUrl.substring(attachmentUrl.lastIndexOf('.') + 1);
        const mimeType = this.getMimeTypeForAttachment(fileType);
        // The downloadable file is only temporary and will be deleted after some time. The created file has to be
        // downloaded as blob and then be made savable with a Object URL
        const attachment = await axios.get(attachmentUrl, { responseType: 'blob' });
        const blob = new Blob([ attachment.data ], { type: mimeType, endings: 'native' });
        const url = window.URL.createObjectURL(blob);
        return {
            name: attachmentUrl,
            type: fileType,
            url: url,
            isImage: mimeType.startsWith('image'),
        };
    }

    private getMimeTypeForAttachment(fileType: string): string {
        switch (fileType) {
            case 'pdf': return 'application/pdf';
            case 'png': return 'image/png';
            case 'jpg': return 'image/jpeg';
            case 'jpeg': return 'image/jpeg';
            // case '.csv': return 'text/csv';
            // add more mime types if needed
            default: throw new Error(`Filetype .${fileType} not supported!`);
        }
    }
}
