
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import FilterCashbookItem from "@/assets/models/FilterCashbookItem";
import CashbookPaymentMethodResponseEntry
    from "@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry";
import i18n from "@/i18n";
import Templates from "@/modules/templates/adapter/vue/Templates.vue";
import moment from "moment-mini";
import Webservice from "@/assets/service/Webservice";
import PersistentDialog from "@/components/general/dialogs/PersistentDialog.vue";

@Component({
    components: {Templates, PersistentDialog},
})
export default class PrintJournal extends Vue {
    @Ref()
    private messageDialog!: PersistentDialog;
    private loading:boolean = false;
    @Prop({required: true})
    private filter!: FilterCashbookItem;
    @Prop({default:1})
    private month!:number;
    @Prop({default:1})
    private year!:number;

    private open: boolean = false;

    private async printMonthJournalConfirm(): Promise<void> {
        if(this.filter.isEmpty) {
            await this.printMonthJournal();
        }else {
            this.messageDialog.showConfirmation("Monatsjournal", "Das Journal berucksichtigt keine Filter. Möchten Sie das Monatsjournal trotzdem drucken?",async()=> {
               await this.printMonthJournal();
            });
        }

    }
    private async printMonthJournal(): Promise<void> {
        try {

            this.loading = true;

            const date = moment(1 + "." + (this.month + 1) + "." + this.year, 'DD.MM.YYYY').startOf('month').format("YYYY/MM/DD");
            const endDate = moment(1 + "." + (this.month + 1) + "." + this.year, 'DD.MM.YYYY').endOf('month').format("YYYY/MM/DD");

            const cashbookId = this.$store.getters.selectedCashbook?.NUM;
            const userId = this.$ports.auth.getUserId();

            const url = await Webservice.getURLReportKassenbuchJournal(userId, cashbookId, date, endDate);
            await this.downloadFile(url);
            // if (this.pdfViewer) {
            //     await this.pdfViewer.show(url, moment(1 + "." + (this.month + 1) + "." + this.year, 'DD.MM.YYYY').format("MM.YYYY"));
            // }

            this.open = false;
        } catch (e) {

        } finally {
            this.loading = false;
            this.open = false;
        }
    }
    private async downloadFile(file: string): Promise<void> {
        // const fileRes = await fetch(file);
        // const fileBlob = await fileRes.blob();
        // const url = window.URL.createObjectURL(fileBlob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = file;
        a.target = '_blank';

        // the filename you want
        //a.download = file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // window.URL.revokeObjectURL(url);
    }
}
