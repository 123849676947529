
import { Component, Vue, Watch } from 'vue-property-decorator';
import FancyCurrencyComponent from '@/components/general/FancyCurrencyComponent.vue';
import TransferOption from '@/assets/models/TransferOption';
import CashbookEntryTemplate from '@/assets/models/CashbookEntryTemplate';
import PaymentEntry from '@/assets/models/PaymentEntry';
import Taxrate from '@/assets/models/Taxrate';
import DatePicker from '@/components/cashbook/dialogs/transactionDialog/DatePicker.vue';
import moment from 'moment-mini';
import { REQUEST_DATEFORMAT } from '@/assets/utils/DateUtil';
import Webservice from '@/assets/service/Webservice';

@Component({
    components: { FancyCurrencyComponent },
})
export default class OpeningBookingDialog extends Vue {

    private webservice = new Webservice();

    private open: boolean = false;
    private loading: boolean = false;


    private errors: any = {};
    private amount: number = 0.0;

    private isValid(): boolean {
        this.errors = {};
        this.$forceUpdate();
        return Object.keys(this.errors).length === 0;
    }


    public show() {
        this.open = true;
        this.amount = 0.0;
        this.loading = false;
    }

    private async save() {
        if (!this.loading && this.isValid()) {
            this.loading = true;

            try {
                await Webservice.insertOpeningBooking(this.amount);

                this.$emit("openingBookingSaved");

                // reload table
                //Webservice.getCashbookEntries(Number(this.$store.getters.selectedMonth), Number(this.$store.getters.selectedYear), () => {});
            } catch (e) {
                // error should already be logged
            }
            this.loading = false;
            this.open=false;
        }
    }

    private cancel() {
        this.open = false;
    }
}
