
  import { Component, Model, Prop, Vue } from 'vue-property-decorator';
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";
  import { CashbookReportsResponseEntry } from "@/assets/service/responseTypes/report/CashbookReportsResponse";

  @Component
  export default class ReportTableGroupRow extends Vue {
    @Model() item!: CashbookReportsResponseEntry;

    @Prop() openReport!: (date: string, callBackFinally: () => void) => void;

    loadReport: boolean = false;

    get type(): string {
      return this.$store.getters.reportTypes.filter(val => val.NUM === this.item.TYP)[0]?.VAL;
    }

    get date() {
      return moment(this.item.DATUM, REQUEST_DATEFORMAT).toDate();
    }

    toggleLoadReport() {
      this.loadReport = !this.loadReport;
    }
  }
