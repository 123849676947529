import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as directives from 'vuetify/es5/directives';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
  directives,
});

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#3F78B3',
        Red: '#CC4C33',
        Green: '#56AD89',
        Blue: '#3F78B3',
        Yellow: '#EAB766',
        LightRed: '#EEC4BB',
        LightGreen: '#C6E3D7',
        LightBlue: '#C0D5E9',
        LightYellow: '#F4DDB5',
        DarkRed: '#883422',
        DarkGreen: '#377359',
        DarkBlue: '#2D557D',
        DarkYellow: '#966514',
      },
      dark: {
        primary: '#3F78B3',
        Red: '#CC4C33',
        Green: '#56AD89',
        Blue: '#3F78B3',
        Yellow: '#EAB766',
        LightRed: '#EEC4BB',
        LightGreen: '#C6E3D7',
        LightBlue: '#C0D5E9',
        LightYellow: '#F4DDB5',
        DarkRed: '#883422',
        DarkGreen: '#377359',
        DarkBlue: '#2D557D',
        DarkYellow: '#966514',
      },
    },
  },
});
