
import { Component, Ref, Vue } from 'vue-property-decorator';
import CashbookEntryTemplatesMobile from '@/views/admin/cashbookEntryTemplate/CashbookEntryTemplatesMobile.vue';
import CashbookEntryTemplatesDesktop from '@/views/admin/cashbookEntryTemplate/CashbookEntryTemplatesDesktop.vue';
import PersistentDialog from '@/components/general/dialogs/PersistentDialog.vue';
import CashbookTemplateDialog from '@/components/admin/CashbookEntryTemplate/dialogs/CashbookTemplateDialog.vue';
import Webservice from '@/assets/service/Webservice';

@Component({
    components: {
        CashbookTemplateDialog,
        PersistentDialog,
        CashbookEntryTemplatesMobile,
        CashbookEntryTemplatesDesktop,
    },
})
export default class CashbookEntryTemplates extends Vue {

    @Ref('templateDialog')
    private readonly templateDialog!: CashbookTemplateDialog;

    @Ref('persistentDialogg')
    private readonly persistentDialog!: PersistentDialog;

    private onAddItem() {
        this.templateDialog.addItem();
    }

    private onViewItem(item) {
        this.templateDialog.viewItem(item);
    }

    private onEditItem(item) {
        this.templateDialog.editItem(item);
    }

    private onDeleteItem(item) {
        this.persistentDialog.showConfirmation('Löschen?', 'Möchten Sie die Buchungsvorlage wirklich löschen?', async () => {
            await Webservice.deleteCashbookEntryTemplate(item.NUMMER, () => {
                //persistentDialog.showInfo("Info", "Die Buchungsvorlage wurde gelöscht.");
            });
        });
    }
}
