
  import CashbookDesktop from './CashbookDesktop.vue';
  import CashbookMobile from './CashbookMobile.vue';
  import CashCountDialog from "@/components/cashbook/dialogs/dayClosing/CashCountDialog.vue";
  import TransferDialog from '@/components/cashbook/dialogs/transfer/TransferDialog.vue';
  import OpeningBookingDialog from '@/components/cashbook/dialogs/openingBooking/OpeningBookingDialog.vue';
  import { Component, Vue } from 'vue-property-decorator';
  import TransactionDialogWrapper from '@/components/cashbook/dialogs/transactionDialog/TransactionDialogWrapper.vue';

  @Component({
    components: {
      TransactionDialogWrapper,
      OpeningBookingDialog,
      TransferDialog,
      CashCountDialog,
      CashbookMobile,
      CashbookDesktop
    }
  })
  export default class Cashbook extends Vue {

  }
