
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class ReportTableHeader extends Vue {
    @Prop() headerProps!: any;

    get isOnSafari(): boolean {
      return (/Safari/i.test(navigator.userAgent));
    }

    getClassesForHeader(header): string {
      let classString: string = `text-${ header.align } `;
      if (header.sortable) {
        classString += 'sortable ';
        if (this.headerProps.props.options.sortBy[0] === header.value) {
          classString += `active ${ this.headerProps.props.options.sortDesc[0] ? 'desc' : 'asc' }`;
        }
      }
      return classString;
    }
  }
