import { CashbookEntryDetailResponse } from '@/assets/service/responseTypes/cashbook/cashbookEntryDetails/CashbookEntryDetailResponse';
import CashbookEntriesResponseEntry
  from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
import CashbookTaxRatesResponseEntry
  from "@/assets/service/responseTypes/cashbook/cashbookTaxRates/CashbookTaxRatesResponseEntry";

const state = {
  cashbooks: JSON.parse(<string>window.localStorage.getItem('cashbooks')) || [],
  cashbookAccounts: JSON.parse(<string>window.localStorage.getItem('cashbookAccounts')) || [],
  selectedCashbook: JSON.parse(window.localStorage.getItem('selectedCashbook') || '{}'),
  activeCashbookDetails: JSON.parse(window.localStorage.getItem('activeCashbookDetails') || '{}'),
  selectedYear: window.localStorage.getItem('selectedYear') || new Date().getFullYear(),
  selectedMonth: window.localStorage.getItem('selectedMonth') || new Date().getMonth(),
  selectedDay: window.localStorage.getItem('selectedDay') || new Date().getDate(),
  showDatePickerDialog: false,
  availableCashbookPayments: JSON.parse(window.localStorage.getItem('availableCashbookPayments') || '[]'),
  taxRates: JSON.parse(window.localStorage.getItem('taxRates') || '[]'),
  cashbookEntriesForSelectedMonth: JSON.parse(window.localStorage.getItem('cashbookEntriesForSelectedMonth') || '[]'),
  cashbookEntryTypes: JSON.parse(window.localStorage.getItem('types') || '[]'),
  cashbookEntryPersistenz: JSON.parse(window.localStorage.getItem('persistenz') || '[]'),
  cashbookEntryDetails: new Map<CashbookEntriesResponseEntry, CashbookEntryDetailResponse>(),
  availableCashbookEntryTemplates: JSON.parse(window.localStorage.getItem('availableCashbookEntryTemplates') || '[]'),
  userPermissions: {},
  loadingEntry: null,
  cashbookBillingDate: '',
  loadTableData: false,
  reportEntries: [],
  reportTypes: JSON.parse(window.localStorage.getItem('reportTypes') || '[]'),
  transferTypes: JSON.parse(window.localStorage.getItem('transferTypes') || '[]'),
};

const getters = {
  cashbooks: initState => initState.cashbooks,
  cashbookAccounts: initState => initState.cashbookAccounts,
  selectedCashbook: initState => initState.selectedCashbook,
  activeCashbookDetails: initState => initState.activeCashbookDetails,
  selectedYear: initState => initState.selectedYear,
  selectedMonth: initState => initState.selectedMonth,
  selectedDay: initState => initState.selectedDay,
  showDatePickerDialog: initState => initState.showDatePickerDialog,
  availableCashbookPayments: initState => initState.availableCashbookPayments,
  taxRates: initState => initState.taxRates,
  cashbookEntriesForSelectedMonth: initState => initState.cashbookEntriesForSelectedMonth,
  cashbookEntryTypes: initState => initState.cashbookEntryTypes,
  cashbookEntryPersistenz: initState => initState.cashbookEntryPersistenz,
  cashbookEntryDetails: initState => initState.cashbookEntryDetails,
  availableCashbookEntryTemplates: initState => initState.availableCashbookEntryTemplates,
  userPermissions: initState => initState.userPermissions,
  loadingEntry: initState => initState.loadingEntry,
  cashbookBillingDate: initState => initState.cashbookBillingDate,
  loadTableData: initState => initState.loadTableData,
  reportEntries: initState => initState.reportEntries,
  reportTypes: initState => initState.reportTypes,
  transferTypes: initState => initState.transferTypes,
};

const mutations = {
  SET_CASHBOOKS(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbooks = params.cashbooks;
    if (params.cashbooks !== undefined) {
      window.localStorage.setItem('cashbooks', JSON.stringify(params.cashbooks));
    }
  },
  SET_CASHBOOK_ACCOUNTS(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookAccounts = params;
    if (params !== undefined) {
      window.localStorage.setItem('cashbookAccounts', JSON.stringify(params));
    }
  },
  SET_SELECTED_CASHBOOK(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.selectedCashbook = params;
    if (params !== undefined) {
      window.localStorage.setItem('selectedCashbook', JSON.stringify(params));
    }
  },
  SET_ACTIVE_CASHBOOK_DETAILS(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.activeCashbookDetails = params;
    if (params !== undefined) {
      window.localStorage.setItem('activeCashbookDetails', JSON.stringify(params));
    }
  },
  SET_SELECTED_YEAR(initState, param) {
    // eslint-disable-next-line no-param-reassign
    initState.selectedYear = param;
    if (param !== undefined) {
      window.localStorage.setItem('selectedYear', param);
    }
  },
  SET_SELECTED_MONTH(initState, param) {
    // eslint-disable-next-line no-param-reassign
    initState.selectedMonth = param;
    if (param !== undefined) {
      window.localStorage.setItem('selectedMonth', param);
    }
  },
  SET_SELECTED_DAY(initState, param) {
    // eslint-disable-next-line no-param-reassign
    initState.selectedDay = param;
    if (param !== undefined) {
      window.localStorage.setItem('selectedDay', param);
    }
  },
  SET_SHOW_DATEPICKER_DIALOG(initState, param) {
    // eslint-disable-next-line no-param-reassign
    initState.showDatePickerDialog = param;
  },
  SET_AVAILABLE_CASHBOOK_PAYMENTS(initState, param) {
    // eslint-disable-next-line no-param-reassign
    initState.availableCashbookPayments = param;
    if (param !== undefined) {
      window.localStorage.setItem('availableCashbookPayments', JSON.stringify(param));
    }
  },
  SET_TAXRATES(initState, params: CashbookTaxRatesResponseEntry[]) {
    // eslint-disable-next-line no-param-reassign
    initState.taxRates = params;
    if (params !== undefined) {
      window.localStorage.setItem('taxRates', JSON.stringify(params));
    }
  },
  SET_CASHBOOKENTRIES_FOR_SELECTED_MONTH(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookEntriesForSelectedMonth = params;
    if (params !== undefined) {
      window.localStorage.setItem('cashbookEntriesForSelectedMonth', JSON.stringify(params));
    }
  },
  SET_CASHBOOKENTRY_TYPES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookEntryTypes = params;
    if (params !== undefined) {
      window.localStorage.setItem('types', JSON.stringify(params));
    }
  },
  SET_CASHBOOKENTRY_PERSISTENZ(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookEntryPersistenz = params;
    if (params !== undefined) {
      window.localStorage.setItem('persistenz', JSON.stringify(params));
    }
  },
  SET_CASHBOOKENTRY_DETAILS(initState, param) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookEntryDetails = param;
  },
  SET_AVAILABLE_CASHBOOK_ENTRY_TEMPLATES(initState, params) {
    if (params !== undefined) {
      window.localStorage.setItem('availableCashbookEntryTemplates', JSON.stringify(params));
    }
    initState.availableCashbookEntryTemplates = params;
  },
  SET_USER_PERMISSIONS(initState, params) {
    initState.userPermissions = params;
  },
  SET_LOADING_ENTRY(initState, params) {
    initState.loadingEntry = params;
  },
  SET_LAST_CASHBOOK_BILLING_DATE(initState, params) {
    initState.cashbookBillingDate = params;
  },
  SET_LOAD_TABLE_DATA(initState, params) {
    initState.loadTableData = params;
  },
  SET_REPORT_ENTRIES(initState, params) {
    initState.reportEntries = params;
  },
  SET_REPORT_TYPES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.reportTypes = params;
    if (params !== undefined) {
      window.localStorage.setItem('reportTypes', JSON.stringify(params));
    }
  },
  SET_TRANSFER_TYPES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.transferTypes = params;
    if (params !== undefined) {
      window.localStorage.setItem('transferTypes', JSON.stringify(params));
    }
  }
};

export default {
  state,
  getters,
  mutations,
};
