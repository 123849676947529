
  import { Component } from 'vue-property-decorator';
  import StepGeneric from "@/components/general/genericComponents/checkOut/steps/StepGeneric";
  import DrawerList from "@/components/general/genericComponents/checkOut/DrawerList.vue";

  @Component({
    components: { DrawerList }
  })
  export default class Step1 extends StepGeneric {

    cancelPollingTask: boolean = false;

    finishStep() {
      this.reload();
      this.next();
    }

    beforeDestroy() {
      this.cancelPollingTask = true;
    }

    mounted() {
      this.reloadDrawers();
    }
  }
