export default class TransferOption {

    public DATUM!: string;
    public ART!: number;
    public BETRAG!: number;
    public VORLAGENACH?: number;
    public VORLAGEVON?: number;
    public STEUERSATZNACH?: number;
    public STEUERSATZVON?: number;
    public ZAHLUNGSMITTELNACH?: number;
    public ZAHLUNGSMITTELVON?: number;
    public BEMERKUNG!: string;
}
