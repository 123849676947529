
  import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
  import { CountProtocolListEntry } from "@/assets/service/responseTypes/cashbook/countProtocol/CountProtocol";

  @Component
  export default class DrawerList extends Vue {
    @Prop() title!: string;

    @Prop() step!: number;

    @Prop() drawerCount!: number | number[];

    @PropSync('protocols', { required: true }) countProtocols!: CountProtocolListEntry[];

    @PropSync('about') aboutDrawers!: boolean;

    drawerTitle(val: number): string {
      if (this.aboutDrawers) {
        return String(val);
      }
      return this.$store.getters.availableCashbookPayments.filter(value => value.NUMMER === val)[0].BEZEICHNUNG;
    }

    getProtocol(drawer: number): CountProtocolListEntry {
      return this.countProtocols.filter(value => Number(this.aboutDrawers ? value.SCHUBLADE : value.ZAHLUNGSMITTEL) === drawer)[0];
    }

    protocolAvailable(drawer: number): boolean {
      return this.getProtocol(drawer) !== undefined;
    }

    openDialog(drawer: number) {
      this.$store.commit('SET_STEP_COUNT_DIALOG', this.step);
      this.$store.commit('SET_DRAWER_COUNT_DIALOG', drawer);
      this.$store.commit('SET_SHOW_COUNT_DIALOG', true);
    }

  }
