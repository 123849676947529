import Vue from 'vue';
import VueI18n from 'vue-i18n';
import type { DateTimeFormats, LocaleMessages, NumberFormats } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats: NumberFormats = {
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
};

const dateTimeFormats: DateTimeFormats = {
  de: {
    long: {
      day: 'numeric', month: 'long', year: 'numeric',
    },
    day: {
      weekday: 'long',
    },
    short: {
      day: 'numeric', month: 'numeric',
    },
    time: {
      hour: 'numeric', minute: 'numeric', hour12: false
    },
    dateTime: {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    },
      date: {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
      }
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages(),
  numberFormats: numberFormats,
  dateTimeFormats: dateTimeFormats,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});
