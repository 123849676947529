
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import CashCountDialog from "@/components/cashbook/dialogs/dayClosing/CashCountDialog.vue";
  import StepGeneric from "@/components/general/genericComponents/checkOut/steps/StepGeneric";
  import {
    CashbookBillingConfiguration,
    Warning
  } from "@/assets/service/responseTypes/cashbook/cashbookBilling/CashbookBillingDefaultResponse";
  import Webservice from '@/assets/service/Webservice';
  import PersistentDialog from "@/components/general/dialogs/PersistentDialog.vue";
  import {warn} from "vue-class-component/lib/util";
  import DesktopHeader from "@/components/general/DesktopHeader.vue";
  @Component({
    components: { PersistentDialog, CashCountDialog,DesktopHeader }
  })
  export default class GenericCheckOut extends Vue {
    currentStepStored: number = 1;

    highestStep: number = 1;

    error: boolean = false;

    loading: boolean = false;

    rollbackDialogOpen: boolean = false;

    title: string = '';
    get cashbookTitle(){
      if(this.$route.meta?.headerHasBranchInfo)
      {
        if(this.$route.meta?.headerTitle)
          return this.$route.meta.headerTitle + " - " + this.activeCashbookTitle;
      }
      return this.activeCashbookTitle;
    }
    get activeCashbookTitle(): string {
      return this.$store.getters.selectedCashbook
        ? this.$store.getters.selectedCashbook.VAL : '';
    }
    get currentStep(): number {
      return this.currentStepStored;
    }

    set currentStep(val: number) {
      if (val > this.highestStep) {
        this.highestStep = val;
      }
      this.currentStepStored = val;
    }

    get steps(): StepComponent[] {
      return [];
    }

    get billingState(): number {
      return this.currentStep;
    }

    set billingState(step: number) {
      this.currentStep = Number(step);
    }

    get cashbookId(): number {
      return this.$store.getters.selectedCashbook?.NUM;
    }

    get activeConfig(): CashbookBillingConfiguration {
      return this.$store.getters.dayEndClosingConfig;
    }

    showWarning(waring:Warning) {
      (this.$refs.persistentDialog as PersistentDialog).showWarning(waring.CAPTION, waring.TEXT);
    }

    next() {
      this.currentStep++;
    }

    updateSteps() {
        const steps = this.$refs['step' + this.currentStep]!;
        (steps[0] as StepGeneric).checkProcessable();
    }

    toggleRollbackDialog(): void {

      this.rollbackDialogOpen = !this.rollbackDialogOpen;

    }

    setLoading(value: boolean): void {
      this.loading = value;
    }

    stateCallback(val) {
    }

    @Watch('cashbookId')
    reload() {
      this.loading = true;
      Webservice.getCashbookBillingState((val) => {
        this.stateCallback(val);
        this.loading = false;
      });
      Webservice.getCashbookBillingConfiguration();
    }

    rollback() {
      const cashbook = this.$store.getters.selectedCashbook.NUM;
      this.$ports.billing.rollbackCashbookBilling(cashbook).then(() => {
        this.rollbackDialogOpen = false;
        this.error = false;
        if(this.$store.getters.isInjected){
            this.$router.replace("/end");
        } else {
            this.$router.push('/cashbook');
        }
      });
    }
  }

  export interface StepComponent {
    title: string;
    component: any;
    stepNumber: number;
  }
