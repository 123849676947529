import { AuthService } from './services/AuthService';

export class AuthPort {
    private readonly authService: AuthService;

    constructor(params: { authService: AuthService }) {
        this.authService = params.authService;
    }

    public async login(): Promise<boolean> {
        return this.authService.login();
    }

    public logout(): void {
        this.authService.logout();
    }

    public getServiceUrl(): string {
        return this.authService.getServiceUrl();
    }

    public getAuthToken(): string {
        return this.authService.getAuthToken();
    }

    public getUserId(): string {
        return this.authService.getUserId();
    }

    public getBranchId(): string {
        return this.authService.getBranchId();
    }

    public isLoggedIn(): Promise<boolean> {
        return this.authService.isLoggedIn();
    }

    public async getBranches(): Promise<{Nummer: number, Name:string}[]> {
        return await this.authService.getBranches();
    }

   public async getUserConfig(appCall:boolean):Promise<any> {
        return this.authService.getUserConfig(appCall);
   }

    public async getSessionInfo(sid:string): Promise<any> {
        return this.authService.getSessionInfo(sid);
    }
}
