
import { Component, Watch } from 'vue-property-decorator';
import moment from 'moment-mini';
import TransactionDialog from '@/components/cashbook/dialogs/transactionDialog/TransactionDialog.vue';
import Cashbook from '@/views/cashbook/Cashbook';
import { REQUEST_DATEFORMAT } from '@/assets/utils/DateUtil';
import isIngestion from '@/assets/utils/CashbookUtils';
import { tableHeaders } from "@/assets/config/TableHeaders";
import CashbookEntriesResponseEntry from "@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry";
import DayEndClosingDialog from "@/components/cashbook/dialogs/dayClosing/DayEndClosingDialog.vue";
import DesktopMonthPicker from "@/components/general/genericComponents/dateDrivenComponents/DesktopMonthPicker.vue";
import CashbookInfo from "@/assets/service/responseTypes/cashbook/cashbookDetail/CashbookInfo";
import AproconHeader from '@aprocon-software/aprocon-header';
import FilterMenu from "@/components/cashbook/dialogs/filter/FilterMenu.vue";
import PrintJournal from "@/components/cashbook/dialogs/printJournal/PrintJournal.vue";

@Component({
    components: {
        PrintJournal,
        FilterMenu,
      DesktopMonthPicker,
      TransactionDialog,
      DayEndClosingDialog,
      AproconHeader,
    },
  })
  export default class CashbookDesktop extends Cashbook {

    showEarlierStored: boolean = false;
    sortBy: string | [] = '';
    sortDesc: boolean | [] = true;

    // eslint-disable-next-line class-methods-use-this
    get headers() {
      return tableHeaders;
    }
    get cashbookTitle(){
      if(this.$route.meta?.headerHasBranchInfo)
      {
        if(this.$route.meta?.headerTitle)
          return this.$route.meta.headerTitle + " - " + this.activeCashbookTitle;
      }
      return this.activeCashbookTitle;
    }
    get filterableCashbookDetailEntries(): CashbookEntriesResponseEntry[] {
      return this.paymentFilteredEntries
        .filter((value: CashbookEntriesResponseEntry) => {
          if (this.filter.BUCHUNGSTEXT !== null) {
            return value.BUCHUNGSTEXT
              .toLowerCase()
              .includes(this.filter.BUCHUNGSTEXT.toLowerCase());
          }
          return true;
        }).filter((value: CashbookEntriesResponseEntry) => {
              if (this.filter.DATUM && this.filter.DATUM) {
                  const date = moment(value.DATUM, REQUEST_DATEFORMAT);
                  const reqDate= moment(this.filter.DATUM);
                  return date.date() === reqDate.date();
              }
              return true;
        });
    }

    private get allowedDates():string[]{
        return this.paymentFilteredEntries.map((entry)=>{
          if(entry && entry.DATUM){
            return entry.DATUM.replaceAll("/","-");
          }
          return '';
        });
    }
    get showEarlier(): boolean {
      return this.showEarlierStored;
    }

    set showEarlier(val: boolean) {
      this.showEarlierStored = val;
    }

    get activeCashbookInfo(): CashbookInfo {
      return this.$store.getters.activeCashbookDetails;
    }

    get isCurrentMonthSelected(): boolean {
      return this.now.getMonth() === this.selectedMonth && this.now.getFullYear() === this.selectedYear;
    }

    get dateFirstEntry(): Date {
      const startDate = moment(this.activeCashbookInfo?.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT).toDate();
      startDate.setHours(0, 0, 0, 0);
      return startDate;
    }

    get availableYears() {
      const years: number[] = [];
      if (this.activeCashbookInfo) {
        const currentYear: number = (this.now).getFullYear();

        for (let i: number = (this.dateFirstEntry.getFullYear()); i <= currentYear; i += 1) {
          years.push(i);
        }
        if (years.length === 0) {
          years.push(currentYear);
        }
      }
      return years.reverse();
    }

    get tableFilterableTodayCashbookDetailEntries(): CashbookEntriesResponseEntry[] {
      return this.filterableCashbookDetailEntries
        .filter(value => {
          const date = moment(value.DATUM, REQUEST_DATEFORMAT);
          const now = moment(this.now);
          return (date.year() === now.year() && date.dayOfYear() === now.dayOfYear());
        })
        .map((value, index) => ({ id: index, ...value }));
    }

    get containsFilterableEarlierCashbookDetailEntries(): boolean {
      return this.filterableEarlierCashbookEntries.length > 0;
    }

    get tableFilterableEarlierCashbookDetailEntries(): CashbookEntriesResponseEntry[] {
      return (this.showEarlier || !this.isCurrentMonthSelected || this.filter.DATUM)
          ? this.filterableEarlierCashbookEntries.map((value, index) => ({ id: index, ...value }))
          : [];
    }

    get filterableEarlierCashbookEntries(): CashbookEntriesResponseEntry[] {
      return this.filterableCashbookDetailEntries
        .filter((value) => {
          const date = moment(value.DATUM, REQUEST_DATEFORMAT);
          const now = moment(this.now);
          return date.year() < now.year() || (date.year() === now.year() && date.dayOfYear() < now.dayOfYear());
        });
    }

    setShowEarlier(val: boolean) {
      this.showEarlier = val;
    }

    @Watch('activeCashbookTitle')
    cashbookChanged() {
      this.checkIfDayClosingIsActive();
      this.changeMonth();
      this.filter.clear();
    }

    @Watch('activeCashbookInfo')
    loadEntries() {
      if (this.$store.getters.cashbookEntriesForSelectedMonth.length === 0) {
        this.changeMonth();
      }
    }

    changeMonth() {
      super.changeMonth();
    }

    getSum(ingestion: boolean, billed: boolean, callback?: () => void): number {
      let sum: number = 0;
      if (this.filterableCashbookDetailEntries) {
        (billed ? this.tableFilterableEarlierCashbookDetailEntries
          : this.tableFilterableTodayCashbookDetailEntries).filter(value => value.PERSISTENZ !== 4).forEach((value) => {
          const [type] = this.$store.getters.cashbookEntryTypes
            .filter(value1 => value1.NUM === value.TYP);
          sum += (((isIngestion(type) && ingestion)
            || (!isIngestion(type) && !ingestion)) ? value.BRUTTO : 0);
        });
      }
      if (callback) {
        callback();
      }
      return sum;
    }

    isEntryReloading(entry: CashbookEntriesResponseEntry) {
      if (this.$store.getters.loadingEntry) {
        return entry.LFDNUMMER === this.$store.getters.loadingEntry.LFDNUMMER && entry.DATUM === this.$store.getters.loadingEntry.DATUM;
      }
      return false;
    }

    toggleCombobox(cbx: string) {
      const combobox: any = this.$refs[cbx];
      if (combobox.isMenuActive) {
        (this.$refs[cbx] as any).isMenuActive = false;
      } else {
        (this.$refs[cbx] as any).isMenuActive = true;
        combobox.focus();
      }
    }

    mounted() {
      super.mounted();
      this.$store.commit('SET_FOOTER_ON_UI', { footerOnUI: true });
    }
  }
