
  import { Component, Vue } from 'vue-property-decorator';
  import AboutMobile from '@/views/about/AboutMobile.vue';
  import AboutDesktop from "@/views/about/AboutDesktop.vue";
  import AboutLoggedOut from "@/views/about/AboutLoggedOut.vue";

  @Component({
    components:{
      AboutLoggedOut,
      AboutMobile,
      AboutDesktop
    }
  })
  export default class About extends Vue {
    get loggedIn(): boolean {
      return this.$store.getters.isLoggedIn();

    }
  }
