import type { RouteConfig } from 'vue-router';

const route: RouteConfig = {
    // path: '/cashbookEntryTemplates',
    path: '/admin/templates',
    // name: 'CashbookEntryTemplates',
    name: 'Templates',
    component: () => import('./Templates.vue'),
    meta: {
        loginRequired: true,
        headerTitle: 'Buchungsvorlagen',
    },
};

export { route };
