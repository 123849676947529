import Vue from 'vue';
import Router from 'vue-router';
import Cashbook from './views/cashbook/Cashbook.vue';
import About from './views/about/About.vue';
import ComingSoon from './views/ComingSoon.vue';
import store from '@/store/store';
import DayEndClosing from "@/views/DayEndClosing.vue";
import CashbookRoot from "@/views/cashbook/CashbookRoot.vue";
import Admin from "@/views/admin/Admin.vue";
import CashbookEntryTemplates from "@/views/admin/cashbookEntryTemplate/CashbookEntryTemplates.vue";
import Report from "@/views/report/Report.vue";
import PermissionUtil from "@/assets/utils/PermissionUtil";
import { RouteConfig, RouteConfigSingleView } from 'vue-router/types/router';
import { Route as Templates } from '@/modules/templates';
import StartApp from "@/views/StartApp.vue";
import CloseDay from "@/views/CloseDay.vue";
import End from "@/views/End.vue";

Vue.use(Router);

export interface ExtendedRouteConfig extends RouteConfigSingleView {
    icon: string;
}

export function getActiveBranch(): string {
    return store.getters.selectedCashbook
        ? store.getters.selectedCashbook.VAL : '';
}

export const mainSites: ExtendedRouteConfig[] = [
    {
        path: '/cashbook',
        component: CashbookRoot,
        icon: 'fa-calendar-alt',
        meta: {
            loginRequired: true,
            headerTitle: "Kassenbuch",
            headerHasBranchInfo: true,
        },
        children: [
            {
                path: '',
                name: 'Übersicht',
                component: Cashbook,
                meta: {
                    loginRequired: true,
                    headerTitle: "Kassenbuch",
                    headerHasBranchInfo: true,
                },
            },
            {
                path: 'day-closing',
                name: 'Tagesabschluss',
                component: DayEndClosing,
                meta: {
                    loginRequired: true,
                    headerTitle: "Tagesabschluss",
                    headerHasBranchInfo: true,
                },
            },
        ]
    },
    // {
    //   path: '/cash-check',
    //   name: 'Kassensturz',
    //   component: () => import('@/views/checkout/CheckoutRoot.vue'),
    //   icon: 'fa-cash-register',
    //   meta: {
    //     loginRequired: true,
    //   }
    // },
    {
        path: '/report',
        name: 'Kassenberichte',
        component: Report,
        icon: 'far fa-file-alt',
        meta: {
            loginRequired: true,
            headerTitle: "Abschlüsse",
            headerHasBranchInfo: true,
            userAuthorized: () => {
                return PermissionUtil.hasPermissionForCashCheck()
            }
        },
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        icon: 'fa-cog',
        meta: {
            loginRequired: true,
            headerTitle: "Verwaltung",
        }
    },
];

export const otherSites: RouteConfig[] = [
    {
        path: '/imprint',
        name: 'Imprint',
        component: ComingSoon,
    },
    {
        path: '/agb',
        name: 'AGB',
        component: ComingSoon,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            headerTitle: "Über Kassenbuch",
        }
    },
    {
        path: '/',
        redirect: '/cashbook',
    },
    {
        path: '*',
        name: '404Redirect',
        redirect: to => '/404',
    },
    {
        path: '/404',
        name: '404',
        component: () => import('./views/errors/404.vue'),
    },{
        path: '/start',
        name: 'start',
        component: StartApp,
        meta: {
            pageTitle: 'Start',
        },
    },{
        path: '/closeDay',
        name: 'closeDay',
        component: CloseDay,
        meta: {
            pageTitle: 'Tagesabschluss',
        },
    },
    {
        path: '/end',
        name: 'end',
        component: End,
        meta: {
            headerTitle: "Ende",
        },
    },
    {
        path: '/cashbookEntryTemplates',
        name: 'CashbookEntryTemplates',
        component: CashbookEntryTemplates,
        meta: {
            loginRequired: true,
            headerTitle: "Buchungsvorlagen",
        },
    },
    Templates,
];

const router: Router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes:
        otherSites.concat(mainSites),
});

export default router;
