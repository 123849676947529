import ResultWrapper from "@/assets/service/responseTypes/ServiceResults";

export default class CountProtocolResponseWrapper {
  upsert_Zaehlprotokoll_response!: CountProtocolInsertResponse;
  get_Zaehlprotokoll_response!: CountProtocolRequestResponse;
  Lst_KassenbuchEintrag_Zaehlprotokolle_response!: CountProtocolListResponse;
  Lst_uncommitted_Zaehlprotokolle_response!: CountProtocolListResponse;
  commit_Zaehlprotokolle_response!: ResultWrapper;
  rollback_Zaehlprotokolle_response!: ResultWrapper;
  get_Kassenbuch_Zahlungsmittel_Stueckelung_response!: PaymentDivision;
  ignore_Kassenbericht_Error_response!: ResultWrapper;
}

export class CountProtocolInsertResponse extends ResultWrapper {
  Kopf!: CountProtocol;
}

export class CountProtocolRequestResponse extends ResultWrapper {
  Protokollkopf!: CountProtocol;
  Lst_Bestand!: Stock[];
  LSt_MuenzenStueckelung !: Stock[];
  Lst_ScheineStueckelung !: Stock[];
}

export class CountProtocolListResponse extends ResultWrapper {
  Lst_Zaehlprotokolle!: CountProtocolListEntry[];
  skippedrecords!: string;
  resultcomplete!: string;
}

export class PaymentDivision extends ResultWrapper {
  MuenzenStueckelung!: PaymentDivisionPart[];
  ScheineStueckelung!: PaymentDivisionPart[];
}

export class PaymentDivisionPart {
  BEZEICHNUNG!: number;
  WERT!: number;
}

export class CountProtocol {
  KASSENBUCH!: number;
  LFDNUMMER!: number;
  DATUM!: string;
  UHRZEIT!: string;
  SCHUBLADE!: number;
  ART!: number;
  ZAHLUNGSMITTEL!: number;
  BESTAND!: number;
  BEMERKUNG!: string;
}

export class CountProtocolListEntry extends CountProtocol {
  NUMMER!: number;
}

export class Stock {
  BEZEICHNUNG!: string;
  WERT!: number;
  ANZAHL!: number;
}
