import CashbookEnumerationsEntry
  from '../service/responseTypes/cashbook/cashbookEnumerations/CashbookEnumerationsEntry';

export default function isIngestion(type: CashbookEnumerationsEntry): boolean {
  switch (type.NUM) {
    case 1:
    case 3:
    case 5:
    case 7:
      return true;
    case 2:
    case 4:
    case 6:
      return false;
    default:
      return true;
  }
}
