export default class CashbookEntryTemplate {
  NUMMER!: number;

  TYP!: number;

  ZAHLUNGSMITTEL!: number;

  KONTO?: number;

  BUCHUNGSTEXT!: string;

  MWSTART?: number;

  BRUTTO!: number;

  KOSTENSTELLE?: number;

  BEMERKUNG!: string;

  KASSENBUCH!: number;

  constructor(template?: CashbookEntryTemplate) {
    if (template) {
      this.NUMMER = template.NUMMER;
      this.ZAHLUNGSMITTEL = template.ZAHLUNGSMITTEL;
      this.BRUTTO = template.BRUTTO;
      this.MWSTART = template.MWSTART;
      this.TYP = template.TYP;
      this.KONTO = template.KONTO;
      this.BUCHUNGSTEXT = template.BUCHUNGSTEXT;
      this.BEMERKUNG = template.BEMERKUNG;
      this.KASSENBUCH=template.KASSENBUCH;
      if (template.KOSTENSTELLE && template.KOSTENSTELLE > 0)
        this.KOSTENSTELLE = template.KOSTENSTELLE;
    }
  }
}
