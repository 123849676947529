import Vue from 'vue';
import store from "@/store/store";
import Webservice from '@/assets/service/Webservice';

export default abstract class PermissionUtil {
  static correctUtilsLoaded(): boolean {
    return store.getters.selectedCashbook.NUM === store.getters.userPermissions.cashbookID
      && Vue.ports.auth.getUserId() === store.getters.userPermissions.userId;
  }

  static hasPermissionToEditCashbookEntry(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.EINTRAGAENDERN;
  }

  static hasPermissionToDeleteCashbookEntry(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.EINTRAGLOESCHEN;
  }

  static hasPermissionToCancelCashbookEntry(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.EINTRAGSTORNIEREN;
  }

  static hasPermissionToCreateTemplates(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.VORLAGEANLEGEN;
  }

  static hasToUseTemplates(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.VORLAGENZWANG;
  }

  static hasPermissionToEditTemplates(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.VORLAGEAENDERN;
  }

  static hasPermissionToDeleteTemplates(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.VORLAGELOESCHEN;
  }

  static hasPermissionForClosure(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.ABSCHLUSS;
  }

  static hasPermissionForCashCheck(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.KASSSTURZ;
  }

  static hasPermissionToEditAfterDayClosing(): boolean {
    if (!PermissionUtil.correctUtilsLoaded()) {
      Webservice.getUserPermissions();
      return false;
    }
    return store.getters.userPermissions.permissions.EDITNACHTA;
  }
}
