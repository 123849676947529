import Vue from 'vue';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

interface SessionState {
    serviceUrl: string|null;
    authToken: string|null;
    userId: string|null;
    branchId: string|null;
    loggedIn: boolean;

    isInjected: boolean;
}

const initState: SessionState = {
    serviceUrl: localStorage.getItem('service'),
    authToken: localStorage.getItem('token'),
    userId: localStorage.getItem('activeUserId'),
    loggedIn: false,
    branchId: localStorage.getItem('branchId'),
    isInjected: false,
};

const moduleGetters: GetterTree<SessionState, any> = {

    isInjected(state: SessionState):boolean{
        return state.isInjected || false;
    },

    serviceUrl(state: SessionState): string {
        return state.serviceUrl || '';
    },
    authToken(state: SessionState): string {
        return state.authToken || '';
    },
    userId(state: SessionState): string {
        return state.userId || '0';
    },
    branchId(state: SessionState): string {
        return state.branchId || '0';
    },
    loggedIn(state: SessionState): boolean {
        return state.loggedIn;
    },

    // legacy
    service(state: SessionState, getters): string {
        return getters.serviceUrl;
    },
    activeUserId(state: SessionState, getters): string {
        return getters.userId;
    },
};

const moduleMutations: MutationTree<SessionState> = {
    setServiceUrl(state: SessionState, serviceUrl: string): void {
        state.serviceUrl = serviceUrl;
        localStorage.setItem('service', serviceUrl);

        // what does this do?
        if(navigator.serviceWorker) {
            navigator.serviceWorker.ready.then((value) => {
                if (value.active) {
                    value.active.postMessage({
                        transfer: [serviceUrl],
                        command: 'updateService',
                    });
                }
            });
        }
    },
    setAuthToken(state: SessionState, authToken: string): void {
        state.authToken = authToken;
        localStorage.setItem('token', authToken);
    },
    setUserId(state: SessionState, userId: string): void {
        state.userId = userId;
        localStorage.setItem('activeUserId', userId);
    },
    setBranchId(state: SessionState, branchId: string): void {
        state.branchId = branchId;
        localStorage.setItem('branchId', branchId);
    },
    setLoggedIn(state: SessionState, loggedIn: boolean) {
        state.loggedIn = loggedIn;
    },
    setIsInjected(state: SessionState, isInjected: boolean) {
        state.isInjected = isInjected;
    },

};

const moduleActions: ActionTree<SessionState, any> = {
    async login(context): Promise<void> {
        const loggedIn = await Vue.ports.auth.login();
        context.commit('setBranchId', Vue.ports.auth.getBranchId());
        context.commit('setLoggedIn', loggedIn);
    },

    async logout(context): Promise<void> {
        Vue.ports.auth.logout();
        context.commit('setLoggedIn', false);
    },

    async keepAlive(context): Promise<boolean> {
        const loggedIn = Vue.ports.auth.isLoggedIn();
        context.commit('setLoggedIn', loggedIn);
        if (!loggedIn) {
            Vue.ports.auth.logout();
        }
        return loggedIn;
    },
}

const module: Module<SessionState, any> = {
    state: initState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
