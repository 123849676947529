
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import { get_tex_width } from "@/assets/utils/transactionDialog/transactionDialogUtils";

  @Component({})
  export default class FancyCurrencyComponent extends Vue {
    @Model('change', { type: Number }) model!: number;

    @Prop({ default: '' }) title!: string;

    @Prop({ default: false }) readOnly!: boolean;

    @Prop({ default: '' }) error!: string;

    @Prop({ default: undefined }) min!: number|undefined;

    @Prop({ default: undefined }) max!: number|undefined;

    @Prop({ default: '' }) rowClass!: string;

    @Prop({ default: '4:8' }) colRatio!: string;

    @Prop({ default: '' }) inputRowClass!: string;

    @Prop({ default: 12 }) cols!: number;

    focused: boolean = false;

    get getClasses(): string {
      let classes: string = '';
      if (this.readOnly) {
        classes = 'read-only ';
      }

      if (this.focused) {
        classes += 'primary--text focus ';
      }

      if (this.error.length > 0) {
        classes += 'error--text';
      }

      return classes;
    }

    get val(): number {
      return this.model;
    }

    set val(val: number) {
      this.$emit('change', val);
    }

    mounted() {
      this.readonlySetter();
    }

    focus() {
      this.focused = true
      this.$el.querySelector('input')?.select();
    }

    get_tex_width(txt, font) {
      return get_tex_width(txt, font);
    }

    @Watch('readOnly')
    readonlySetter() {
      if (this.readOnly) {
        this.$el.querySelector('input')?.setAttribute('readonly', '');
      } else {
        this.$el.querySelector('input')?.removeAttribute('readonly');
      }
    }
  }
