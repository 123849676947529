export default abstract class ErrorConstants {
  static NO_CONNECTION: string = 'noConnection';
  static MAX_UPLOAD_SIZE: string = 'maxUploadSize';

  static getDisplayStringForError(error: string): string {
    switch (error) {
      case '': return 'Error.NoError';
      case this.NO_CONNECTION: return 'Error.NoConnection';
      case this.MAX_UPLOAD_SIZE: return 'Error.MaxUploadSize';
      default: return 'Error.UnknownError'
    }
  }

  static getDisplayTitleForError(error: string): string {
    switch (error) {
      case '': return 'ErrorTitle.NoError';
      case this.NO_CONNECTION: return 'ErrorTitle.NoConnection';
      case this.MAX_UPLOAD_SIZE: return 'ErrorTitle.MaxUploadSize';
      default: return 'ErrorTitle.UnknownError'
    }
  }
}
