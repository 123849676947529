export interface TableHeader {
  text: string;
  value: string;
  align?: string;
  width: string;
  sortable: boolean;
}

export const tableHeaders: TableHeader[] = [
  {
    text: 'LFD',
    value: 'LFDNUMMER',
    sortable: true,
    align: 'center',
    width: '5%',
  },
  {
    text: 'Date',
    value: 'DATUM',
    sortable: true,
    align: 'center',
    width: '5%',
  },
  {
    text: 'BusinessCase',
    value: 'BUCHUNGSTEXT',
    sortable: true,
    align: 'start',
    width: '30%',
  },
  {
    text: 'Ingestion',
    value: 'BRUTTO',
    sortable: true,
    align: 'end',
    width: '11%',
  },
  {
    text: 'Tax',
    value: 'MWSTSATZ',
    sortable: true,
    align: 'end',
    width: '8%',
  },
  {
    text: 'Payment',
    value: 'KASEIN_MWST',
    sortable: true,
    align: 'end',
    width: '10%',
  },
  {
    text: 'CashBalance',
    value: 'SALDO',
    sortable: true,
    align: 'end',
    width: '9%',
  },
  {
    text: 'Appends',
    value: '',
    sortable: false,
    align: 'center',
    width: '7%',
  },
  {
    text: 'Action',
    value: 'action',
    sortable: false,
    width: '14%',
    align: 'center',
  },
];

export const cashbookDetailsHeader: TableHeader[] = [
  {
    value: '',
    text: '',
    width: '2%',
    sortable: false,
  },
  {
    value: '',
    text: '',
    width: '40%',
    sortable: false,
  },
  {
    value: 'BRUTTO',
    text: '',
    align: 'end',
    width: '11%',
    sortable: true,
  },
  {
    text: 'Tax',
    value: 'MWSTSATZ',
    sortable: true,
    align: 'end',
    width: '8%',
  },
  {
    text: 'Payment',
    value: 'KASEIN_MWST',
    sortable: true,
    align: 'end',
    width: '10%',
  },
  {
    value: '',
    text: '',
    width: '16%',
    sortable: false,
  },
];

export const reportTableHeader: TableHeader[] = [
  {
    text: 'Date',
    value: 'DATUM',
    sortable: true,
    align: 'center',
    width: '5%',
  },
  {
    text: 'LFD',
    value: 'LFDNUMMER',
    sortable: true,
    align: 'center',
    width: '5%',
  },
  {
    text: 'Typ',
    value: '',
    sortable: false,
    align: 'right',
    width: '7%',
  },
  {
    text: 'Anfangsbestand',
    value: '',
    sortable: false,
    align: 'right',
    width: '7%',
  },
  {
    text: 'Endbestand',
    value: '',
    sortable: false,
    align: 'right',
    width: '7%',
  },
  {
    text: 'Transit',
    value: '',
    sortable: false,
    align: 'right',
    width: '7%',
  },
  {
    text: 'Differenz',
    value: '',
    sortable: false,
    align: 'right',
    width: '7%',
  },
  {
    text: 'Bericht',
    value: '',
    sortable: false,
    align: 'center',
    width: '7%',
  }
];
