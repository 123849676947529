
  import {Component, Vue} from 'vue-property-decorator';
  import GetLicenceInformationResponse
    from "@/assets/service/responseTypes/about/GetLicenseInformationResponseEntry";
  import GetLicenceInformationResponseEntry
    from "@/assets/service/responseTypes/about/GetLicenseInformationResponseEntry";
  import Webservice from '@/assets/service/Webservice';

  @Component
  export default class ServiceAbout extends Vue {
    serviceAbout: GetLicenceInformationResponse;

    constructor() {
      super();
      this.serviceAbout = new GetLicenceInformationResponseEntry();
    }
    getText(text: string): string {
      if(text){
      return text
        .replace(/;/g, "\n");
      }
      return '';
    }
    created() {
      Webservice.getLicenseInformation((resp) => {
        this.serviceAbout = resp;

      });
    }
  }
