import { Guid } from 'guid-typescript';
import CashbookPaymentMethodResponseEntry
  from '@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry';
import DivisionStyle from '@/components/cashbook/dialogs/transactionDialog/DivisionStyle';
import CashbookTaxRatesResponseEntry
  from '@/assets/service/responseTypes/cashbook/cashbookTaxRates/CashbookTaxRatesResponseEntry';

export default class CashbookDivision {
  uuID: string = Guid.create().toString();

  current!: number;

  taxRate!: CashbookTaxRatesResponseEntry;

  tax: number = 0;

  payment!: CashbookPaymentMethodResponseEntry;

  style!: DivisionStyle | undefined;

  currentError: string = '';

  taxError: string = '';

  paymentError: string = '';

  maxValue: number = 0;

  constructor(current?: number,
              maxValue?: number,
              taxRate?: CashbookTaxRatesResponseEntry,
              payment?: CashbookPaymentMethodResponseEntry,
              style?: DivisionStyle) {
    if (current !== undefined) {
      this.current = current;
    }
    if (maxValue !== undefined) {
      this.maxValue = maxValue;
    }
    if (taxRate !== undefined) {
      this.taxRate = taxRate;
    }
    this.style = style;
    if (payment) {
      this.payment = payment;
    }
  }

  equals(compare: any): boolean {
    if (compare instanceof CashbookDivision) {
      return (compare.uuID === this.uuID
        && compare.current === this.current
        && compare.taxRate === this.taxRate);
    }
    return false;
  }
}
