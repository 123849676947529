
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import {ExtendedRouteConfig} from "@/router";

  @Component({})
  export default class SidemenuEntry extends Vue {
    @Prop() title!: string;

    @Prop() link!: string | undefined;

    @Prop() icon!: string | undefined;

    @Prop({default:true}) showLink!: boolean;

    private get sideMenuEntryTitle() : string {
        if(this.title ) {
            return this.$tc('Sidemenu.' + this.title);
        }

        return this.$tc('Sidemenu.Übersicht');
    }
  }
