export default class Taxes implements TaxesInterface {
  ART!: number;
  BRUTTOBETRAG!: number;
  STEUERBETRAG!: number;
  STEUERSATZ!: number;

  constructor(ART?: number, STEUERSATZ?: number, STEUERBETRAG?: number, BRUTTOBETRAG?: number) {
    // this.ART = ART;
    if (STEUERSATZ) {
      this.STEUERSATZ = STEUERSATZ;
    }
    // this.STEUERBETRAG = STEUERBETRAG;
    if (BRUTTOBETRAG) {
      this.BRUTTOBETRAG = BRUTTOBETRAG;
    }
  }
}

export interface TaxesInterface {
  ART: number;

  STEUERSATZ: number;

  STEUERBETRAG: number;

  BRUTTOBETRAG: number;
}
