
import {Component, Prop, Vue} from "vue-property-decorator";
import Webservice from "@/assets/service/Webservice";

@Component({components: {}})
export default class StartApp extends Vue {
    private errorMessage:string="Leider hat etwas nicht funktioniert."
    private success: boolean = true;

    async mounted() {
        const query = {...this.$route.query};
        if (query.sid) {
            try{
                if(query.surl && query.surl !== ''){
                    this.$store.commit("setServiceUrl", query.surl);
                }
                const sessionInfo = await this.$ports.auth.getSessionInfo(query.sid.toString());
                if(sessionInfo){
                    this.$store.commit("setAuthToken", query.sid.toString());
                    this.$store.commit("setBranchId", sessionInfo.Filiale.ID);
                    this.$store.commit("setUserId", sessionInfo.User.ID);
                    this.$store.commit("setLoggedIn", true);
                    this.$store.commit("setIsInjected", true);
                    const cashbooks = await Webservice.getCashbooks();
                    if(cashbooks && cashbooks.length > 0) {
                        const book = cashbooks.find((book) => {
                            if (book.FILIALID) {
                                return book.FILIALID === sessionInfo.Filiale.ID;
                            }
                        });
                        if (book && book.NUM) {
                            this.$store.commit('SET_SELECTED_CASHBOOK', book);
                        } else {
                            console.log(`No Cashbook matches FilialId = ${sessionInfo.Filiale.ID}`);
                            this.$store.commit('SET_SELECTED_CASHBOOK', cashbooks[0]);
                        }
                    }
                    await this.$router.replace("/cashbook");
                    this.success = true;
                }
            }catch(e:any) {
                console.log(e);
                this.success = false;
                this.errorMessage = e;
            }

        } else {
            this.success = false;
        }
    }
}