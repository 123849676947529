import Vue from 'vue';
import Vuex from 'vuex';
import cashbook from '@/store/modules/cashbook/cashbook';
import session from '@/store/session/SessionModule';
import transactionDialog from '@/store/modules/cashbook/transactionDialog';
import error from "@/store/modules/error";
import dayClosing from "@/store/modules/dayClosing";
import countProtocol from "@/store/modules/countProtocol";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    users: [],
    footerOnUI: true,
    caller: <string>window.localStorage.getItem('caller')||'',
  },
  getters: {
    users: state => state.users,
    isFooterOnUI: state => state.footerOnUI,
    caller: initState => initState.caller,
  },
  mutations: {
    SET_USERS(state, params) {
      state.users = params.users;
    },
    SET_FOOTER_ON_UI(state, params) {
      state.footerOnUI = params.footerOnUI;
    },
    SET_CALLER(initState, params:string) {
        // eslint-disable-next-line no-param-reassign
        initState.caller = params;
        window.localStorage.setItem('caller', params);
    },
  },
  actions: {
    cleanup({ commit }) {
      commit('SET_CALLER', undefined);
      commit('SET_CASHBOOKS', { cashbooks: [] });
      commit('SET_ACTIVE_CASHBOOK_DETAILS', {});
      commit('SET_CASHBOOKENTRIES_FOR_SELECTED_MONTH', []);
      const now: Date = new Date();
      commit('SET_SELECTED_YEAR', now.getFullYear());
      commit('SET_SELECTED_MONTH', now.getMonth());
      commit('SET_SELECTED_DAY', now.getDate());
    }
  },
  modules: {
    cashbook,
    transactionDialog,
    session,
    error,
    dayClosing,
    countProtocol,
  },
});
