import { AxiosResponse } from 'axios';
import moment from 'moment-mini';
import { Mutex } from 'async-mutex';
import Repository, { CancelToken } from '@/assets/service/Repository';
import store from '@/store/store';
import CashbookEntriesResponseEntry from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
import Payment from '@/assets/service/parameterTypes/cashbook/payments/Payment';
import Taxes from '@/assets/service/parameterTypes/cashbook/taxes/Taxes';
import CashbookEnumerationsWrapper, { EnumLabel } from '@/assets/service/responseTypes/cashbook/cashbookEnumerations/CashbookEnumerations';
import CashbookEnumerationsEntry from '@/assets/service/responseTypes/cashbook/cashbookEnumerations/CashbookEnumerationsEntry';
import { REQUEST_DATEFORMAT } from '@/assets/utils/DateUtil';
import CashbookEntryDetailResponseWrapper, { CashbookEntryDetailResponse } from '@/assets/service/responseTypes/cashbook/cashbookEntryDetails/CashbookEntryDetailResponse';
import DocumentHeader from '@/assets/models/DocumentHeader';
import CashbookEntryTemplate from "@/assets/models/CashbookEntryTemplate";
import CashbookListResponse from "@/assets/service/responseTypes/cashbook/cashbookList/CashbookListResponse";
import CashbookDetailResponse from "@/assets/service/responseTypes/cashbook/cashbookDetail/CashbookDetailResponse";
import CashbookTaxRatesResponseWrapper from "@/assets/service/responseTypes/cashbook/cashbookTaxRates/CashbookTaxRatesResponse";
import CashbookEntryResponseTypeWrapper from "@/assets/service/responseTypes/cashbook/cashbookInsertionEntryResponseType/CashbookEntryResponseType";
import CashbookImageURLResponseWrapper from "@/assets/service/responseTypes/cashbook/cashbookImageURL/CashbookImageURLResponse";
import CashbookDetailEntriesResponseWrapper from "@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponse";
import CashbookEntryTemplateResponseWrapper from "@/assets/service/responseTypes/cashbook/cashbookEntryTemplatesResponse/CashbookEntryTemplateResponse";
import CashbookEntryTemplateModificationResponseWrapper from "@/assets/service/responseTypes/cashbook/cashbookEntryTemplateModificationResponse/CashbookEntryTemplateModificationResponse";
import ServiceErrors from "@/assets/service/responseTypes/ServiceErrors";
import UserPermissionsResponseWrapper from "@/assets/service/responseTypes/cashbook/cashbookUserPermissions/UserPermissionsResponse";
import ErrorConstants from "@/assets/constants/ErrorConstants";
import CashbookBillingDefaultResponseWrapper, { CashbookBillingState } from "@/assets/service/responseTypes/cashbook/cashbookBilling/CashbookBillingDefaultResponse";
import CountProtocolResponseWrapper, { CountProtocolListEntry, CountProtocolRequestResponse, PaymentDivision } from "@/assets/service/responseTypes/cashbook/countProtocol/CountProtocol";
import ReportURLResponse from "@/assets/service/responseTypes/cashbook/report/ReportURL";
import CashbookReportsResponse from "@/assets/service/responseTypes/report/CashbookReportsResponse";
import GetLicenceInformationResponse from "@/assets/service/responseTypes/about/GetLicenseInformationResponse";
import GetLicenceInformationResponseEntry from "@/assets/service/responseTypes/about/GetLicenseInformationResponseEntry";
import TransferOption from '@/assets/models/TransferOption';
import CashbookTransferResponse from '@/assets/service/responseTypes/cashbook/cashbookTransfer/CashbookTransferResponse';
import IsEmptyCashbookResponse from "@/assets/service/responseTypes/cashbook/openingBooking/IsEmptyCashbookResponse";
import InsertOpeningBookingResponse from "@/assets/service/responseTypes/cashbook/openingBooking/InsertOpeningBookingResponse";
import Cashbook from '@/assets/models/Cashbook';
import { CashbookImageRequest } from '@/assets/models/CashbookImageRequest';

export default class Webservice {
  public static AppId: string = 'APRO.KASSENBUCH';

  // General Endpoints
  private static readonly logoutEndpoint: string = '/logoff';
  private static readonly fetchUserEndpoint: string = '/Tbl_Benutzer';
  private static readonly keepAliveEndpoint: string = '/KeepAlive';
  private static readonly getUserPermissionsEndpoint: string = '/get_Kassenbuch_Benutzerrechte';
  private static readonly getLicenceInformationEndpoint: string='/getLicenceInformation';

  // Cashbook Endpoints
  private static readonly getAvailablePaymentsEndpoint: string = '/Tbl_KassenbuchZahlungsmittel';
  private static readonly getAvailableTaxRatesEndpoint: string = '/Tbl_KassenbuchSteuersaetze';
  private static readonly cashbookEnumerationsEndpoint: string = '/Lst_KassenbuchEnumerations';

  // Cashbook Entry Endpoints
  private static readonly getIsEmptyCashbookEndpoint: string = '/get_Empty';
  private static readonly insertOpeningBookingEndpoint: string = '/insert_Eroeffnungsbuchung';
  private static readonly getCashbookEntriesEndpoint: string = '/Tbl_KassenbuchEintraege';
  private static readonly getCashbookEntryDetailsEndpoint: string = '/get_KassenbuchEintrag';
  private static readonly insertCashbookEntryEndpoint: string = '/insert_KassenbuchEintrag';
  private static readonly changeCashbookEntryEndpoint: string = '/change_KassenbuchEintrag';
  private static readonly deleteCashbookEntryEndpoint: string = '/delete_KassenbuchEintrag';
  private static readonly cancelCashbookEntryEndpoint: string = '/void_KassenbuchEintrag';
  private static readonly getImageURLCashbookEntryImageEndpoint: string = '/get_ImageURL_KassenbuchEintrag';
  private static readonly getOpenEntriesEndpoint: string = '/get_Offene_Eintraege';
  private static readonly insertTransferEntriesEndpoint: string = '/insert_Uebertrag';
  private static readonly countEntriesEndpoint = '/count_Eintraege';
  private static readonly getReportURLEndpoint = '/get_URL_ReportKassenbuchAbrechnung';

  // Cashbook Entry Template Endpoints
  private static readonly getCashbookEntryTemplatesEndpoint: string = '/Tbl_KassenbuchEintragVorlagen';
  private static readonly insertCashbookEntryTemplateEndpoint: string = '/insert_KassenbuchEintragVorlage';
  private static readonly changeCashbookEntryTemplateEndpoint: string = '/change_KassenbuchEintragVorlage';
  private static readonly deleteCashbookEntryTemplateEndpoint: string = '/delete_KassenbuchEintragVorlage';

  // Day End Closing Endpoints
  private static readonly getCashbookBillingStateEndpoint: string = '/get_Kassenbuch_Abrechnung_Status';
  private static readonly startCashbookBillingEndpoint: string = '/start_Kassenbuch_Abrechnung';
  private static readonly processCashbookBillingEndpoint: string = '/process_Kassenbuch_Abrechnung';
  private static readonly commitCashbookClosingEndpoint: string = '/commit_Kassenbuch_Abrechnung';
  private static readonly rollbackCashbookBillingEndpoint: string = '/rollback_Kassenbuch_Abrechnung';
  private static readonly isCashbookBillingStartableEndpoint: string = '/Abrechnung_startable';
  private static readonly isCashbookBillingProcessableEndpoint: string = '/Abrechnung_processable';
  private static readonly isCashbookBillingCommittableEndpoint: string = '/Abrechnung_commitable';
  private static readonly getCashbookBillingConfigurationEndpoint: string = '/get_Kassenbuch_Tagesabschlusskonfiguration';
  private static readonly getRegisterProgressEndpoint: string = '/get_Kassenbericht_Fortschritt';
  private static readonly getCashbookBillingDateEndpoint: string = '/get_Kassenbuch_Abrechnung_Datum';

  // Count Protocol Endpoints
  private static readonly getCashbookPaymentDivisionEndpoint: string = '/get_Kassenbuch_Zahlungsmittel_Stueckelung';
  private static readonly insertCashCountProtocolEndpoint: string = '/upsert_Zaehlprotokoll';
  private static readonly getCashCountProtocolEndpoint: string = '/get_Zaehlprotokoll';
  private static readonly listCashbookEntryCashCountProtocolsEndpoint: string = '/Lst_KassenbuchEintrag_Zaehlprotokolle';
  private static readonly listUncommittedCashCountProtocolsEndpoint: string = '/Lst_uncommitted_Zaehlprotokolle';
  private static readonly commitCashCountProtocolsEndpoint: string = '/commit_Zaehlprotokolle';
  private static readonly rollbackCashCountProtocolsEndpoint: string = '/rollback_Zaehlprotokolle';
  private static readonly ignoreRegisterProtocolErrorEndpoint: string = '/ignore_Kassenbericht_Error';
  private static readonly getCashbookProtocolsEndpoint: string = '/Tbl_KassenbuchAbrechnungen';
  private static readonly processCashbookTransferEndpoint: string = '/process_Kassenbuch_Umbuchung';

    private static readonly get_URL_ReportKassenbuchJournalEndpoint: string = '/get_URL_ReportKassenbuchJournal';

  // Mutex to Cancel Requests
  private static cancelGetCashbookEntries: () => void;
  private static getCashbookMutex: Mutex = new Mutex();
  private static getPermissionMutex: Mutex = new Mutex();
  private static getCashbookBillingStateMutex: Mutex = new Mutex();
  private static cashbookBillingStateQueueCounter: number = 0;
  private static cashbookBillingStateStored: void | AxiosResponse<CashbookBillingDefaultResponseWrapper> = undefined;

  /*****************************************************************
   ************************ General Requests ***********************
   *****************************************************************/

  public static async init(): Promise<void> {
    const now = new Date();
    store.commit('SET_SELECTED_DAY', now.getDate());
    store.commit('SET_SELECTED_MONTH', now.getMonth());
    store.commit('SET_SELECTED_YEAR', now.getFullYear());

    const cashbooks = await this.getCashbooks();
    if (!cashbooks.find((cashbook) => cashbook.NUM === store.getters.selectedCashbook.NUM)) {
      // user does not have access to cashbook selected by previous user
      store.commit('SET_SELECTED_CASHBOOK', cashbooks[0] || {});
    }

    this.getCashbookInfo();
    this.getTaxRates();
    this.getEnumerations(EnumLabel.TYP);
    this.getEnumerations(EnumLabel.BILLING_STATE);
    this.getEnumerations(EnumLabel.REGISTER_STATE);
    this.getEnumerations(EnumLabel.COUNT_STATE);
    this.getEnumerations(EnumLabel.COUNT_TYPE);
    this.getEnumerations(EnumLabel.REPORT_TYPE);
    this.getEnumerations(EnumLabel.TRANSFER_TYPE);
  }

  public static getLicenseInformation(callbackSuccess: (response: GetLicenceInformationResponseEntry) => void) {
    Repository.post<GetLicenceInformationResponse>(store.getters.serviceUrl + Webservice.getLicenceInformationEndpoint).then((value) => {
        callbackSuccess(value.data.getLicenceInformation_response);
    }).catch((exception)=>{
      callbackSuccess(new GetLicenceInformationResponseEntry());
    });
  }

  /*****************************************************************
   *********************** Cashbook Requests ***********************
   *****************************************************************/

  public static async getCashbooks(): Promise<Cashbook[]> {
    try {
      const request = { SID: store.getters.authToken };
      const response = await Repository.post<CashbookListResponse>(store.getters.serviceUrl + '/Lst_Kassenbuch', request);
      const cashbooks = response.data?.Lst_Kassenbuch_response?.Lst_Kassenbuch;
      const serviceErrors = response.data?.ServiceErrors;
      if (serviceErrors) {
        this.preAnalyzeErrors(serviceErrors);
        return Promise.reject(serviceErrors);
      }
      if (cashbooks) {
        store.commit('SET_CASHBOOKS', { cashbooks });
        return cashbooks;
      }
      return [];
    } catch (e) {
      this.handleRealErrors(e);
      return Promise.reject(e);
    }
  }

  public static async getCashbookInfo(): Promise<void> {
    const cashbook = store.getters.selectedCashbook;
    if (!cashbook.NUM) {
      return;
    }
    try {
      const request = { SID: store.getters.authToken, KassenbuchID: cashbook.NUM };
      const response = await Repository.post<CashbookDetailResponse>(store.getters.serviceUrl + '/get_Kassenbuch', request);
      const serviceErrors = response.data?.get_Kassenbuch_response?.ServiceErrors;
      const responseData = response.data?.get_Kassenbuch_response;
      if (responseData?.ServiceErrors) {
        this.preAnalyzeErrors(serviceErrors);
      } else {
        store.commit('SET_ACTIVE_CASHBOOK_DETAILS', responseData.Kassenbuch);
        store.commit('SET_DAY_END_CLOSING_CONFIG', responseData.KonfigurationTagesabschluss);
        store.commit('SET_AVAILABLE_CASHBOOK_ENTRY_TEMPLATES', responseData.Tbl_KassenbuchEintragVorlagen);
        store.commit('SET_AVAILABLE_CASHBOOK_PAYMENTS', responseData.Tbl_KassenbuchZahlungsmittel);
        store.commit('SET_CASHBOOK_ACCOUNTS', responseData.Tbl_KassenbuchKontonummern.sort((a, b) => a.KONTO - b.KONTO));
        store.commit('SET_USER_PERMISSIONS', {
          userId: store.getters.userId,
          cashbookID: cashbook.NUM,
          permissions: responseData.Berechtigungen
        });

        if (!moment(responseData.Kassenbuch.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT)
            .isSameOrBefore(new Date(store.getters.selectedYear, store.getters.selectedMonth, store.getters.selectedDay), 'day')) {
          const now = new Date();
          store.commit('SET_SELECTED_YEAR', now.getFullYear());
          store.commit('SET_SELECTED_MONTH', now.getMonth());
          store.commit('SET_SELECTED_DAY', now.getDate());
        }
        Webservice.getCashbookBillingDate();
      }
    } catch (e) {
      this.handleRealErrors(e);
    }
  }

  public static getEnumerations(enumLabel: EnumLabel): void {
    Repository.post<CashbookEnumerationsWrapper>(store.getters.serviceUrl + Webservice.cashbookEnumerationsEndpoint,
      {
        EnumLabel: enumLabel,
      }).then((value) => {
      if (value.data.Tbl_KassenbuchEnumerations_response.ServiceErrors === undefined) {
        const enums: CashbookEnumerationsEntry[] = value.data
          .Tbl_KassenbuchEnumerations_response.Lst_Enumeration;
        switch (enumLabel) {
          case EnumLabel.TYP:
            store.commit('SET_CASHBOOKENTRY_TYPES', enums);
            break;
          case EnumLabel.PERSISTENZ:
            store.commit('SET_CASHBOOKENTRY_PERSISTENZ', enums);
            break;
          case EnumLabel.BUCHUNGSART:
            break;
          case EnumLabel.BILLING_STATE:
            store.commit('SET_CASHBOOK_BILLING_STATES', enums);
            break;
          case EnumLabel.REGISTER_STATE:
            store.commit('SET_CASHBOOK_REGISTER_STATES', enums);
            break;
          case EnumLabel.COUNT_STATE:
            store.commit('SET_CASHBOOK_COUNT_STATES', enums);
            break;
          case EnumLabel.COUNT_TYPE:
            store.commit('SET_CASH_COUNT_TYPES', enums);
            break;
          case EnumLabel.REPORT_TYPE:
            store.commit('SET_REPORT_TYPES', enums);
            break;
          case EnumLabel.TRANSFER_TYPE:
            store.commit('SET_TRANSFER_TYPES', enums);
            break;
          default:
            break;
        }
      } else {
        this.preAnalyzeErrors(value.data.Tbl_KassenbuchEnumerations_response.ServiceErrors);
      }
    }).catch(reason => this.handleRealErrors(reason));
  }

  public static async getTaxRates(date?: Date): Promise<void> {
    const requestData = {
      KassenbuchID: store.getters.selectedCashbook.NUM,
      Datum: moment(date).format(REQUEST_DATEFORMAT),
      SID: store.getters.authToken,
    };
    return Repository.post<CashbookTaxRatesResponseWrapper>(store.getters.serviceUrl + Webservice.getAvailableTaxRatesEndpoint, requestData)
      .then((value) => {
        if (value.data.Tbl_KassenbuchSteuersaetze_response.ServiceErrors === undefined) {
          store.commit('SET_TAXRATES', value.data.Tbl_KassenbuchSteuersaetze_response.Tbl_KassenbuchSteuersaetze);
        } else {
          this.preAnalyzeErrors(value.data.Tbl_KassenbuchSteuersaetze_response.ServiceErrors);
        }
      })
      .catch(reason => this.handleRealErrors(reason));
  }

  public static async getUserPermissionsAsync(cashbookId:number):Promise<any> {
      const result = await Repository.post<UserPermissionsResponseWrapper>(
          store.getters.serviceUrl + Webservice.getUserPermissionsEndpoint,
          {
              SID: store.getters.authToken,
              KassenbuchID: cashbookId,
          });
      if(result?.data?.get_Kassenbuch_Benutzerrechte_response?.Berechtigungen){
          return result?.data?.get_Kassenbuch_Benutzerrechte_response?.Berechtigungen;
      }

      return {};
  }
  public static getUserPermissions() {
    this.getUserPermissionsIntern().catch(reason => this.handleRealErrors(reason)).then(r => {
    });
  }

  private static async getUserPermissionsIntern() {
    const release = await Webservice.getPermissionMutex.acquire();
    try {
      if ((store.getters.userPermissions === undefined
        || store.getters.userPermissions.userId !== store.getters.userId
        || store.getters.userPermissions.cashbookID !== store.getters.selectedCashbook.NUM)
        && store.getters.cashbooks.filter(value => store.getters.selectedCashbook.NUM === value.NUM).length > 0) {
        const permissions: UserPermissionsResponseWrapper = (await Repository.post<UserPermissionsResponseWrapper>(
            store.getters.serviceUrl + Webservice.getUserPermissionsEndpoint,
          {
            SID: store.getters.authToken,
            KassenbuchID: store.getters.selectedCashbook.NUM,
          })).data;

        if (permissions.get_Kassenbuch_Benutzerrechte_response.ServiceErrors === undefined) {
          store.commit('SET_USER_PERMISSIONS', {
            userId: store.getters.userId,
            cashbookID: store.getters.selectedCashbook.NUM,
            permissions: permissions.get_Kassenbuch_Benutzerrechte_response.Berechtigungen
          })
        } else {
          this.preAnalyzeErrors(permissions.get_Kassenbuch_Benutzerrechte_response.ServiceErrors);
        }
      }
    } finally {
      release();
    }
  }

  /*****************************************************************
   ******************** Cashbook Entry Requests ********************
   *****************************************************************/

  public static insertCashbookEntry(head: DocumentHeader,
                      payments: Payment[],
                      taxes: Taxes[],
                      callbackSuccess: (response: DocumentHeader) => void,
                      callbackFail: (reason: ServiceErrors[]) => void,
                      callbackFinally: () => void,
                      reason?: string): void {
    Repository.post<CashbookEntryResponseTypeWrapper>(store.getters.serviceUrl + Webservice.insertCashbookEntryEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Grund: reason,
        Belegkopf: head,
        Lst_Zahlungen: payments,
        Lst_Steuern: taxes,
      }).then((value) => {
      if (value.data.insert_KassenbuchEintrag_response
        && value.data.insert_KassenbuchEintrag_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.insert_KassenbuchEintrag_response.ServiceErrors);
        callbackFail(value.data.insert_KassenbuchEintrag_response.ServiceErrors);
      } else {
        callbackSuccess(value.data.insert_KassenbuchEintrag_response.Belegkopf);
      }
    }).catch(reason => this.handleRealErrors(reason)).finally(callbackFinally);
  }

  public static changeCashbookEntry(head: DocumentHeader,
                      reason: string,
                      payments: Payment[],
                      taxes: Taxes[],
                      callbackSuccess: () => void,
                      callbackFail: (reason: ServiceErrors[]) => void,
                      callbackFinally: () => void): void {
    Repository.post<CashbookEntryResponseTypeWrapper>(store.getters.serviceUrl + Webservice.changeCashbookEntryEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Grund: reason,
        Belegkopf: head,
        Lst_Zahlungen: payments,
        Lst_Steuern: taxes,
      }).then((value) => {
      if (value.data.change_KassenbuchEintrag_response
        && value.data.change_KassenbuchEintrag_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.change_KassenbuchEintrag_response.ServiceErrors);
        callbackFail(value.data.change_KassenbuchEintrag_response.ServiceErrors);
      } else {
        callbackSuccess();
      }
    }).catch(reason => this.handleRealErrors(reason)).finally(callbackFinally);
  }

  public static deleteCashbookEntry(head: CashbookEntriesResponseEntry,
                      reason: string,
                      callbackSuccess: () => void,
                      callbackFail: (reason: ServiceErrors[]) => void,
                      callbackFinally: () => void): void {
    this.genericCashbookEntryDeletion(Webservice.deleteCashbookEntryEndpoint, head, reason, callbackSuccess, callbackFail, callbackFinally);
  }

  public static cancelCashbookEntry(head: CashbookEntriesResponseEntry,
                      reason: string,
                      callbackSuccess: () => void,
                      callbackFail: (reason: ServiceErrors[]) => void,
                      callbackFinally: () => void): void {
    this.genericCashbookEntryDeletion(Webservice.cancelCashbookEntryEndpoint, head, reason, callbackSuccess, callbackFail, callbackFinally);
  }

  public static genericCashbookEntryDeletion(endpoint: string,
                               head: CashbookEntriesResponseEntry,
                               reason: string,
                               callbackSuccess: () => void,
                               callbackFail: (reason: ServiceErrors[]) => void,
                               callbackFinally: () => void): void {
    Repository.post<CashbookEntryResponseTypeWrapper>(store.getters.serviceUrl + endpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: head.KASSENBUCH,
        Datum: head.DATUM,
        LaufendeNummer: head.LFDNUMMER,
        Grund: reason,
      }).then((value) => {
      if (value.data.delete_KassenbuchEintrag_response
        && value.data.delete_KassenbuchEintrag_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.delete_KassenbuchEintrag_response.ServiceErrors);
        callbackFail(value.data.delete_KassenbuchEintrag_response.ServiceErrors);
      } else if (value.data.void_KassenbuchEintrag_response
        && value.data.void_KassenbuchEintrag_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.void_KassenbuchEintrag_response.ServiceErrors);
        callbackFail(value.data.void_KassenbuchEintrag_response.ServiceErrors);
      } else {
        callbackSuccess();
      }
    }).catch(reason => this.handleRealErrors(reason)).finally(callbackFinally);
  }

  public static async uploadCashbookEntryImage(request: CashbookImageRequest): Promise<void> {
    const maxFileSizeMb: number = parseFloat(process.env.VUE_MAX_UPLOAD_SIZE_MB!);
    if (request.file.size > maxFileSizeMb * 1024 * 1024) {
      store.commit('SET_ERROR', ErrorConstants.MAX_UPLOAD_SIZE);
      return;
    }
    const formData: FormData = new FormData();
    formData.append('SID', store.getters.authToken || '');
    formData.append('KassenbuchID', request.cashbookId);
    formData.append('LaufendeNummer', request.entryId.toString());
    formData.append('Datum', request.date || moment(new Date()).format(REQUEST_DATEFORMAT));
    formData.append('Image', request.file);
    formData.append('ImageFormat', request.file.type.split('/')[1]);
      //
      // const jdata = {
      //     'SID': store.getters.authToken || '',
      //     'KassenbuchID': request.cashbookId,
      //     'LaufendeNummer': request.entryId.toString(),
      //     'Datum': request.date || moment(new Date()).format(REQUEST_DATEFORMAT),
      //     'Image': request.file,
      //     'ImageFormat': request.file.type.split('/')[1]
      // }
    const headers = {
        'Content-Type': 'multipart/form-data',
      Accept: 'application/json',

    };


    let response;
    try {
      response = await Repository.post<CashbookImageURLResponseWrapper>(store.getters.serviceUrl + '/uploadImage_KassenbuchEintrag', formData, { headers });
    } catch(e) {
      this.handleRealErrors(e);
      return;
    }
    const data = response.data.uploadImage_KassenbuchEintrag_response;
    if (data?.ServiceErrors) {
      this.preAnalyzeErrors(data.ServiceErrors);
      throw data.ServiceErrors;
    }
  }

  public static getImageURLCashbookEntryImage(lfd: number, date: string,
                                callbackSuccess: (url: string) => void,
                                callbackFail: (reason: any) => void): void {
    Repository.post<CashbookImageURLResponseWrapper>(store.getters.serviceUrl + Webservice.getImageURLCashbookEntryImageEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Datum: date,
        LaufendeNummer: lfd,
      }).then((value) => {
      if (value.data.get_ImageURL_KassenbuchEintrag_response
        && value.data.get_ImageURL_KassenbuchEintrag_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.get_ImageURL_KassenbuchEintrag_response.ServiceErrors);
        callbackFail(value.data.get_ImageURL_KassenbuchEintrag_response.ServiceErrors);
      } else {
        // callbackSuccess(value.data.get_ImageURL_KassenbuchEintrag_response.ImageURL);
        Repository.get(store.getters.serviceUrl + value.data.get_ImageURL_KassenbuchEintrag_response.ImageURL, {
          responseType: 'blob',
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            callbackSuccess(url);
          }).catch(reason => this.handleRealErrors(reason));
      }
    }).catch(reason => this.handleRealErrors(reason));
  }

  public static getCashbookEntries(month: number, year: number, callbackFinally: () => void): void {
    if (typeof Webservice.cancelGetCashbookEntries === 'function') {
      Webservice.cancelGetCashbookEntries();
      store.commit('SET_CASHBOOKENTRY_DETAILS',
        new Map<CashbookEntriesResponseEntry, CashbookEntryDetailResponse>());
    }
    const cancelToken = new CancelToken((cancel) => {
      Webservice.cancelGetCashbookEntries = cancel;
    });
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

      Repository.post<CashbookDetailEntriesResponseWrapper>(store.getters.serviceUrl + Webservice.getCashbookEntriesEndpoint,
        {
          SID: store.getters.authToken,
          KassenbuchID: store.getters.selectedCashbook.NUM,
          DatumVon: moment(startDate).format(REQUEST_DATEFORMAT),
          DatumBis: moment(endDate).format(REQUEST_DATEFORMAT),
          Deleted: 1
        }, { cancelToken }).then((value) => {
        if (value.data.Tbl_KassenbuchEintraege_response.ServiceErrors === undefined) {
          store.commit('SET_CASHBOOKENTRIES_FOR_SELECTED_MONTH', value.data.Tbl_KassenbuchEintraege_response.Tbl_KassenbuchEintraege);
        } else {
          this.preAnalyzeErrors(value.data.Tbl_KassenbuchEintraege_response.ServiceErrors);
        }
        callbackFinally();
      });
  }

  public static reloadCashbookEntries() {
    store.commit('SET_LOAD_TABLE_DATA', true);
    this.getCashbookEntries(store.getters.selectedMonth, store.getters.selectedYear, () => {
      store.commit('SET_LOAD_TABLE_DATA', false);
      store.commit('SET_LOADING_ENTRY', null);
    });
  }

  public static getCashbookEntryDetails(entry: CashbookEntriesResponseEntry):
    Promise<AxiosResponse<CashbookEntryDetailResponseWrapper>> {
    return Repository.post<CashbookEntryDetailResponseWrapper>(store.getters.serviceUrl + Webservice.getCashbookEntryDetailsEndpoint,
      {
        SID: store.getters.authToken,
        Datum: entry.DATUM,
        KassenbuchID: entry.KASSENBUCH,
        LaufendeNummer: entry.LFDNUMMER,
      });
  }

  public static async getCachedCashbookEntryDetails(entry: CashbookEntriesResponseEntry):
    Promise<CashbookEntryDetailResponse> {
    const release = await Webservice.getCashbookMutex.acquire();
    try {
      const cashbookEntryDetailsMap: Map<CashbookEntriesResponseEntry, CashbookEntryDetailResponse> = store.getters.cashbookEntryDetails;
      if (!cashbookEntryDetailsMap.has(entry)) {
        cashbookEntryDetailsMap.set(entry, await (await this.getCashbookEntryDetails(entry))
          .data.get_KassenbuchEintrag_response);
        store.commit('SET_CASHBOOKENTRY_DETAILS', cashbookEntryDetailsMap);
      }
      return new Promise<CashbookEntryDetailResponse>(
        resolve => resolve(cashbookEntryDetailsMap.get(entry)!),
      );
    } finally {
      release();
    }
  }

  public static getOpenEntries(callbackSuccess: (openEntryCount: number) => void): void {
    Repository.post<CashbookDetailEntriesResponseWrapper>(store.getters.serviceUrl + Webservice.getOpenEntriesEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM
      }).then(value => {
      if (value.data.get_Offene_Eintraege_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.get_Offene_Eintraege_response.ServiceErrors);
      } else {
        callbackSuccess(value.data.get_Offene_Eintraege_response.OffeneEintraege);
      }
    })
  }

  public static insertTransferEntries(callbackSuccess: () => void) {
    Repository.post<CashbookDetailEntriesResponseWrapper>(store.getters.serviceUrl + Webservice.insertTransferEntriesEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM
      }).then(value => {
      if (value.data.insert_Uebertrag_response?.ServiceErrors) {
        this.preAnalyzeErrors(value.data.insert_Uebertrag_response.ServiceErrors);
      } else {
        callbackSuccess();
      }
    });
  }

  public static countEntries(onlyOpenEntries: boolean, callbackSuccess: (entryCount: number) => void) {
    const num = store.getters.selectedCashbook.NUM;
    if (num) {
      Repository.post<CashbookDetailEntriesResponseWrapper>(store.getters.serviceUrl + Webservice.countEntriesEndpoint,
        {
          SID: store.getters.authToken,
          KassenbuchID: num,
          Datum: moment(new Date()).format(REQUEST_DATEFORMAT),
          nurOffeneEintraege: onlyOpenEntries
        }).then(value => {
        if (value.data.count_Eintraege_response.ServiceErrors) {
          this.preAnalyzeErrors(value.data.count_Eintraege_response.ServiceErrors);
        } else {
          callbackSuccess(value.data.count_Eintraege_response.Eintraege);
        }
      });
    }
  }

  public static getReportURL(date: string, callbackSuccess: (val: string) => void, callBackFinally: () => void) {
    Repository.post<ReportURLResponse>(store.getters.serviceUrl + Webservice.getReportURLEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Datum: date
      }).then(value => {
      if (value && value.data.get_URL_ReportKassenbuchAbrechnung_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.get_URL_ReportKassenbuchAbrechnung_response.ServiceErrors);
      } else {
        callbackSuccess(value.data.get_URL_ReportKassenbuchAbrechnung_response.ImageURL);
      }
    }).finally(callBackFinally);
  }

  /*****************************************************************
   *************** Cashbook Entry Template Requests ****************
   *****************************************************************/

  public static getCashbookEntryTemplates(callbackSuccess?: () => void): void {
    Repository.post<CashbookEntryTemplateResponseWrapper>(store.getters.serviceUrl + Webservice.getCashbookEntryTemplatesEndpoint,
      {
        KassenbuchID: store.getters.selectedCashbook.NUM,
        SID: store.getters.authToken,
      }).then((value) => {
      if (!value.data.Tbl_KassenbuchEintragVorlagen_response.ServiceErrors) {
        store.commit('SET_AVAILABLE_CASHBOOK_ENTRY_TEMPLATES',
          value.data.Tbl_KassenbuchEintragVorlagen_response.Tbl_KassenbuchEintragVorlagen);
        if (callbackSuccess) {
          callbackSuccess();
        }
      } else {
        this.preAnalyzeErrors(value.data.Tbl_KassenbuchEintragVorlagen_response.ServiceErrors);
      }
    }).catch(reason => this.handleRealErrors(reason));
  }

  public static insertCashbookEntryTemplate(template: CashbookEntryTemplate,
                              callbackSuccess: (val: CashbookEntryTemplate) => void, callbackFinally: () => void): void {
    this.genericCashbookEntryTemplateMethod(template,
      (val: CashbookEntryTemplateModificationResponseWrapper) => {
        if (val.insert_KassenbuchEintragVorlage_response.ServiceErrors) {
          this.preAnalyzeErrors(val.insert_KassenbuchEintragVorlage_response.ServiceErrors);
        } else {
          callbackSuccess(val.insert_KassenbuchEintragVorlage_response.Vorlage);
        }
      },
      callbackFinally, Webservice.insertCashbookEntryTemplateEndpoint);
  }

  public static changeCashbookEntryTemplate(template: CashbookEntryTemplate,
                              callbackSuccess: (val: CashbookEntryTemplate) => void, callbackFinally: () => void): void {
    this.genericCashbookEntryTemplateMethod(template,
      (val: CashbookEntryTemplateModificationResponseWrapper) => {
        if (val.change_KassenbuchEintragVorlage_response.ServiceErrors) {
          this.preAnalyzeErrors(val.change_KassenbuchEintragVorlage_response.ServiceErrors);
        } else {
          callbackSuccess(val.change_KassenbuchEintragVorlage_response.Vorlage);
        }
      },
      callbackFinally, Webservice.changeCashbookEntryTemplateEndpoint);
  }

  public static deleteCashbookEntryTemplate(templateNumber: number, callbackFinally: () => void): void {
    Repository.post<CashbookEntryTemplateModificationResponseWrapper>(store.getters.serviceUrl + Webservice.deleteCashbookEntryTemplateEndpoint,
      {
        KassenbuchID: store.getters.selectedCashbook.NUM,
        SID: store.getters.authToken,
        VorlageNummer: templateNumber,
      }).then((value) => {
      if (!value.data.delete_KassenbuchEintragVorlage_response) {
        this.getCashbookEntryTemplates();
      }else {
        this.preAnalyzeErrors(value.data.delete_KassenbuchEintragVorlage_response.ServiceErrors);
      }
    }).catch(reason => this.handleRealErrors(reason)).finally(callbackFinally);
  }

  /*****************************************************************
   ******************* Cashbook Billing Requests *******************
   *****************************************************************/

  public static async getCashbookBillingStateAsync(): Promise<void | AxiosResponse<CashbookBillingDefaultResponseWrapper>> {
    await Webservice.getCashbookBillingStateMutex.runExclusive(() => Webservice.cashbookBillingStateQueueCounter += 1);
    const release = await Webservice.getCashbookBillingStateMutex.acquire();
    try {
      if (Webservice.cashbookBillingStateQueueCounter > 1
        && Webservice.cashbookBillingStateStored !== undefined) {
        return Webservice.cashbookBillingStateStored;
      }
      Webservice.cashbookBillingStateStored = await this.genericCashbookBillingOperation(Webservice.getCashbookBillingStateEndpoint);

      return await this.genericCashbookBillingOperation(Webservice.getCashbookBillingStateEndpoint);
    } finally {
      Webservice.cashbookBillingStateQueueCounter -= 1;
      release();
    }
  }

  public static getCashbookBillingState(callbackSuccess: (val: CashbookBillingState) => void): void {
    this.getCashbookBillingStateAsync().then(value => {
      if (value && (value.data.get_Kassenbuch_Abrechnung_Status_response.ServiceErrors === undefined
        || value.data.get_Kassenbuch_Abrechnung_Status_response.ServiceErrors === null)) {
        callbackSuccess(value.data.get_Kassenbuch_Abrechnung_Status_response);
      } else if (value && value.data.get_Kassenbuch_Abrechnung_Status_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.get_Kassenbuch_Abrechnung_Status_response.ServiceErrors);
      }
    });
  }

  public static getCashbookBillingConfiguration() {
    this.genericCashbookBillingOperation(Webservice.getCashbookBillingConfigurationEndpoint).then((value) => {
      if (value && value.data.get_Kassenbuch_Tagesabschlusskonfiguration_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.get_Kassenbuch_Tagesabschlusskonfiguration_response.ServiceErrors);
      } else if (value) {
        store.commit('SET_DAY_END_CLOSING_CONFIG', value.data.get_Kassenbuch_Tagesabschlusskonfiguration_response.KonfigurationTagesabschluss);
      }
    });
  }

  public static genericCashbookBillingOperation(endpoint: string): Promise<void | AxiosResponse<CashbookBillingDefaultResponseWrapper>> {
    return Repository.post<CashbookBillingDefaultResponseWrapper>(store.getters.serviceUrl + endpoint, {
      SID: store.getters.authToken,
      KassenbuchID: store.getters.selectedCashbook.NUM
    }).catch(this.handleRealErrors);
  }

  public static getCashbookBillingDate() {
    if (store.getters.selectedCashbook && store.getters.selectedCashbook.NUM !== undefined
      && (store.getters.activeCashbookDetails
        && (store.getters.selectedCashbook.NUM === store.getters.activeCashbookDetails.NUMMER
          && store.getters.activeCashbookDetails.DATUMERSTEREINTRAG.length > 0))) {
      this.genericCashbookBillingOperation(Webservice.getCashbookBillingDateEndpoint)
        .then(value => {
          if (value && value.data.get_Kassenbuch_Abrechnung_Datum_response.ServiceErrors
            && value.data.get_Kassenbuch_Abrechnung_Datum_response.ServiceErrors[0].ERRORNUMBER !== 10300) {
            this.preAnalyzeErrors(value.data.get_Kassenbuch_Abrechnung_Datum_response.ServiceErrors);
          } else if (value) {
            store.commit('SET_LAST_CASHBOOK_BILLING_DATE', value.data.get_Kassenbuch_Abrechnung_Datum_response.LetzteAbrechnungDatum);
          }
        });
    }
  }

  /*****************************************************************
   ************************* Count Protocol ************************
   *****************************************************************/

  public static getCashbookPaymentDivision() {
    this.genericCashbookCountProtocolOperation(Webservice.getCashbookPaymentDivisionEndpoint)
      .then(value => {
        if (value) {
          if (value.data.get_Kassenbuch_Zahlungsmittel_Stueckelung_response.ServiceErrors) {
            this.preAnalyzeErrors(value.data.get_Kassenbuch_Zahlungsmittel_Stueckelung_response.ServiceErrors);
          } else {
            store.commit('SET_PAYMENT_DIVISIONS', value.data.get_Kassenbuch_Zahlungsmittel_Stueckelung_response);
          }
        }
      });
  }

  public static getCashbookPaymentDivisionByPayment(payment: number, callbackSuccess: (resp: PaymentDivision) => void): void {
    Repository.post<CountProtocolResponseWrapper>(store.getters.serviceUrl + Webservice.getCashbookPaymentDivisionEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Zahlungsmittel: payment
      }).then(value => {
      if (value) {
        if (value.data.get_Kassenbuch_Zahlungsmittel_Stueckelung_response.ServiceErrors) {
          this.preAnalyzeErrors(value.data.get_Kassenbuch_Zahlungsmittel_Stueckelung_response.ServiceErrors);
        } else {

          callbackSuccess(value.data.get_Kassenbuch_Zahlungsmittel_Stueckelung_response);
        }
      }
    });
  }

  public static getCashCountProtocol(lfdNr: number, drawer: string, art: number, payment: number, date: string, callbackSuccess: (resp: CountProtocolRequestResponse) => void, callbackFinally: () => void): void {
    Repository.post<CountProtocolResponseWrapper>(store.getters.serviceUrl + Webservice.getCashCountProtocolEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Datum: date,
        LaufendeNummer: lfdNr,
        Schublade: drawer,
        Art: art,
        Zahlungsmittel: payment
      }).then(value => {
      if (value) {
        if (value.data.get_Zaehlprotokoll_response.ServiceErrors) {
          this.preAnalyzeErrors(value.data.get_Zaehlprotokoll_response.ServiceErrors);
        } else {
          callbackSuccess(value.data.get_Zaehlprotokoll_response);
        }
      }
    }).finally(callbackFinally);
  }

  public static getCountProtocols(date: string, lfdNr: number, callbackSuccess: (countProtocols: CountProtocolListEntry[]) => void): void {
    Repository.post<CountProtocolResponseWrapper>(store.getters.serviceUrl + Webservice.listCashbookEntryCashCountProtocolsEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Datum: date,
        LaufendeNummer: lfdNr
      }).then(value => {
      if (value) {
        if (value.data.Lst_KassenbuchEintrag_Zaehlprotokolle_response.ServiceErrors) {
          this.preAnalyzeErrors(value.data.Lst_KassenbuchEintrag_Zaehlprotokolle_response.ServiceErrors);
        } else {
          callbackSuccess(value.data.Lst_KassenbuchEintrag_Zaehlprotokolle_response.Lst_Zaehlprotokolle);
        }
      }
    });
  }

  public static getUncommitedCountProtocols(type: number, callbackSuccess: (countProtocols: CountProtocolListEntry[]) => void): void {
    Repository.post<CountProtocolResponseWrapper>(store.getters.serviceUrl + Webservice.listUncommittedCashCountProtocolsEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Art: type
      }).then(value => {
      if (value) {
        if (value.data.Lst_uncommitted_Zaehlprotokolle_response.ServiceErrors) {
          this.preAnalyzeErrors(value.data.Lst_uncommitted_Zaehlprotokolle_response.ServiceErrors);
        } else {
          callbackSuccess(value.data.Lst_uncommitted_Zaehlprotokolle_response.Lst_Zaehlprotokolle);
        }
      }
    });
  }

  public static genericCashbookCountProtocolOperation(endpoint: string): Promise<void | AxiosResponse<CountProtocolResponseWrapper>> {
    return Repository.post<CountProtocolResponseWrapper>(store.getters.serviceUrl + endpoint, {
      SID: store.getters.authToken,
      KassenbuchID: store.getters.selectedCashbook.NUM
    }).catch(this.handleRealErrors);
  }

  public static getCashbookProtocols(callbackFinally: () => void) {
    Repository.post<CashbookReportsResponse>(store.getters.serviceUrl + Webservice.getCashbookProtocolsEndpoint,
      {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        DatumVon: moment(new Date(store.getters.selectedYear, store.getters.selectedMonth)).format(REQUEST_DATEFORMAT),
        DatumBis: moment(new Date(store.getters.selectedYear, store.getters.selectedMonth + 1, 0)).format(REQUEST_DATEFORMAT),
      }).then(value => {
      if (value.data.Tbl_KassenbuchAbrechnungen_response.ServiceErrors) {
        this.preAnalyzeErrors(value.data.Tbl_KassenbuchAbrechnungen_response.ServiceErrors);
      } else {
        store.commit('SET_REPORT_ENTRIES', value.data.Tbl_KassenbuchAbrechnungen_response.Tbl_KassenbuchAbrechnungen);
      }
      callbackFinally();
    })
  }

  /*****************************************************************
   ************************* Transfer ******************************
   *****************************************************************/

  public static async transfer(options: TransferOption): Promise<void> {
    const data = {
        SID: store.getters.authToken,
        KassenbuchID: store.getters.selectedCashbook.NUM,
        Umbuchung: options,
    };
    try {
      const response = await Repository.post<CashbookTransferResponse>(store.getters.serviceUrl + Webservice.processCashbookTransferEndpoint, data);
      if (response.data.process_Kassenbuch_Umbuchung_response && response.data.process_Kassenbuch_Umbuchung_response.ServiceErrors) {
        this.preAnalyzeErrors(response.data.process_Kassenbuch_Umbuchung_response.ServiceErrors);
        return await Promise.reject();
      }
      return await Promise.resolve();
    } catch(e) {
      this.handleRealErrors(e);
      return await Promise.reject();
    }
  }
  /*****************************************************************
   ************************* OpeningBooking ******************************
   *****************************************************************/
  public static async getURLReportKassenbuchJournal(userId: string, cashbookId: number, dateFrom:string, dateTo:string): Promise<string> {
      const data = {
          SID: store.getters.authToken,
          KassenbuchID: cashbookId,
          UserID: userId,
          DatumVon: dateFrom,
          DatumBis: dateTo
      };
      try {
          const res = await Repository.post(store.getters.serviceUrl + Webservice.get_URL_ReportKassenbuchJournalEndpoint, data);
          if (res.data.get_URL_ReportKassenbuchJournal_response && res.data.get_URL_ReportKassenbuchJournal_response.ServiceErrors) {
              this.preAnalyzeErrors(res.data.get_URL_ReportKassenbuchJournal_response.ServiceErrors);
              return await Promise.reject();
          }
          if (res.data.get_URL_ReportKassenbuchJournal_response && res.data.get_URL_ReportKassenbuchJournal_response.ImageURL) {
              return  res.data.get_URL_ReportKassenbuchJournal_response.ImageURL;
          }
          return "";
      } catch(e) {
          this.handleRealErrors(e);
          return "";
      }
  }
  public static async isEmptyCashbook(userId: string, cashbookId: number): Promise<boolean> {
    const data = {
      SID: store.getters.authToken,
      KassenbuchID: cashbookId,
    };
    try {
      const res = await Repository.post<IsEmptyCashbookResponse>(store.getters.serviceUrl + Webservice.getIsEmptyCashbookEndpoint, data);
      if (res.data.get_Empty_response && res.data.get_Empty_response.ServiceErrors) {
        this.preAnalyzeErrors(res.data.get_Empty_response.ServiceErrors);
        return false;

      }
      return res.data.get_Empty_response.is_Empty;
    } catch(e) {
      this.handleRealErrors(e);
      return false;
    }
  }
  public static async insertOpeningBooking(amount:number): Promise<void> {
    const data = {
      SID: store.getters.authToken,
      KassenbuchID: store.getters.selectedCashbook.NUM,
      Anfangsbestand: amount
    };
    try {
      const res= await Repository.post<InsertOpeningBookingResponse>(store.getters.serviceUrl + Webservice.insertOpeningBookingEndpoint, data);
      if (res.data.insert_Eroeffnungsbuchung_response && res.data.insert_Eroeffnungsbuchung_response.ServiceErrors) {
        this.preAnalyzeErrors(res.data.insert_Eroeffnungsbuchung_response.ServiceErrors);
        return Promise.reject();

      }
      return Promise.resolve();
    } catch(e) {
      this.handleRealErrors(e);
      return await Promise.reject();
    }
  }

  /*****************************************************************
   ************************* Error Handling ************************
   *****************************************************************/

  public static preAnalyzeErrors(serviceErrors: ServiceErrors[]) {
    if (serviceErrors) {
      if (serviceErrors.filter(value => value.ERRORDESCRIPTION.includes('Anmeldung erforderlich')).length > 0) {
        // location.reload();
      } else if (serviceErrors.filter(value => value.ERRORRECORDID.length === 0).length) {
        store.commit('SET_ERROR', serviceErrors.filter(value => value.ERRORRECORDID.length === 0)[0]);
      }
    }
  }

  private static handleRealErrors(reason) {
    if (reason.isAxiosError && reason.response === undefined) {
      store.commit('SET_ERROR', ErrorConstants.NO_CONNECTION);
    }
  }

  private static genericCashbookEntryTemplateMethod(template: CashbookEntryTemplate,
                                             callbackSuccess: (val: CashbookEntryTemplateModificationResponseWrapper) => void,
                                             callbackFinally: () => void, cashbookEntryTemplateEndpoint: string) {

    Repository.post<CashbookEntryTemplateModificationResponseWrapper>(store.getters.serviceUrl + cashbookEntryTemplateEndpoint,
      {
        KassenbuchID: store.getters.selectedCashbook.NUM,
        SID: store.getters.authToken,
        Vorlage: template
      }).then((value) => {
      if (!value.data.delete_KassenbuchEintragVorlage_response) {
        this.getCashbookEntryTemplates(() => {
          callbackSuccess(value.data);
        });
      }
    }).catch(reason => this.handleRealErrors(reason)).finally(callbackFinally);
  }
}
