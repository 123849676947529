
  import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";
  import store from "@/store/store";

  @Component
  export default class DatePicker extends Vue {
    @Model() model!: Date;

    dateString: string = '';

    @Prop() setDate!: (date: Date) => void;

    showDialog: boolean = false;

    get date(): string {
      if (this.dateString.length > 0) {
        return this.dateString;
      } else {
        this.dateString = moment(this.model).toISOString(true).substr(0, 10);
        return this.dateString;
      }
    }

    set date(date: string) {
      this.dateString = date;
    }

    get maxDate(): string {
      const selectedMonth = new Date(store.getters.selectedYear, store.getters.selectedMonth + 1, 0);
      let now = new Date();
      return moment(moment(now).isSameOrAfter(selectedMonth, 'day') ? selectedMonth : now).toISOString(true).substr(0, 10);
    }

    get minDate(): string {
      const firstEntry = moment(this.$store.getters.activeCashbookDetails.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT);
      const selectedMonth = new Date(store.getters.selectedYear, store.getters.selectedMonth, 1);
      return moment(this.$store.getters.activeCashbookDetails ? (firstEntry.isSameOrAfter(selectedMonth, 'day') ? firstEntry : selectedMonth) :
        new Date()).toISOString(true).substr(0, 10);
    }

    @Watch("showDialog")
    onClose() {
      this.dateString = moment(this.model).toISOString(true).substr(0, 10);
    }

    saveDate(dateString: string): void {
      this.setDate(new Date(dateString));
      this.showDialog = false;
    }
  }
