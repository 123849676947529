import { Prop, Vue } from 'vue-property-decorator';
import { CountProtocolListEntry } from "@/assets/service/responseTypes/cashbook/countProtocol/CountProtocol";
import Webservice from '@/assets/service/Webservice';

export default class StepGeneric extends Vue {
  @Prop() next!: () => void;

  @Prop() toggleRollbackDialog!: () => void;

  @Prop() setLoading!: (value: boolean) => void;

  @Prop() step!: number;

  countProtocols: CountProtocolListEntry[] = [];

  loading: boolean = false;

  processable: boolean = false;

  get stepComputed(): number {
    return this.step;
  }

  get aboutDrawers(): boolean {
    return [1, 3, 4].includes(this.stepComputed);
  }

  get drawerCount(): number | number[] {
    if (this.aboutDrawers) {
      return this.$store.getters.dayEndClosingConfig.ANZSCHUBLADEN;
    }
    const array: number[] = [];
    this.$store.getters.dayEndClosingConfig.ZAEHLABLIEFZM.forEach((entry: number) => {
      if (!array.includes(entry) && ((this.$store.getters.dayEndClosingConfig.ZAEHLABLIEF === 0 && entry > 0)
        || (this.$store.getters.dayEndClosingConfig.ZAEHLABLIEF === 1 && entry >= 0))) {
        array.push(entry);
      }
    });
    return array.sort((a, b) => a - b);
  }

  get stepFinished(): boolean {
    return this.countProtocols.length === (typeof this.drawerCount === 'number' ? this.drawerCount : this.drawerCount.length);
  }

  finishStep() {
    this.reload();
    this.next();
  }

  reload() {
    Webservice.getCashbookBillingState((val) => {
      this.$store.commit('SET_CASHBOOK_BILLING_STATE', val);
      // @ts-ignore a fix causes an endless redirect loop
      if (val.AbrechnungStatus === 0 || val.AbrechnungStatus === 5) {
        this.$router.push('/');
      }
    });
    Webservice.getCashbookBillingConfiguration();
  }

  checkProcessable(): void {
    this.reloadDrawers();
  }

  reloadDrawers() {
    this.setLoading(true);
    Webservice.getUncommitedCountProtocols(this.stepComputed, (countProtocols) => {
      this.countProtocols = countProtocols;
      this.setLoading(false);
    });
  }
}
