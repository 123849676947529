<template>
  <v-layout>
    <router-view/>
  </v-layout>
</template>

<script>
  export default {
    name: 'CashbookRoot',
  };
</script>

<style scoped>

</style>
