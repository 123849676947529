import ResultWrapper from "@/assets/service/responseTypes/ServiceResults";

export default class GetLicenceInformationResponseEntry extends  ResultWrapper{
  Product!: string;
  SerialNumber!: string;
  Company!: string;
  WebCopies!: string;
  WebCopiesInUse!: string;
  Optional!: string;
  ExpiryDate!: string;
}


