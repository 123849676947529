export default class PackageInfo {
private static instance: PackageInfo;
private constructor() {
}
  public static getInstance():PackageInfo {
    if (!PackageInfo.instance) {
      const config = require('@/../package.json');
      PackageInfo.instance = <PackageInfo>config;
    }
    return PackageInfo.instance;
  }
  name: string="";
  version: string="0.0.0";
  private: boolean=false;
  scripts: any;
  dependencies: any;
  devDependencies: any;


  getText(obj:any):string{
    return JSON.stringify(obj);
  }
}
