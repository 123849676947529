
  import { Component, Watch } from 'vue-property-decorator';
  import Report from "@/views/report/Report";
  import DesktopMonthPicker
    from "@/components/general/genericComponents/dateDrivenComponents/DesktopMonthPicker.vue";
  import { reportTableHeader } from "@/assets/config/TableHeaders";
  import CashbookInfo from "@/assets/service/responseTypes/cashbook/cashbookDetail/CashbookInfo";
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";
  import { CashbookReportsResponseEntry } from "@/assets/service/responseTypes/report/CashbookReportsResponse";
  import DesktopHeader from "@/components/general/DesktopHeader.vue";
  @Component({
    components: ({
      DesktopMonthPicker,
      DesktopHeader,
    })
  })
  export default class ReportDesktop extends Report {

    showEarlierStored: boolean = false;

    sortBy: string | [] = '';

    sortDesc: boolean | [] = true;

    get cashbookTitle(){
      if(this.$route.meta?.headerHasBranchInfo)
      {
        if(this.$route.meta?.headerTitle)
          return this.$route.meta.headerTitle + " - " + this.activeCashbookTitle;
      }
      return this.activeCashbookTitle;
    }
    get activeCashbookTitle(): string {
      return this.$store.getters.selectedCashbook
        ? this.$store.getters.selectedCashbook.VAL : '';
    }
    // eslint-disable-next-line class-methods-use-this
    get headers() {
      return reportTableHeader;
    }

    get showEarlier(): boolean {
      return this.showEarlierStored;
    }

    set showEarlier(val: boolean) {
      this.showEarlierStored = val;
    }

    get activeCashbookInfo(): CashbookInfo {
      return this.$store.getters.activeCashbookDetails;
    }

    get dateFirstEntry(): Date {
      const startDate = moment(this.activeCashbookInfo?.DATUMERSTEREINTRAG, REQUEST_DATEFORMAT).toDate();
      startDate.setHours(0, 0, 0, 0);
      return startDate;
    }

    get availableYears() {
      const years: number[] = [];
      if (this.activeCashbookInfo) {
        const currentYear: number = (this.now).getFullYear();

        for (let i: number = (this.dateFirstEntry.getFullYear()); i <= currentYear; i += 1) {
          years.push(i);
        }
        if (years.length === 0) {
          years.push(currentYear);
        }
      }
      return years.reverse();
    }

    get isCurrentMonthSelected(): boolean {
      return this.now.getMonth() === this.selectedMonth && this.now.getFullYear() === this.selectedYear;
    }

    get containsFilterableEarlierCashbookDetailEntries(): boolean {
      return this.filterableEarlierCashbookEntries.length > 0;
    }

    get tableFilterableEarlierCashbookDetailEntries(): CashbookReportsResponseEntry[] {
      return (this.showEarlier || !this.isCurrentMonthSelected) ? this.filterableEarlierCashbookEntries
        .map((value, index) => ({ id: index, ...value })) : [];
    }

    get filterableEarlierCashbookEntries(): CashbookReportsResponseEntry[] {
      return this.entries
        .filter((value) => {
          const date = moment(value.DATUM, REQUEST_DATEFORMAT);
          const now = moment(this.now);
          return date.year() < now.year() || (date.year() === now.year() && date.dayOfYear() < now.dayOfYear());
        });
    }

    get tableFilterableTodayCashbookDetailEntries(): CashbookReportsResponseEntry[] {
      return this.entries
        .filter(value => {
          const date = moment(value.DATUM, REQUEST_DATEFORMAT);
          const now = moment(this.now);
          return (date.year() === now.year() && date.dayOfYear() === now.dayOfYear());
        })
        .map((value, index) => ({ id: index, ...value }));
    }

    setShowEarlier(val: boolean) {
      this.showEarlier = val;
    }

    @Watch('activeCashbookTitle')
    cashbookChanged() {
      this.changeMonth();
    }

    @Watch('activeCashbookInfo')
    loadEntries() {
      if (this.$store.getters.cashbookEntriesForSelectedMonth.length === 0) {
        this.changeMonth();
      }
    }

    changeMonth() {
      super.changeMonth();
    }

    toggleCombobox(cbx: string) {
      const combobox: any = this.$refs[cbx];
      if (combobox.isMenuActive) {
        (this.$refs[cbx] as any).isMenuActive = false;
      } else {
        (this.$refs[cbx] as any).isMenuActive = true;
        combobox.focus();
      }
    }

    getSum() {

    }

    isEntryReloading(i: any) {
      return false;
    }

    mounted() {
      super.mounted();
      this.$store.commit('SET_FOOTER_ON_UI', { footerOnUI: true });
    }
  }
