
  import { Component, Watch } from 'vue-property-decorator';
  import Report from "@/views/report/Report";
  import MobileDayPicker
    from "@/components/general/genericComponents/dateDrivenComponents/MobileDayPicker.vue";
  import CashbookEntriesResponseEntry
    from "@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry";
  import { CashbookReportsResponseEntry } from "@/assets/service/responseTypes/report/CashbookReportsResponse";

  @Component({ components: { MobileDayPicker } })
  export default class ReportMobile extends Report {
    reportLoadingLFD: number = -1;

    get selectedDay(): number {
      if (Number(this.$store.getters.selectedYear) !== this.selectedYear
        || Number(this.$store.getters.selectedMonth) !== this.selectedMonth) {
        this.selectedMonth = this.$store.getters.selectedMonth;
        this.changeMonth();
      }
      return Number(this.$store.getters.selectedDay);
    }

    set selectedDay(d: number) {
      this.$store.commit('SET_SELECTED_DAY', d);
    }

    reportLoading(lfd: number) {
      return this.reportLoadingLFD === lfd;
    }

    mounted() {
      super.mounted();
      this.scrollTo(this.selectedDay);
    }

    @Watch('activeCashbookTitle')
    cashbookChanged() {
      this.changeMonth();
    }

    typ(item: CashbookEntriesResponseEntry): string {
      return this.$store.getters.reportTypes.filter(val => val.NUM === item.TYP)[0]?.VAL;
    }

    @Watch('loadTableData')
    @Watch('selectedDay')
    dayWatcher() {
      if (!this.loadTableData) {
        this.scrollTo(this.selectedDay);
      }
    }

    scrollTo(day: number) {
      let slider = document.querySelector('.hs');
      let element = document.querySelector('#day-element-' + (day));
      if (slider && element) {
        slider.scrollTo((element as HTMLElement).offsetLeft - ((element as HTMLElement).clientWidth / 2), 0);
      }
    }

    getDate(d: number): Date {
      return new Date(this.selectedYear, this.selectedMonth, d);
    }

    getDayStartCashBalance(d: number): number {
      const entriesForMonth = this.entries;
      if (entriesForMonth.length > 0) {
        const list = entriesForMonth.filter(value => d >= Number(value.DATUM.split('/')[2]));
        if (list.length > 0) {
          return list[list.length - 1].ANFANGSBESTAND;
        } else {
          const entry = entriesForMonth[0];
          return entry.ZAHLUNGSMITTEL !== 0 ? entry.ANFANGSBESTAND : (entry.ANFANGSBESTAND - +entry.ANFANGSBESTAND);
        }
      }
      return 0;
    }

    getDayCashDelta(d: number): number {
      const entriesForDay: CashbookReportsResponseEntry[] = this.getEntriesForDay(d);
      return entriesForDay[entriesForDay.length - 1]?.ISTBESTAND | 0;
    }

    private getEntriesForDay(d: number): CashbookReportsResponseEntry[] {
      return this.entries
        .filter(value => d == Number(value.DATUM.split('/')[2]))
        .sort((a, b) => a.LFDNUMMER - b.LFDNUMMER);
    }
  }
