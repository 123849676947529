
import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Lightbox extends Vue {

    @Prop({ required: true, default: null })
    public readonly src!: string|null;
    private showLightbox: boolean = false;
    private showToolbar: boolean = true;
    private zoomed = false;

    public zoom() {
        this.zoomed = !this.zoomed;
    }

    public show() {
        this.showLightbox = true;
    }
}
