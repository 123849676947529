
import { Component, Vue, Prop } from "vue-property-decorator";

  @Component
  export default class DesktopHeader extends Vue{
    @Prop()
    public title: string|undefined ;

    get headerTitle():string{
      if(this.title)
        return this.title;

      if(this.$route.meta?.headerTitle)
        return this.$route.meta.headerTitle;

      return ""
    }
  };
