import { Store } from 'vuex';
import type { StoreRepository } from '@/modules/auth';

export class AuthModuleAdapter implements StoreRepository {

    private readonly store: Store<any>;

    constructor(store: Store<any>) {
        this.store = store;
    }

    public getServiceUrl(): string | undefined {
        return this.store.getters.serviceUrl;
    }

    public getToken(): string | undefined {
        return this.store.getters.authToken;
    }

    public getBranchId(): string | undefined {
        return this.store.getters.branchId;
    }

    public getUserId(): string | undefined {
        return this.store.getters.userId;
    }

    public setServiceUrl(serviceUrl: string): void {
        this.store.commit('setServiceUrl', serviceUrl);
    }

    public setToken(token: string): void {
        this.store.commit('setAuthToken', token);
    }

    public setUserId(userId: string): void {
        this.store.commit('setUserId', userId);
    }

    public setBranchId(branchId: string) {
        this.store.commit('setBranchId', branchId);
    }
}
