import ServiceErrors from '@/assets/service/responseTypes/ServiceErrors';
import CashbookEnumerationsEntry
  from '@/assets/service/responseTypes/cashbook/cashbookEnumerations/CashbookEnumerationsEntry';

export default class CashbookEnumerationsWrapper {
  // eslint-disable-next-line camelcase
  Tbl_KassenbuchEnumerations_response!: CashbookEnumerations;
}

export class CashbookEnumerations {
  // eslint-disable-next-line camelcase
  Lst_Enumeration!: CashbookEnumerationsEntry[];

  ServiceErrors!: ServiceErrors[];
}

export enum EnumLabel {
  TYP = 'Typ',
  PERSISTENZ = 'Persistenz',
  BUCHUNGSART = 'Buchungsart',
  BILLING_STATE = 'Abrechnungs-Status',
  REGISTER_STATE = 'Registrierkassenbericht-Status',
  COUNT_STATE = 'Geldzählung-Status',
  COUNT_TYPE = 'Geldzählung-Art',
  REPORT_TYPE = 'Berichtsart',
  TRANSFER_TYPE = 'Art der Umbuchung'
}
