export default function dateDiffInDays(date1, date2) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor((Date.UTC(dt2.getFullYear(),
    dt2.getMonth(),
    dt2.getDate()) - Date.UTC(dt1.getFullYear(),
    dt1.getMonth(),
    dt1.getDate())) / (1000 * 60 * 60 * 24));
}

// export const ENTRY_DATEFORMAT: string = 'DD/MM/YYYY';

export const REQUEST_DATEFORMAT: string = 'YYYY/MM/DD';

export const REQUEST_TIMEFORMAT: string = 'hh:mm:ss';
