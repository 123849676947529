
  import { Component, Vue } from 'vue-property-decorator';
  import ReportDesktop from "./ReportDesktop.vue";
  import ReportMobile from "./ReportMobile.vue";
  import CashCountDialog from "@/components/cashbook/dialogs/dayClosing/CashCountDialog.vue";

  @Component({
    components: ({
      CashCountDialog,
      ReportDesktop,
      ReportMobile
    })
  })
  export default class Report extends Vue {

  }
