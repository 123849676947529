
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PermissionUtil from '@/assets/utils/PermissionUtil';
import { tableHeaders } from '@/assets/config/TableHeaders';
import CashbookTableHeader from '@/components/cashbook/table/cashbook/CashbookTableHeader.vue';
import DayEndClosingDialog from '@/components/cashbook/dialogs/dayClosing/DayEndClosingDialog.vue';
import CashbookEntriesResponseEntry from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
import moment from 'moment-mini';
import { REQUEST_DATEFORMAT } from '@/assets/utils/DateUtil';
import Webservice from '@/assets/service/Webservice';

@Component({
    components: { DayEndClosingDialog, CashbookTableHeader},
})
export default class CashbookTableFooter extends Vue {
    // means dont show actions?
    @Prop()
    public readonly isOld!: boolean;
    @Prop()
    public readonly content!: CashbookEntriesResponseEntry[];

    private showTakePreStockStored: boolean = false;
    private showDayEndClosingStored: boolean = false;
    private isEmptyCashbook: boolean = false;

    private get showTakePreStockBtn(): boolean {
        return !this.isOld
            && this.showTakePreStockStored;
    }

    private get showDayEndClosingBtn(): boolean {
        return !this.isOld
            && moment(this.$store.getters.cashbookBillingDate, REQUEST_DATEFORMAT).isBefore(new Date(), 'day')
            && PermissionUtil.hasPermissionForClosure()
            && this.content?.length > 0
            && this.$store.getters.cashbookBillingState.AbrechnungStatus === 0
            && this.showDayEndClosingStored;
    }

    private get header() {
        return tableHeaders;
    }

    public mounted() {
        this.updateOpenEntries();
        this.updateCashbookEmpty();
    }

    public updated() {
        this.updateOpenEntries();
    }

    private getSum(): number {
        return this.content.length > 0 ? this.content[this.content.length - 1].SALDO : 0;
    }

    @Watch('$store.getters.selectedCashbook', {deep: true})
    @Watch('$store.getters.activeUserId')
    private async updateCashbookEmpty() {
        const cashbookId = this.$store.getters.selectedCashbook?.NUM;
        const userId = this.$ports.auth.getUserId();
        if (userId && cashbookId) {
            this.isEmptyCashbook = await Webservice.isEmptyCashbook(userId, cashbookId);
        }
    }

    @Watch('content', {deep: true})
    private async updateOpenEntries() {
        if (!this.isOld) {
            Webservice.getCashbookBillingState((val) => {
                if (this.$store.getters.cashbookBillingState.AbrechnungStatus !== val.AbrechnungStatus
                    || this.$store.getters.cashbookBillingState.KassenberichtStatus !== val.KassenberichtStatus
                    || this.$store.getters.cashbookBillingState.ZaehlprotokollStatus !== val.ZaehlprotokollStatus) {
                    this.$store.commit('SET_CASHBOOK_BILLING_STATE', val);
                }
            });

            Webservice.countEntries(false, (amount: number) => {
                this.showTakePreStockStored = (amount === 0);
            });
            Webservice.countEntries(true, (amount: number) => {
                this.showDayEndClosingStored = (amount > 0);
            });
        }
    }

    private transfer() {
        Webservice.insertTransferEntries(() => {
            Webservice.reloadCashbookEntries();
            Webservice.getCashbookBillingState((val) => this.$store.commit('SET_CASHBOOK_BILLING_STATE', val));
            Webservice.getCashbookBillingDate();
            this.updateOpenEntries();
        });
    }
}
