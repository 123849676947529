const state = {
  error: '',
  webserviceMethod: null as (() => Promise<any>)|null,
};

const getters = {
  error: initState => initState.error,
  webserviceMethod: initState => initState.webserviceMethod,
};

const mutations = {
  SET_ERROR(initState, param) {
    initState.error = param;
  },
  SET_ERROR_WEBSERVICE_METHOD(initState, param) {
    initState.webserviceMethod = param;
  }
};

export default {
  state,
  getters,
  mutations,
};
