import { BillingPort } from './BillingPort';
import { BillingService } from './services/BillingService';
import { BillingRepository } from './adapter/BillingRepository';
import { BillingRestRepository } from './adapter/rest/BillingRestRepository';
import { AuthenticationProvider } from './bridges';

export class BillingPortFactory {

    private readonly authenticationProvider: AuthenticationProvider;

    private billingService: BillingService|null = null;
    private billingRepository: BillingRepository|null = null;

    private constructor(params: { auth: AuthenticationProvider }) {
        this.authenticationProvider = params.auth;
    }

    public static createBillingPort(auth: AuthenticationProvider): BillingPort {
        const factory = new BillingPortFactory({ auth: auth });
        return new BillingPort({
            billingService: factory.getBillingService(),
        });
    }

    private getBillingService(): BillingService {
        if (this.billingService === null) {
            this.billingService = new BillingService({
                billingRepository: this.getBillingRepository(),
            });
        }
        return this.billingService;
    }

    private getBillingRepository(): BillingRepository {
        if (this.billingRepository === null) {
            this.billingRepository = new BillingRestRepository({
                auth: this.authenticationProvider,
            });
        }
        return this.billingRepository;
    }
}
