import DialogMode from '@/components/cashbook/dialogs/transactionDialog/DialogMode';
import store from "@/store/store";
import GenericDateDrivenComponent
    from "@/components/general/genericComponents/dateDrivenComponents/GenericDateDrivenComponent";
import CashbookEntriesResponseEntry
    from "@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry";
import {REQUEST_DATEFORMAT} from "@/assets/utils/DateUtil";
import PermissionUtil from "@/assets/utils/PermissionUtil";
import moment from "moment-mini";
import Webservice from '@/assets/service/Webservice';
import FilterCashbookItem from "@/assets/models/FilterCashbookItem";

export default class Cashbook extends GenericDateDrivenComponent {
    filter: FilterCashbookItem = new FilterCashbookItem();
    dialogMode = DialogMode;

    get paymentFilteredEntries(): CashbookEntriesResponseEntry[] {
        return this.loadTableData ? [] : this.$store.getters.cashbookEntriesForSelectedMonth.filter((value: CashbookEntriesResponseEntry) => {
            if (this.filter.PAYMENT && this.filter.PAYMENT.NUMMER !== -1) {
                return value.ZAHLUNGSMITTEL === this.filter.PAYMENT.NUMMER
                    || (value.ZAHLUNGSMITTEL_2 !== 0 && value.ZAHLUNGSMITTEL_2 === this.filter.PAYMENT.NUMMER)
                    || (value.ZAHLUNGSMITTEL_3 !== 0 && value.ZAHLUNGSMITTEL_3 === this.filter.PAYMENT.NUMMER);
            }
            return true;
        });
    }

    get canCreateTransaction(): boolean {
        return ((moment(this.$store.getters.cashbookBillingDate, REQUEST_DATEFORMAT).isBefore(this.transactionDate, 'day') || PermissionUtil.hasPermissionToEditAfterDayClosing()));
    }

    get transactionDate() {
        return new Date(this.$store.getters.selectedYear,
            this.$store.getters.selectedMonth,
            this.day);
    }

    get day(): number {
        if (this.$vuetify.breakpoint.smAndDown) {
            return Number(this.$store.getters.selectedDay);
        } else {
            const now = new Date();
            if (now.getFullYear() === Number(this.$store.getters.selectedYear)
                && now.getMonth() === Number(this.$store.getters.selectedMonth)) {
                return now.getDate();
            } else {
                return (new Date(this.$store.getters.selectedYear,
                    this.$store.getters.selectedMonth + 1, 0)).getDate();
            }
        }
    }

    mounted() {
        this.checkIfDayClosingIsActive();
        super.mounted();
        //this.filter.PAYMENT=this.allCashbookPaymentMethod;
    }

    openTransactionDialog(ingestion: boolean) {
        this.$store.commit('SET_INGESTION', ingestion);
        this.$store.commit('SET_TRANSACTION_DIALOG_MODE', DialogMode.CREATE);
        this.$store.commit('SET_FILER_DATE', this.filter.DATUM ? new Date(this.filter.DATUM) : null);
        this.$store.commit('SET_SHOW_TRANSACTION_DIALOG', true);
    }
    openTransferDialog() {
        this.$store.commit('SET_FILER_DATE', this.filter.DATUM ? new Date(this.filter.DATUM) : null);
        this.$emit('showTransferDialog');
    }

    async checkIfDayClosingIsActive() {
        Webservice.getCashbookBillingDate();
        const cashbook = this.$store.getters.selectedCashbook.NUM;
        const state = await this.$ports.billing.getCashbookBillingState(cashbook);
        // @ts-ignore a fix causes an endless redirect loop
        if (state.AbrechnungStatus !== undefined && state.AbrechnungStatus !== 0 && state.AbrechnungStatus !== 5) {
            await this.$router.push('/cashbook/day-closing');
        }
    }

    changeMonth() {
        this.loadTableData = true;
        store.commit('SET_CASHBOOKENTRIES_FOR_SELECTED_MONTH', []);
        Webservice.getCashbookEntries(Number(this.$store.getters.selectedMonth), Number(this.$store.getters.selectedYear), () => {
            this.loadTableData = false;
        });
    }

    openReport(date: string, callBackFinally: () => void) {
        Webservice.getReportURL(date, (url) => {
            window.open(url, '_blank');
        }, callBackFinally)
    }
}
