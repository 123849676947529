
  import { Component, Vue } from 'vue-property-decorator';
  import ClientAbout from "@/components/about/ClientAbout.vue";
  import ServiceAbout from "@/components/about/ServiceAbout.vue";


  @Component({
    components: {ServiceAbout, ClientAbout}
  })
  export default class AboutMobile extends Vue {
    tab= null;
  }
