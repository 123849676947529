const state = {
  dayEndClosingConfig: JSON.parse(window.localStorage.getItem('dayEndClosingConfig') || '{}'),
  cashbookBillingState: {},
  cashbookBillingStates: JSON.parse(window.localStorage.getItem('billingStates') || '[]'),
  cashbookRegisterStates: JSON.parse(window.localStorage.getItem('registerStates') || '[]'),
  cashbookCountStates: JSON.parse(window.localStorage.getItem('countStates') || '[]')
};

const getters = {
  dayEndClosingConfig: initState => initState.dayEndClosingConfig,
  cashbookBillingState: initState => initState.cashbookBillingState,
  cashbookBillingStates: initState => initState.cashbookBillingStates,
  cashbookRegisterStates: initState => initState.cashbookRegisterStates,
  cashbookCountStates: initState => initState.cashbookCountStates
};

const mutations = {
  SET_DAY_END_CLOSING_CONFIG(initState, param) {
    initState.dayEndClosingConfig = param;
    if (param !== undefined) {
      window.localStorage.setItem('dayEndClosingConfig', JSON.stringify(param));
    }
  },
  SET_CASHBOOK_BILLING_STATE(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookBillingState = params;
    if (params !== undefined) {
      window.localStorage.setItem('billingState', JSON.stringify(params));
    }
  },
  SET_CASHBOOK_BILLING_STATES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookBillingStates = params;
    if (params !== undefined) {
      window.localStorage.setItem('billingStates', JSON.stringify(params));
    }
  },
  SET_CASHBOOK_REGISTER_STATES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookRegisterStates = params;
    if (params !== undefined) {
      window.localStorage.setItem('registerStates', JSON.stringify(params));
    }
  },
  SET_CASHBOOK_COUNT_STATES(initState, params) {
    // eslint-disable-next-line no-param-reassign
    initState.cashbookCountStates = params;
    if (params !== undefined) {
      window.localStorage.setItem('countStates', JSON.stringify(params));
    }
  }
};

export default {
  state,
  getters,
  mutations,
};
